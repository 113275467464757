import React from 'react'
import { Button  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark  } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscribeToChannelModal from '../subscribe-channel-modal'

const publishableKey = process.env.REACT_APP_STRIPE_CHECKOUT_ID
const stripePromise = loadStripe(publishableKey);

const SubscribeChannelComponent = ({ channelId, products, displayName, isSubscribed, setIsSubscribed, showSubscribeModal, setShowSubscribeModal, profileId, authUser }) => {

    const toggle = () => setShowSubscribeModal(!showSubscribeModal)

    return (
        <>
            <Elements stripe={stripePromise}>
                <SubscribeToChannelModal 
                    isOpen={showSubscribeModal} 
                    toggle={toggle} 
                    channelId={channelId} 
                    products={products}
                    displayName={displayName}
                    profileId={profileId} 
                    setIsSubscribed={setIsSubscribed} 
                    authUser={authUser}
                    isSubscribed={isSubscribed} />
            </Elements>
            <Button color="primary" onClick={toggle}>
                <FontAwesomeIcon 
                    color="primary"
                    className="cursor-pointer"
                    icon={isSubscribed ? faBookmark : farBookmark}
                />
            </Button>
        </>
    )
}

export default SubscribeChannelComponent
