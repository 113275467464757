


exports.addMinutes =  function (dt, minutes) {
    if (typeof dt === 'string') {
      const strongDate = new Date(dt)
      return new Date(strongDate.getTime() + minutes*60000);
  
    } else {
      return new Date(dt.getTime() + minutes*60000);
    }
  }

exports.subtractMinutes =  function (dt, minutes) {
    if (typeof dt === 'string') {
      const strongDate = new Date(dt)
      return new Date(strongDate.getTime() - minutes*60000);
  
    } else {
      return new Date(dt.getTime() + minutes*60000);
    }
  }

  
exports.doOverlap = (proposed, existing) => {
    return (existing.startTime <= proposed.endTime) && (proposed.startTime <= existing.endTime)
}


