import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { EventTitleBarContainer } from '../styles'
import { Button } from 'reactstrap'
import hash from 'object-hash'
import { getAppId } from '../../../../../../utils/brand-silo'
import InlineMessengerComponentWithData from '../../../../../messenger/inline-messenger'


const EventChatComponent = (props) => {

    const { className, style, messengerStyle, isChatButtonDisabled, showInitial, targetId, hideHeader = false } = props

    const contextType = 'EVENT'
    const appId = getAppId()
    const contextHash = hash(`${appId}-${targetId}`)

    const initialMessengerCtxState = { contextType, targetId, contextHash, displayName: 'asdfasdf', messages: null } 

    const [showMessenger, setMessenger] = useState(showInitial)

    const toggleMessenger = () => {
        const newValue = !showMessenger

        setMessenger(newValue)
    }

    return (
        <div className={className} style={style} >

            { !hideHeader && (
                <EventTitleBarContainer>
                    <div className="w-100">
                        <strong>
                            {' '}
                                Event Chat
                            {' '}
                        </strong>
                        {' '}
                    </div>

                    <div className="my-auto" >
                        <Button outline disabled={isChatButtonDisabled} onClick={toggleMessenger}  >
                            <FontAwesomeIcon icon={faCaretDown } />
                        </Button>
                    </div>
                </EventTitleBarContainer>
            )}

            { showMessenger && (
                <InlineMessengerComponentWithData 
                    style={messengerStyle}
                    showHeader={false}
                    currentContext={initialMessengerCtxState}
                />
            )}
        </div>
    )
}

export default EventChatComponent

