import React from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import LoadingComponent from '../../../components/loading-component'


const NetworkQuery = ({ query, variables, update, showLoading = true }) => {
    const {data, loading} = useQuery(query, { variables })
    if (loading) {
        return showLoading ? (<LoadingComponent isInline />) : (<></>)
    } else if (data) {
        const dataKeys = Object.keys(data)

        update({ ...data[dataKeys], updatedAt: new Date().toString() })
    }

    return (<></>)
}


const CachedFieldComponent = ({ query, variables, find, required, cache, render, showLoading, initialState, isNestedQuery = false, handleError }) => {

    const cached = useReactiveVar(cache.reactive)

    if (cached === null) {
        return (
            <NetworkQuery
                query={query}
                variables={variables}
                dataType={cache.dataType}
                update={cache.reactive}
                showLoading={showLoading}
            />
        )
    } else if (find) {
        const { primaryKey } = cache
        const selectedResult = cached.results.find(r => r[primaryKey] === find )

        if (!selectedResult) {
            return (
                <NetworkQuery
                    query={query}
                    variables={variables}
                    cache={cache}
                    update={(selectedUpdate) => {
                        if (cache.dataType === 'ARRAY') {
                            if (selectedUpdate.results.length === 0 && handleError) {
                                handleError()
                            }

                            const otherRecords = cached.results.filter(r => r[primaryKey] !== find )

                            const results = !!initialState
                                ? ([
                                    ...otherRecords,
                                    {
                                        ...initialState,
                                        [required]: selectedUpdate.results
                                    }
                                ]) : ([
                                    ...otherRecords,
                                    ...selectedUpdate.results                                
                                ])

                            cache.reactive({ results })
                        }
                    }}
                />
            )
        } else if (required && !selectedResult[required]) {
            return (
                <NetworkQuery
                    query={query}
                    variables={variables}
                    cache={cache}
                    update={(selectedUpdate) => {
                        if (cache.dataType === 'ARRAY') {
                            const otherRecords = cached.results.filter(r => r[primaryKey] !== find )
                            const mappedRecord = isNestedQuery
                                ? ({
                                    ...selectedResult,
                                    [required]: selectedUpdate.results
                                }) : (selectedUpdate.results[0])

                            cache.reactive({
                                results: [
                                    ...otherRecords,
                                    mappedRecord
                                ]
                            })
                        }
                    }}
                />
            )

        } else {
            return render(selectedResult)
        }
    } else {
        return render(cached)
    }
}

export default CachedFieldComponent
