import React from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import { getCurrentBrandConfig } from '../../utils/brand-silo'
import AppLogo from '../logo'

const LoadingComponent = ({ isSite, isInline }) => {
  const { appId, logo } = getCurrentBrandConfig()

  if (isSite) {
    return (
      <div className="d-flex bg-secondary" style={{ height: '100vh', width: '100vw' }}>
        <span className="m-auto">
          <Row>
            <Col xs={12} className="text-center">
              <Spinner color="primary" />
            </Col>
            <Col xs={12} className="text-center">
              <AppLogo appId={appId} logo={logo} />
            </Col>
          </Row>
        </span>
      </div>
    )
  } else if (isInline) {
    return (
      <Row>
        <Col xs={12} className="text-center">
          <Spinner color="primary" />
        </Col>
      </Row>
    )
  } else {
    return (
      <Spinner color="primary" />
    )
  }
}

export default LoadingComponent
