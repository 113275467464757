import { gql } from '@apollo/client'

export const INVITE_MEMBERS_MUTATION = gql`
  mutation addGroupMembers($request: AddGroupMembersRequest) {
    addGroupMembers(request: $request) {
        displayName
        profileId
        userId
        status
        interest
    }
  }
`
