import { gql } from '@apollo/client'

export const CHANNEL_EDITS_MUTATION = gql`
    mutation editChannel($request: EditChannelRequest) {
        editChannel(request: $request) {
            displayName
        }
    }
`

