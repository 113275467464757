import { gql } from '@apollo/client'

export const START_EVENT_SESSION = gql`
  mutation startEventSession($request: StartEventSessionRequest) {
    startEventSession(request: $request) {
        partyId
        peers {
            profileId
            displayName
            primaryImageUrl
            channelArn
            isHost
        }
        parties {
            partyId
            peers {
                profileId
                displayName
                primaryImageUrl
                isHost
            }
        }
    }
  }
`

export const RECORD_ACTIVITY = gql`
  mutation recordActivity($request: RecordActivityRequest) {
    recordActivity(request: $request)
  }
`
