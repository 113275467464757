import React from 'react'
import { GET_SESSION } from './queries'
import { getAppId } from '../../../utils/brand-silo'
import SessionCacheComponent from '../../../shared/cache/helpers/SessionCacheComponent'
import { mySession } from '../../../shared/cache'

const SessionGuard = ({ render, cognitoId, email }) => {
  const appId = getAppId()

  const variables = {
    request: {
      cognitoId,
      appId,
      // displayName
      email
    },
  }

  return (
    <SessionCacheComponent
      cognitoId={cognitoId}
      query={GET_SESSION}
      variables={variables}
      cache={mySession}
      render={render}
    />
  )
  
}

export default SessionGuard
