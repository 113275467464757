export const HotboxAppConfig = {
  appId: 'HOTBOX',
  name: 'Hotbox',
  logo: 'HOTBOX',
  appUrl: 'hotboxsocial.com',
  favicon: 'hotbox-favicon.ico',
  pages: [
    'LANDING',
    // 'EXPLORE',
    'PROFILE',
    'MESSENGER',
  ],
  type: 'SINGLE',
  forceSso: true,
  landing: {
    page: 'LANDING',
    header: 'IT\'S 4:20 SOMEWHERE',
    subHeader: 'BRINGING THE WORLD TOGETHER ONE TOKE AT A TIME',
    cta: {
      primary: {
        text: 'FIND A HOTBOX',
        target: 'EVENT',
        type: 'FUNCTION'

      },
      secondary: {
        text: 'LEARN MORE',
        target: 'EVENT',
      },
    },
    items: [
      {
        type: 'CONTACT',
        id: 'contact-us',
        isInline: true,
        email: true,
        company: false,
        body: false
      },
      {
        type: 'FOOTER',
        id: 'footer',
      },
    ]
  },
  pageConfigs: [
    {
      key: 'PROFILE',
      isAuthorized: true,
      tabs: [
        // 'EVENTS'
        'FRIENDS',
        'GROUPS',
      ],
    },
    {
      key: 'EXPLORE',
      isAuthorized: false,
      tabs: [],
    },
    {
      key: 'EVENT',
      isAuthorized: true,
      tabs: [],
    },
    {
      key: 'GROUP',
      isAuthorized: true,
      hasEvent: false,
      tabs: [],
    },
  ],
  event: {
    type: 'SINGLE_APP',
    eventId: '420',
    name: 'Hotbox Social',
    description: 'It\'s 4:20 somewhere',
    showHeader: false,
    maxPeers: 1000000,
    maxPartySize: 9,
    viewerTypes: {
      allowSolo: true,
      allowParty: true,
      allowFrontRow: false,
      allowHost: false
    }
  },
  theme: {
    components: {
      page: {
        backgroundColor: 'quinary',
        altBackgroundColor: 'secondary',
      },
      navBar: {
        backgroundColor: 'primary',
        logoColor: 'secondary',
        thumbnailContainerColor: 'quinary',
        iconColor: 'primary',
        iconBackgroundColor: 'secondary',
        borderColor: 'quaternary',
      },
      sideBar: {
        fontColor: 'tertiary',
        backgroundColor: 'secondary',
        itemComponentColorPassive: 'secondary',
        itemComponentColorActive: 'nonary',
        itemBackgroundColorPassive: 'secondary',
        itemBackgroundColorActive: 'septenary',
        itemColorPassive: 'primary',
        itemColorActive: 'tertiary',
        logoColor: 'secondary',
      },
      hero: {
        titleColor: 'tertiary',
        descriptionColor: 'quaternary',
        bottomBackgroundColor: 'secondary',  
        badgeBackgroundColor: 'tertiary',
        badgeFontColor: 'octonary',
        borderColor: 'senary',
        profileImgBackgroundColor: 'quinary',
        profileImgColor: 'quaternary'
      },
      gallery: {
        backgroundColor: 'secondary',
        borderColor: 'senary',
        tileLowerBackgroundColor: 'quinary',
        viewCountColor: 'quaternary',
        menuItemColorPassive: 'quaternary',
        menuItemColorActive: 'tertiary',
        menuItemBadgeBackgroundColorPassive: 'quaternary',
        menuItemBadgeBackgroundColorActive: 'tertiary',
        menuItemBadgeColorPassive: 'tertiary',
        menuItemBadgeColorActive: 'secondary',
        menuItemAccent: 'primary'
      }
    },  
    color: {
      primary: '#7fcd91', // primary green
      secondary: '#4d4646', // dark black
      tertiary: '#FFFFFF', // white
      quaternary: '#A7A7A7', // gray
      quinary: '#635c5c', // light black
      senary: '#403737', // dark gray
      septenary: '#7fcd91BE', // primary with transparency
      octonary: '#292D34', // middle black
      nonary: '#87E3AB8B', // primary with most transparency
    },
  type: {
      primary: 'Oswald',
      secondary: 'Oswald',
    },
  },
}
