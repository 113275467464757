import React, { useState } from 'react'
import { Col } from 'reactstrap'
import ViewerStreamComponent from '../stream-component/viewer'
import ReportViolationModal from './report-violation-modal'

const PartyComponent = ({
  profile, partyDetails, partyColSize
}) => {
  const { peers } = partyDetails
  const peersToShow = peers.filter((peer) => peer !== null && peer.profileId !== profile.profileId && !peer.isHost)

  const [showModal, setShowModal] = useState(false)
  const [reportPeer, setReportPeer] = useState(undefined)

  const toggle = () => setShowModal(!showModal)

  const reportUser = (profileId) => {
    setShowModal(true)
    setReportPeer(profileId)
  }

  const myFriends = profile.friends ? profile.friends : []

  return (
    <>

      { showModal && reportPeer && <ReportViolationModal profileId={reportPeer} isOpen={!!reportPeer} toggle={toggle} />}

      { peersToShow.length > 0 && peersToShow.map((peer, i) => {
        const peerIsFriend = !!myFriends.find((f) => f.profileId === peer.profileId)

        return (
          <Col key={peer.profileId} xs={partyColSize.xs} md={partyColSize.md} style={{ padding: '0px' }}>
            <ViewerStreamComponent isLiked={peerIsFriend} peer={peer} index={i} reportUser={reportUser} hostProfileId={profile.profileId} />
          </Col>
        )
      })}

    </>
  )
}


const PartyComponentWithGuard = (props) => {
  const { hasLoaded } = props

  if (!hasLoaded) {
    return (<></>)
  } else {
    return <PartyComponent {...props} />
  } 
}


export default PartyComponentWithGuard

