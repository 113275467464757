export const SpeakeasyAppConfig = {
  appId: 'SPEAKEASY',
  name: 'Speakeasy',
  logo: 'Speakeasy',
  appUrl: 'speakeasy.app',
  favicon: 'speakeasy-favicon.ico',
  pages: [
    'LANDING',
    'EXPLORE',
    'PROFILE',
    'MESSENGER',
  ],
  type: 'SINGLE',
  forceSso: true,
  allowProfileEvents: 'true',
  landing: {
    page: 'LANDING',
    header: 'A SOCIAL PLACE',
    subHeader: 'A PARTY DYNAMIC FOR VIRTUAL EVENTS',
    cta: {
      primary: {
        text: 'Explore',
        type: 'FUNCTION',
        color: 'primary'
      },
      secondary: {
        type: 'REF',
        ref: 'apply-founding-creator',
        text: 'Influencers',
        color: 'secondary',
        isBlock: true
      },
    },
    items: [
      {
        type: 'COLUMN',
        title: 'Join the Virtual Party',
        tag: 'WELCOME',
        id: 'welcome',
        items: [
            {
              // image: 'https://tes-media-dev.s3.amazonaws.com/content/platform-blue.png',
              // imageStyle: {  marginTop: '1rem', marginBottom: '4rem' },
              // header: 'Join the Virtual Party',
              description: 'We\'re a social media platform with an emphasis on "social." Speakeasy hosts virtualized parties, they\'re free and open for anyone to join.',
              showBorder: false
            },
        ]
      },
      {
        type: 'COLUMN',
        title: 'How it Works',
        tag: 'THE PLATFORM',
        id: 'how-it-works',
        items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/event-explore.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Join an Event',
              // description: '',
              showBorder: true,
              className: "text-center"
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/in-party.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Chat with your Party',
              // description: '',
              showBorder: true,
              className: "text-center"
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/next-party.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Go to Next Room',
              // description: '',
              showBorder: true,
              className: "text-center"
          },
        ],
      },
      {
        type: 'COLUMN',
        title: 'Apply to be a Founding Creator',
        tag: 'FOUNDING CREATORS PROGRAM',
        id: 'apply-founding-creator',
        items: [
            {
              // image: 'https://tes-media-dev.s3.amazonaws.com/content/platform-blue.png',
              // imageStyle: {  marginTop: '1rem', marginBottom: '4rem' },
              header: 'What is it?',
              description: 'The Founding Creators are a select group of influencers and content creators who will receive priviledges when the platform launches',
              showBorder: false
            },
            {
              // image: 'https://tes-media-dev.s3.amazonaws.com/content/platform-blue.png',
              // imageStyle: {  marginTop: '1rem', marginBottom: '4rem' },
              header: 'Create Virtual Events',
              description: 'The Founding Creators will be the only ones to be able to create events, but open to the public to join so you can bring your following to the party',
              showBorder: false
            },
        ],
        cta: {
          type: 'SITE-LINK',
          director: 'APPLY',
          color: 'primary',
          text: 'Apply to be a Founding Creator'
        }
      },
      {
        type: 'CONTACT',
        id: 'contact-us',
        isInline: true,
        email: true,
        company: false,
        body: false
      },
      {
        type: 'FOOTER',
        id: 'footer',
      },
    ]
  },
  pageConfigs: [
    {
      key: 'PROFILE',
      isAuthorized: true,
      tabs: [
        'EVENTS',
        'FRIENDS',
        'GROUPS',
      ],
    },
    {
      key: 'EXPLORE',
      isAuthorized: false,
      tabs: [
        'EVENTS',
      ],
    },
    {
      key: 'EVENT',
      isAuthorized: true,
      tabs: [],
    },
    {
      key: 'GROUP',
      isAuthorized: true,
      hasEvent: true,
      tabs: [],
    },
  ],
  event: {
    type: 'SINGLE_APP',
    eventId: '2020',
    name: 'Speakeasy Social',
    description: 'The last bar open in quarantine',
    showHeader: false,
    maxPeers: 1000000,
    maxPartySize: 9,
    viewerTypes: {
      allowSolo: true,
      allowParty: true,
      allowFrontRow: false,
      allowHost: false
    }
  },
  theme: {
    components: {
      page: {
        backgroundColor: 'secondary',
        altBackgroundColor: 'octonary',
        backgroundTransparency: 'octonary',
      },
      navBar: {
        backgroundColor: 'quinary',
        logoColor: 'secondary',
        thumbnailContainerColor: 'octonary',
        iconColor: 'primary',
        iconBackgroundColor: 'secondary',
        borderColor: 'secondary',
      },
      sideBar: {
        fontColor: 'secondary',
        backgroundColor: 'quinary',
        itemComponentColorPassive: 'quinary',
        itemComponentColorActive: 'primary',
        itemBackgroundColorPassive: 'quinary',
        itemBackgroundColorActive: 'quinary',
        itemColorPassive: 'secondary',
        itemColorActive: 'secondary',
      },
      hero: {
        titleColor: 'quaternary',
        descriptionColor: 'tertiary',
        bottomBackgroundColor: 'secondary',  
        badgeBackgroundColor: 'octonary',
        badgeFontColor: 'secondary',
        borderColor: 'senary',
        profileImgBackgroundColor: 'octonary',
        profileImgColor: 'secondary'
      },
      gallery: {
        backgroundColor: 'secondary',
        borderColor: 'senary',
        tileLowerBackgroundColor: 'octonary',
        viewCountColor: 'quaternary',
        menuItemColorPassive: 'quaternary',
        menuItemColorActive: 'tertiary',
        menuItemBadgeBackgroundColorPassive: 'quaternary',
        menuItemBadgeBackgroundColorActive: 'tertiary',
        menuItemBadgeColorPassive: 'tertiary',
        menuItemBadgeColorActive: 'octonary',
      }
    },  
    color: {
      primary: '#cf1b30', // primary red
      secondary: '#f7f7f7', // offwhite
      tertiary: '#69747C', // slate
      quaternary: '#545454', // dark gray
      quinary: '#eb4559', // red
      senary: '#eb455934', // red w transparency
      septenary: '#cf1b3034', // primary w transparency
      octonary: '#7D838640', // grey w transparency
      nonary: '#8666AD', // purple

    },
  type: {
      primary: 'Limelight',
      secondary: 'Oswald',
    },
  },
}
