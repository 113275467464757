import { gql } from '@apollo/client'

export const SEARCH_PROFILES_QUERY = gql`
  query searchProfiles($request: SearchProfilesRequest) {
    searchProfiles(request: $request) {
        results {
            displayName
            modifiedAt
            timestampUnix
            userId
            status
            userName
            createdAt
            appId
            interest
            location
            profileId
            attributes {
                attributeId
                entityId
                entityType
                type
                isVerified
                primaryImageUrl
                title
                position
                startDate
                endDate
                description
            }
            friends {
                displayName
                profileId
                interest
                isOnline
            }
            groups {
                displayName
                groupId        
                appId
                ownerId
                createdAt
                modifiedAt
                members {
                    displayName
                    isActive
                    isAccepted
                    targetType
                    appId
                    profileId
                    membershipId
                    userId
                    status
                    userName
                    interest
                    isOnline
                    currentPartyId
                }
            }
            events {
                eventId
                profileId
                title
                description
                entityId
                streamType
                startTime
                duration
            }
        }
        metadata {
            count
            skip
            limit
        }
    }
  }
`
