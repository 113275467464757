/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f7fb2460-1591-425d-bafe-e99ad2e6f900",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MybanvVZq",
    "aws_user_pools_web_client_id": "7j5hdqaksmiab2g36tt95vnvj0",
    "oauth": {},
    "aws_user_files_s3_bucket": "tes-content-bucket105225-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
