import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import BaseFormComponent from '../../shared/base-form'
import { myProfile } from '../../shared/cache'

const addGroupToProfile = async (eventPromise) => {
  const profile = myProfile.reactive()
  const newGroupPayload = await eventPromise
  const { createGroup: newGroup } = newGroupPayload.data
  newGroup.members = [profile]
  const newState = {
    ...profile,
    groups: [
      newGroup,
      ...profile.groups
    ]
  }
  myProfile.reactive(newState)
}

const CreateGroupModal = ({ toggle, isOpen, createGroup }) => {

  const createNewGroup = async ({ displayName }) => {
    const variables = {
      request: {
        displayName,
      },
    }

    addGroupToProfile(createGroup({ variables }))
    
    toggle()
  }

  const form = {
    isInline: false,
    showErrors: true,
    fields: {
      displayName: {
        dataType: 'STRING',
        type: 'text',
        name: 'displayName',
        label: 'Group Name',
        showLabel: true,
        defaultValue: '',
        placeholder: '',
        isRequired: true,
        minLength: 1,
        maxLength: 20,
        xs: 12,
      },

    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Create Group</ModalHeader>
      <ModalBody>
        <BaseFormComponent form={form} submit={createNewGroup} />
      </ModalBody>
    </Modal>
  )
}

export default CreateGroupModal
