import { gql } from '@apollo/client'

export const CREATE_CHANNEL_MUTATION = gql`
  mutation createChannel($request: CreateChannelRequest) {
    createChannel(request: $request) {
        channelId
        profileId
        appId
        displayName
        affiliateGroupId
        slug
        primaryImageUrl
        headerImageUrl
    }
  }
`
