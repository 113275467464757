import React, { useState, useEffect } from 'react'
import { Field, Formik } from 'formik'
import {
  Row, Col, Input, Label, Button, FormGroup, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap'
import { generateValidationSchema } from './valiation-schema'
import ImageUploader from 'react-images-upload'
import DateTime from 'react-datetime'
import { Storage } from 'aws-amplify'
import S3ImageComponent from '../../components/s3-image-component'
import "react-datetime/css/react-datetime.css"
import aws_exports from '../../aws-exports'
import LoadingComponent from '../../components/loading-component'
import { isDevInRuntime } from '../../utils/routing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperPlane,
  faSmile
} from '@fortawesome/free-solid-svg-icons'
import { CommentWriterContainer, CommentWriter, SmileIcon, FormButtonContainer, CancelButton, SendButton, CommentInputcontainer } from './style';
import { Card, CardText, CardBody, CardTitle } from 'reactstrap';
import Filter from 'bad-words'

Storage.configure(aws_exports)


const CHECKBOX_INPUT = 'checkbox'
const RADIO_INPUT = 'radio'
const IMAGE_INPUT = 'image'
const SELECT_INPUT = 'select'
const TEXT_INPUT = 'text'
const EMAIL_INPUT = 'email'
const DATE_TIME_INPUT = 'datetime'
const NUMBER_INPUT = 'number'
const VIDEO_INPUT = 'video'


const RadioInputOption = ({ currentField, option, render, isCard }) => {
  return isCard
    ? <Col xs={12} md={4} >
        <div>
          <InputGroup check>
            <Label check className={ option.description ? 'mb-3' : '' } >
              <Card>
                {option.image && (
                  <S3ImageComponent 
                    imageName={option.image} 
                    style={{ height: '100%' }}
                    // imageStyle={{ borderRadius: '10px', marginTop: '10px' }}
                  />
                )}
                <CardBody>
                  <CardTitle tag="h5">{ option.label }</CardTitle>
                  <CardText>{ option.description }</CardText>
                      <Field type="radio" name={currentField.name} value={option.value} disabled={currentField.disabled} /> {' '}
                      Select

                </CardBody>
              </Card>
            </Label>
          </InputGroup>
        </div>
      </Col>
    : (
      <InputGroup check>
        <Label check className={ option.description ? 'mb-3' : '' } >
          <Field type="radio" name={currentField.name} value={option.value} disabled={currentField.disabled} /> {' '}
          { option.label }<br />
          <i style={{ color: 'gray' }}>{option.description}</i>
        </Label>
      </InputGroup>
    )
}

const RadioInputComponent = ({ currentField }) => {
  const isCard = currentField.optionType && currentField.optionType === 'CARD'
  return (
    <InputGroup role="group" tag="fieldset">
      <Label>{ currentField.label }</Label>
      <Row>
        { currentField.options.map(option => (
          <RadioInputOption option={option} isCard={isCard} currentField={currentField} />
        ))}
      </Row>
    </InputGroup>
  )
}

const CheckboxInputComponent = ({ values, currentField, handleChange, handleBlur }) => (
  <InputGroup>
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <Input addon type="checkbox" name={currentField.name} checked={values[currentField.name]} onChange={handleChange} onBlur={handleBlur} />
      </InputGroupText>
    </InputGroupAddon>
    <InputGroupAddon addonType="append">
      <InputGroupText>
        { currentField.label }
      </InputGroupText>
    </InputGroupAddon>
  </InputGroup>
)

const ImageInputComponent = ({ values, currentField, setFieldValue }) => {

  return (
    <Row>
      <Col xs={12}>
        <Label >
          { currentField.label }
        </Label>
      </Col>
      <Col>
        <S3ImageComponent 
          imageName={values[currentField.name]} 
          style={{ height: '190px' }}
          imageStyle={{ borderRadius: '10px', marginTop: '10px' }}
        />
      </Col>
      <Col className="text-center">
        <ImageUploader
          withIcon={true}
          buttonText='Choose images'
          onChange={async (pictureFiles, pictureData) => {
            const file = pictureFiles[pictureFiles.length - 1]

            const filename = `${Date.now()}-${file.name}`;

            // UPLOAD PRIVATE FILE
            // const stored = await Storage.vault.put(filename, file, {
            //   contentType: file.type
            // })

            // UPLOAD PUBLIC FILE
            const stored = await Storage.put(filename, file, {
              contentType: file.type
            })

            setFieldValue(currentField.name, stored.key)          
          }}
          imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
          maxFileSize={5242880}
        />
      </Col>
    </Row>
  )
}

const VideoInputComponent = ({ values, currentField, setFieldValue }) => {

  const [isLoading, setIsLoading] = useState(false)

  const getTextToShow = () => {
    return values[currentField.name] === 'none'
      ? 'No video file'
      : values[currentField.name]
  }

  return (
    <Row>
      <Col xs={12}>
        <Label >
          { currentField.label }
        </Label>
      </Col>
      <Col>
        { isLoading 
          ?  <LoadingComponent isInline={true} />
          : <i>{ getTextToShow() }</i>
        }
      </Col>
      <Col className="text-center">
        <input
          className='video-input'
          type='file'
          id='file-input'
          accept='video/*'
          onChange={async (event) => {

            event.preventDefault()
            if (event.target.files[0] !== null) {

              const file = event.target.files[0]

              const filename = `${Date.now()}-${event.target.files[0].name}`;
  
              setIsLoading(true)

              // UPLOAD PRIVATE FILE
              // const stored = await Storage.vault.put(filename, file, {
              //   contentType: file.type
              // })
  
              // UPLOAD PUBLIC FILE
              const stored = await Storage.put(filename, file, {
                contentType: file.type
              })
  
              const devBucket = 'tes-content-bucket102614-dev'
              const prodBucket = 'tes-content-bucket105225-prod'
              const selectedPath = isDevInRuntime() ? devBucket : prodBucket
              const fullPath = `s3://${selectedPath}/public/${stored.key}`

              setFieldValue(currentField.name, fullPath)          
              setIsLoading(false)

            }

          }}
        />
      </Col>
    </Row>
  )
}

const SelectInputComponent = ({ values, currentField, handleChange, handleBlur }) => (
  <>
    { currentField.showLabel && <Label for={currentField.name}>{ currentField.label }</Label> }
    <InputGroup>
      <Input type={currentField.type} name={currentField.name} value={values[currentField.name]} onChange={handleChange} onBlur={handleBlur} placeholder={currentField.placeholder} >
        { currentField.options.map(option => (
          <option>
            { option }
          </option>
        ))}
      </Input>
    </InputGroup>
  </>
)

const TextInputComponent = ({ values, currentField, handleChange, handleBlur, isInline, isSubmitting }) => {

  const cancelComment = () => {
    currentField.showReply(false)
  }

  // hide comment input immediately after the comment is submitted
  if (currentField.isCommentThread) {
    if (isSubmitting) {
      setTimeout(() => {
        cancelComment()
      })
    }
  }

  return (
    <>
      { currentField.showLabel && <Label for={currentField.name}>{ currentField.label }</Label> }
      { currentField.isComment ?
        <CommentInputcontainer isCommentThread={currentField.isCommentThread}>
          <CommentWriterContainer>
            <CommentWriter
              type={currentField.type} 
              name={currentField.name} 
              value={values[currentField.name]} 
              onChange={handleChange} 
              onBlur={handleBlur} 
              placeholder={currentField.placeholder} 
              disabled={currentField.disabled} 
            >
            </CommentWriter>
            <SmileIcon>
              <FontAwesomeIcon icon={faSmile} />
            </SmileIcon>
          </CommentWriterContainer>
          <FormButtonContainer isCommentThread={currentField.isCommentThread}>
            { currentField.isCommentThread ?
              <>
                <CancelButton onClick={() => cancelComment}>
                  Cancel
                </CancelButton>
                <SendButton type="submit" disabled={isSubmitting}>
                  Send
                </SendButton>
              </>
              :
              <SendButton type="submit" disabled={isSubmitting}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </SendButton>
            }
          </FormButtonContainer>
        </CommentInputcontainer>
        :
        <>
          <InputGroup>
            <Input 
              type={currentField.type} 
              name={currentField.name} 
              value={values[currentField.name]} 
              onChange={handleChange} 
              onBlur={handleBlur} 
              autocomplete={currentField.autoComplete}
              placeholder={currentField.placeholder} 
              disabled={currentField.disabled} 
            />
            { isInline && (
            <InputGroupAddon addonType="append">
              <Button color="primary" type="submit" disabled={isSubmitting}>Submit</Button>
            </InputGroupAddon>
            )}
          </InputGroup>
        </>
      }
    </>
  )
}

const DateTimeComponent = ({ values, currentField, handleChange, handleBlur, setFieldValue }) => (
  <>
    { currentField.showLabel && <Label for={currentField.name}>{ currentField.label }</Label> }
    <InputGroup>
      <DateTime
        className="w-100"
        name={currentField.name} value={values[currentField.name]} onChange={dt => setFieldValue(currentField.name, dt)} onBlur={handleBlur} placeholder={currentField.placeholder} 
      />

    </InputGroup>
  </>
)

const resolveInputType = (values, currentField, handleChange, handleBlur, isInline, isSubmitting, setFieldValue ) => {
  switch (currentField.type) {
    case CHECKBOX_INPUT:
      return (
        <CheckboxInputComponent
          values={values}
          currentField={currentField} 
          handleChange={handleChange} 
        />
      )
    case RADIO_INPUT:
      return (
        <RadioInputComponent
          currentField={currentField} 
        />
      )
    case IMAGE_INPUT:
      return (
        <ImageInputComponent 
          values={values}
          currentField={currentField} 
          setFieldValue={setFieldValue}
        />
      )
    case VIDEO_INPUT:
      return (
        <VideoInputComponent 
          values={values}
          currentField={currentField} 
          setFieldValue={setFieldValue}
        />
      )
    case SELECT_INPUT:
      return (
        <SelectInputComponent
          values={values}
          currentField={currentField} 
          handleChange={handleChange} 
          handleBlur={handleBlur} 
        />
      )
    case TEXT_INPUT:
    case EMAIL_INPUT:
    case NUMBER_INPUT:
      return (
        <TextInputComponent 
          values={values}
          currentField={currentField} 
          handleChange={handleChange} 
          handleBlur={handleBlur} 
          isInline={isInline} 
          isSubmitting={isSubmitting}
        />
      )
    case DATE_TIME_INPUT:
      return (
        <DateTimeComponent
          values={values}
          currentField={currentField} 
          handleChange={handleChange} 
          handleBlur={handleBlur} 
          isSubmitting={isSubmitting} 
          setFieldValue={setFieldValue}
        />
      )
    default:
      return (<>input type not found</>)
  }
}

const UpdateStateComponent = ({ values, submit, isValid, formKey, submitValidation, validateForm }) => {
  
  useEffect(() => {
    validateForm(values)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    submit(values)
    submitValidation({ [formKey]: isValid })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid])

  return (<></>)
}

const BaseFormComponent = ({ form, submit, setNotification, formKey, submitValidation }) => {
  const initialValues = {}
  const fieldKeys = Object.keys(form.fields)
  fieldKeys.map((key) => {
    initialValues[key] = form.fields[key].defaultValue
    return key
  })

  const { isInline, showErrors } = form
  const [formErrors, setFormErrors] = useState({})
  const validationSchema = generateValidationSchema(form.fields)

  const cleanPayload = (payload) => {
    const payloadKeys = Object.keys(payload)
    const filter = new Filter()
    payloadKeys.forEach(key => {
      const fieldType = form.fields[key].type
      const keyValue = payload[key]
      if (fieldType === 'text' && keyValue.length > 0) {
        payload[key] = filter.clean(keyValue)
      }
    })
    return payload
  }

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const payload = cleanPayload(values)
    if(!form.persistOnSubmit) {
      resetForm(initialValues)
    }

    submit(payload)
      .then(() => {
        setSubmitting(false)
        setNotification && setNotification({
          context: '',
          type: 'FORM_SUBMISSION',
          text: 'Your form was submitted successfully',
          senderName: '',
          action: null,
        })
      })
      .catch((err) => showErrors && setFormErrors({ submit: err }))
  }

  const getErrorToShow = (errs) => {
    const keys = Object.keys(errs)
    if (keys.length === 0) {
      return ""
    } else {
      const errorToShow = errs[keys[0]]
      return errorToShow
    }
  }

  return (
    <div style={{width: '100%'}}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          submitted,
          validateForm,
          isValid
        }) => (
          <form onSubmit={handleSubmit}>
            { form.submit.autoUpdate && (
              <UpdateStateComponent 
                values={values} 
                submit={submit} 
                isValid={isValid} 
                validateForm={validateForm}
                formKey={formKey} 
                submitValidation={submitValidation} />
            )}
            <Row>
              { fieldKeys && fieldKeys.map((key) => {
                const currentField = form.fields[key]

                if (currentField.customValidation && currentField.customValidation(values)) {
                  const errors = formErrors
                  errors[currentField.name] = currentField.customValidationErrorMessage
                  setFormErrors(errors)
                } else {
                  const errors = formErrors
                  delete errors[currentField.name]
                  setFormErrors(errors)
                }

                return (
                  <Col key={key} 
                    xs={currentField.xs || 12} 
                    sm={currentField.sm} 
                    md={currentField.md} 
                    lg={currentField.lg} 
                    xl={currentField.xl} 
                    hidden={currentField.hidden}
                  >
                    <FormGroup className={isInline ? 'mb-0' : 'mb-3'}>
                      { resolveInputType(values, currentField, handleChange, handleBlur, isInline, isSubmitting, setFieldValue)}
                    </FormGroup>
                    <Row>
                      <Col style={{ color: 'red' }} >
                        { showErrors && touched[currentField.name] && errors[currentField.name] }
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
            <Row>
              <Col xs={12} style={{ color: 'red' } }>
                { getErrorToShow(formErrors) }
              </Col>
            </Row>
            
            { (!isInline && !form.submit.hideButton) && (
                <Row>
                  <Col>
                    <Button 
                      color="primary" 
                      type="submit" 
                      block={form.submit.isBlock} 
                      className="mt-3" 
                      disabled={isSubmitting || getErrorToShow(formErrors) !== "" }
                    >
                      { form.submit.text || "Submit" }
                    </Button>
                  </Col>
                </Row>
              )}
          </form>
        )}
      </Formik>
    </div>
  )
}

export default BaseFormComponent
