import styled from 'styled-components'
import { mediaQueries, boldDefaultSmallFont } from '../../../styles/helpers'

export const SideBarNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 3rem;
    height: auto;
    padding: 1rem 0 0.5rem 0;
    background-color: ${({ theme }) => theme.components.sideBar.backgroundColor };
    border-right: 1px solid ${({theme}) => theme.components.gallery.borderColor };

    ${mediaQueries('tablet')`
        width: 12rem;
        padding-left: 0.5rem;
    `};
`;

export const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const NavItem = styled.div`
    display: flex;
    justify-content: flex-start;
    border-radius: 0.3rem 0 0 0.3rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
    margin: 0.3rem 0;
    background-color: ${(props) => props.active ? 
        ({theme}) => {
            return theme.components.sideBar.itemComponentColorActive
        } : 
        ({theme}) => {
            return theme.components.sideBar.itemComponentColorPassive
        }
    };

    &:hover {
        cursor: pointer;
    }
`;

export const Logo = styled.div`
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 0.3rem;
    display: flex;
    padding: 0.5rem;
    border: 1px solid;

    border-color: ${(props) => props.active ? 
        `rgba(255, 255, 255, 0.8)`
        : 
        ({theme}) => { 
            return theme.components.sideBar.inActiveLogoBorderColor 
        }
    };

    background-color: ${(props) => props.active ? 
        ({theme}) => {
            return theme.components.sideBar.itemBackgroundColorActive
        } : 
        ({theme}) => {
            return theme.components.sideBar.itemBackgroundColorPassive
        }
    };

    color: ${(props) => props.active ? 
        ({theme}) => {
            return theme.components.sideBar.itemColorActive
        } : 
        ({theme}) => {
            return theme.components.sideBar.itemColorPassive
        }
    };

    svg {
        width: 1rem !important;
        height: 1rem !important;
    }
`;

export const Text = styled.div`
    display: none;
    ${boldDefaultSmallFont}
    margin: 0.45rem 0.6rem 0;
    color: ${({ theme }) => theme.components.sideBar.fontColor };

    ${mediaQueries('tablet')`
        display: block;
    `};
`;
