import { getAppId } from './brand-silo'
import { GET_SESSION } from '../components/guards/session/queries'
import { SEARCH_EVENTS_QUERY } from '../components/orchestrator/director/explore-director/queries'

export const GET_SESSION_REQUEST = ({ cognitoId }) => {
  const appId = getAppId()

  const request = {
    query: GET_SESSION,
    variables: {
      request: {
        cognitoId,
        appId,
      },
    },
  }

  return request
}


export const SEARCH_EVENT_REQUEST = ({ eventId, profileId }) => {

  const request = {
    query: SEARCH_EVENTS_QUERY,
    variables: {
      request: {
        eventId, 
        profileId,
      },
    },
  }

  return request
}