import { gql } from '@apollo/client'

export const PROFILE_EDITS_MUTATION = gql`
    mutation editProfile($request: EditProfileRequest) {
        editProfile(request: $request) {
            displayName
            interest
            primaryImageUrl
        }
    }
`

export const ATTRIBUTE_EDITS_MUTATION = gql`
    mutation editAttribute($request: EditAttributeRequest) {
        editAttribute(request: $request) {
            attributeId
            entityId
            entityType
            type
            isVerified
            primaryImageUrl
            title
            position
            startDate
            endDate
            description
        }
    }
`
