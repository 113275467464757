import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import EventIngressComponent from './event-ingress'
import PartySelectorComponent from './party-selector'
import ActiveEventComponent from './active-event'
import PeerConnectComponent from './connect-event'
import { mapAppEvents } from '../../../../utils/mapper'
import { SEARCH_EVENTS_QUERY } from '../explore-director/queries'
import { eventList, myProfile } from '../../../../shared/cache'
import CachedFieldComponent from '../../../../shared/cache/helpers/CachedFieldComponent'
import { getCurrentBrandConfig } from '../../../../utils/brand-silo'


const VIEWS = {
    INGRESS: "INGRESS",
    PEER_CONNECT: "PEER_CONNECT",
    PARTY_SELECTOR: "PARTY_SELECTOR",
    ACTIVE: "ACTIVE",
    INTERMISSION: "INTERMISSION",
    EGRESS: "EGRESS", 
}

const goToIngress = (setView, setViewerType) => () => {
    setViewerType()
    setView(VIEWS.INGRESS)

}

const goToConnect = (setView, setViewerType) => (viewerType, displayName, code) => {
    setViewerType(viewerType)
    setView(VIEWS.PEER_CONNECT)
}

const goToPartySelector = (setView, setLocalMedia, setViewerType) => (viewerType, localMedia) => {
    if (localMedia) {
        setLocalMedia(localMedia)
    }
    if (viewerType) {
        setViewerType(viewerType)
    }
    setView(VIEWS.PARTY_SELECTOR)
}

const goToActiveEvent = (setView, setLocalMedia, setViewerType, setHostSelectedParty) => (viewerType, localMedia, hostSelectedParty) => {

    if (localMedia) {
        setLocalMedia(localMedia)
    }
    if (viewerType) {
        setViewerType(viewerType)
    }
    if (hostSelectedParty) {
        setHostSelectedParty(hostSelectedParty)
    }
    setView(VIEWS.ACTIVE)
}


const resolveView = (props) => {
    const { 
        authUser, profile, event, 
        setProfile, linkProfileRef, appConfig, 
        updateEventDetails, currentView, setView, 
        localMedia, setLocalMedia, viewerType, setViewerType,
        masterDetails, setMasterDetail, partyDetails,
        hostSelectedParty, setHostSelectedParty, goToLanding,
        pollDetails, hasStream
    } = props

    switch (currentView) {
        case VIEWS.INGRESS:
            return <EventIngressComponent 
                        event={event}
                        profile={profile}
                        goToEvent={goToActiveEvent(setView, setLocalMedia, setViewerType)} 
                        goToPeerConnect={goToConnect(setView, setViewerType)} 
                        setMasterDetail={setMasterDetail}
                        authUser={authUser} 
                        linkProfileRef={linkProfileRef} 
                        setProfile={setProfile}
                        updateEventDetails={updateEventDetails}
                    />
        case VIEWS.PEER_CONNECT:
            return <PeerConnectComponent 
                        event={event} 
                        viewerType={viewerType}
                        goToEvent={goToActiveEvent(setView, setLocalMedia, setViewerType)}  
                        goToPartySelector={goToPartySelector(setView, setLocalMedia, setViewerType)}
                        partyDetails={partyDetails}
                    />
        case VIEWS.PARTY_SELECTOR:
            return <PartySelectorComponent
                        appConfig={appConfig} 
                        event={event} 
                        viewerType={viewerType} 
                        profile={profile}  
                        partyDetails={partyDetails}
                        updateEventDetails={updateEventDetails}
                        goToActiveEvent={goToActiveEvent(setView, setLocalMedia, setViewerType, setHostSelectedParty)}  
                    />
        case VIEWS.ACTIVE:
            return <ActiveEventComponent
                        appConfig={appConfig} 
                        event={event} 
                        viewerType={viewerType} 
                        localMedia={localMedia} 
                        profile={profile}  
                        linkProfileRef={linkProfileRef}  
                        masterDetails={masterDetails}
                        partyDetails={partyDetails}
                        hostSelectedParty={hostSelectedParty}
                        updateEventDetails={updateEventDetails}
                        pollDetails={pollDetails}
                        goBack={goToIngress(setView, setViewerType)}
                        hasStream={hasStream}
                    />
        case VIEWS.INTERMISSION:
        case VIEWS.EGRESS:
        default:
            goToLanding()
            return <>not sure how you got here</>
    }
}


const resolveInitialView = (event) => {
    const { viewerTypes, type } = event
    const { allowParties, allowFrontRow } = viewerTypes
    const isSingleEventApp = type === 'SINGLE_APP'
    const isGroupEventApp = type === 'GROUP_EVENT'

    if (allowParties || allowFrontRow) {
        return VIEWS.INGRESS
    } else if (isSingleEventApp || isGroupEventApp) {
        // return VIEWS.PEER_CONNECT
        return VIEWS.INGRESS
    } else {
        return VIEWS.ACTIVE
    }
}

const resolveInitialViewerType = (initialView, event) => {
    const isGroupEventApp = event.type === 'GROUP_EVENT'

    if (initialView === VIEWS.ACTIVE ? 'SOLO' : undefined) {
        return 'SOLO'
    } else if (isGroupEventApp) {
        return 'PARTY'
    } else {
        return undefined
    }
}

const EventDirector = (props) => {
    const { event, hasStream } = props
    
    const profile = useReactiveVar(myProfile.reactive)

    const initialView = resolveInitialView(event)
    const initialViewerType = resolveInitialViewerType(initialView, event) 

    const [currentView, setView] = useState(initialView)
    const [localMedia, setLocalMedia] = useState()
    const [viewerType, setViewerType] = useState(initialViewerType)
    const [masterDetails, setMasterDetail] = useState()

    const [hostSelectedParty, setHostSelectedParty] = useState()

    const eventDirectorProps = {
        ...props,
        profile, event, hasStream,
        currentView, setView, 
        localMedia, setLocalMedia, 
        viewerType, setViewerType,
        masterDetails, setMasterDetail,
        hostSelectedParty, setHostSelectedParty
    }

    if (profile && profile.displayName) {
        return (
            <div className="mx-1 mx-md-5" >

                { resolveView(eventDirectorProps) }

            </div>
        )
    } else {
        return <></>
    }
}


const EventDirectorWithQuery = (props) => {
    const { event: { eventId, type }, goToLanding, setSidebar } = props

    if (type && type === 'GROUP_EVENT') {
        return (
            <EventDirector
                {...props}
                hasStream={false}
                event={props.event}
            />
        )    
    
    } else {
        const { allowStreams } = getCurrentBrandConfig()
    
        let requestStream = undefined
    
        if (allowStreams) {
            requestStream = 'streamUrl'
        }
    
        const variables = { request: { eventId } }
    
        const handleError = () => {
            setSidebar(true)
            goToLanding()
        }

        return (
            <CachedFieldComponent
                query={SEARCH_EVENTS_QUERY}
                variables={variables}
                cache={eventList}
                find={eventId}
                required={requestStream}
                handleError={handleError}
                render={(cacheProps) => {
                    
                    const event = (cacheProps.hosts === null || cacheProps.hosts === undefined)
                        ? mapAppEvents([cacheProps])[0]
                        : cacheProps

                    return (
                        <EventDirector
                            {...props}
                            hasStream={allowStreams}
                            event={event}
                        />
                    )
                }}
            />
        )
    }
}


const EventDirectorWithDataCheck = (props) => {
    const { setSidebar } = props

    useEffect(() => {
        setSidebar(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <EventDirectorWithQuery {...props} />
}

export default EventDirectorWithDataCheck
