import React, { useState } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchool, faBuilding, faAddressCard, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import EditProfileAttributeModal from '../edit-profile-modal/edit-profile-attribute-modal'


const ExperienceItem = ({ toggle, item, isMyProfile }) => {

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]

  const formatDate = date => `${monthNames[date.getMonth()]} ${date.getFullYear()}`
  
  return (
    <Col xs={12} >
      <p >
        <b>{ item.position }</b>
        { item.isVerified && <FontAwesomeIcon className="ml-2"  icon={faCheckCircle} /> }
        <br />
        <i>{ formatDate(new Date(item.startDate)) } - { formatDate(new Date(item.endDate)) }</i>
        <br />
        { item.description }
        { isMyProfile && 
          <>
            <br />
            <Button onClick={() => toggle(item)}>Edit</Button>
          </>
        }
      </p>
    </Col>
  )
}

const ExperienceCategory = ({ appId, authUser, profileId, category, isMyProfile }) => {
  const { key, icon, value, items: categoryItems } = category
  const [editAttrModal, setEditAttrModal] = useState({ isOpen: false, attribute: undefined })
  const toggle = (attribute) => {
    setEditAttrModal({ attribute, isOpen: !editAttrModal.isOpen })
  }

  const sortedActivities = categoryItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))

  return (
    <Row className="text-left" >
      <Col xs={12}>
        <h3 className="ml-5 mt-3 ">{ value }</h3>
      </Col>

      { sortedActivities.length > 0 &&  (
        <Col xs={12} className="p-3"  >
          <Row>
            <Col xs={2} className="d-flex">
              <FontAwesomeIcon className="ml-auto" size="5x" icon={icon} />
            </Col>

            <Col xs={10} >
              <h4>{ sortedActivities[0].title }</h4>


              { sortedActivities.length > 0 && 
                  sortedActivities.map( item => (
                    <ExperienceItem toggle={toggle} item={item} isMyProfile={isMyProfile} /> 
                  ))
              }
            </Col>
          </Row>
        </Col>
      )}


      { isMyProfile && 
        <Col xs={12} className="text-center" >
          <EditProfileAttributeModal 
            isOpen={editAttrModal.isOpen}
            type={key} 
            attributeData={editAttrModal.attribute} 
            toggle={toggle}
            profileId={profileId}
            appId={appId}
            authUser={authUser}
          />
          <Button onClick={() => toggle()} >Add { value.toLowerCase() } experience</Button>
        </Col>
      }
    </Row>
  )
}
  
const ExperienceComponent = ({ profile, appConfig, authUser, isMyProfile }) => {

  const { appId } = appConfig

  const { attributes, profileId } = profile

  const categories = [
    {
      key: 'EDUCATION',
      value: 'Education',
      icon: faSchool
    },
    {
      key: 'EXTRA_CURRICULAR',
      value: 'Extra Curricular',
      icon: faBuilding
    },
    {
      key: 'WORK',
      value: 'Work',
      icon: faAddressCard
    }
  ]

  const mappedCategories = categories.map(c => {
    return {
      items: (attributes && attributes.length) ? attributes.filter(a => a.type === c.key) : [], 
      ...c
    }
  })

  return (
    <Row className="mb-5" >
      <Col>
        { mappedCategories && mappedCategories.map(category => ( 
          <ExperienceCategory appId={appId} profileId={profileId} authUser={authUser} category={category} isMyProfile={isMyProfile} /> 
        ))}
      </Col>
    </Row>
  )
}

export default ExperienceComponent
