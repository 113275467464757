import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            hasError: false
        }
    }
  
    

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo })
    }
  
    render() {
        if (this.state.hasError) {
            if (window.location.href.includes('localhost')) {
                return this.props.children
            } else {
                window.location.reload()
                return <></>
            }
        } else {
            return this.props.children; 
        }
    }
}
  

export default ErrorBoundary