import React, { useState } from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { getAppId } from '../../../utils/brand-silo'
import { REPORT_VIOLATION_MUTATION } from './mutations'

const ReportUserModal = ({ isOpen, toggle, profileId }) => {
  const [reportType, setReportType] = useState('Abuse')

  const [reportViolation] = useMutation(REPORT_VIOLATION_MUTATION)

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'violationType') {
      setReportType(value)
    }
  }

  const reportUser = async () => {
    const appId = getAppId()
    const description = ''
    const variables = {
      request: {
        appId,
        targetId: profileId,
        targetType: 'PEER',
        violationType: reportType,
        description,
      },
    }
    toggle()
    return reportViolation({ variables })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ zIndex: 6000 }}>
      <ModalHeader toggle={toggle} style={{ zIndex: 6000 }}>Report a User</ModalHeader>
      <ModalBody style={{ zIndex: 6000 }}>
        What did the user do wrong?
        <FormGroup>
          <Label for="violationType">Violation Type</Label>
          <Input type="select" name="violationType" id="violationType" onChange={handleChange} style={{ zIndex: 6500 }}>
            <option>Abuse</option>
            <option>Nudity</option>
            <option>Fake Account</option>
          </Input>
        </FormGroup>

      </ModalBody>
      <ModalFooter style={{ zIndex: 6000 }}>
        <Button color="primary" block onClick={reportUser} style={{ zIndex: 6500 }}>Submit</Button>
        <Button color="secondary" block onClick={toggle} style={{ zIndex: 6500 }}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ReportUserModal
