import React from 'react'
import { Card, CardText, CardBody, CardTitle, CardSubtitle, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faComment, faEye } from '@fortawesome/free-solid-svg-icons'
import S3ImageComponent from '../../s3-image-component'


const isValidLinkUrl = (imgUrl) => (imgUrl && imgUrl !== '' && imgUrl !== 'none')

const formatDateTime = (dt) => new Date(dt).toDateString()

const PostComponent = ({ index, profile, post }) => {

    const {
        displayName,
    } = profile

    const {
        text,
        primaryImageUrl,
        linkUrl,
        createdAt,
        activity
    } = post

    const {
        likes,
        views,
        comments
    } = activity

    return (
        <div className="text-left" >
            <Card className={index === 0 ? 'm-3' : 'mx-3 mb-3' } >
                <CardBody>
                    <CardTitle tag="h5">{ displayName }</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{ formatDateTime(createdAt) }</CardSubtitle>
                    <CardText>
                        { isValidLinkUrl(primaryImageUrl) && 
                            <S3ImageComponent 
                                imageName={primaryImageUrl} 
                                style={{ height: 'auto', width: '100%' }} 
                            />
                        }
                        <Row className="mt-4" >
                            <Col xs={4} className="text-left" >
                                { likes }
                                <FontAwesomeIcon className="ml-2"  icon={faHeart} /> 
                            </Col>
                            <Col xs={4} className="text-center" >
                                { comments.length }
                                <FontAwesomeIcon className="ml-2"  icon={faComment} /> 
                            </Col>
                            <Col xs={4} className="text-right" >
                                { views }
                                <FontAwesomeIcon className="ml-2"  icon={faEye} /> 
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} >
                                { text }
                                { isValidLinkUrl(linkUrl) && 
                                    <>
                                        <br />
                                        <a href={linkUrl} target="_blank" rel="noopener noreferrer" >{ linkUrl }</a>
                                    </>
                                }
                            </Col>
                        </Row>
                    </CardText>
                </CardBody>
            </Card>
        </div>
    )
}

export default PostComponent
