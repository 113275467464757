import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { currentMessengerContext } from '../../shared/cache'
import InlineMessengerComponent from './inline-messenger'


const MessengerComponentWithData = (props) => {

  const currentContext = useReactiveVar(currentMessengerContext.reactive)

  if (currentContext === null) {
    return (<></>)
  }

  return (
    <InlineMessengerComponent {...props} currentContext={currentContext} /> 
  )
}

export default MessengerComponentWithData
