import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import LikeComponent from '../../like-component'
import { BaseHeroContainer, LandscapeContainer, LandscapeSubContainerLeft, LandscapeSubContainerRight, LandscapeSubContainerButtons, LocationLabel, BottomContainer, BottomSubContainerLeft, BottomSubContainerRight, Logo, ProfileImg, DetailsContainer, Title, Description } from './style'
import S3ImageComponent from '../../s3-image-component'

const BaseHeroComponent = ({
  name, imageUrl, headerImageUrl, targetId, targetType, isLiked, description, showLikeToggle = true, primaryRender, secondaryRender, labelText
}) => {
  const hasImage = imageUrl && imageUrl !== 'none'
  const hasHeaderImage = headerImageUrl && headerImageUrl !== 'none'

  return (
    <BaseHeroContainer>
      <LandscapeContainer>
        { hasHeaderImage && (
          <S3ImageComponent 
              imageName={headerImageUrl} 
              imageStyle={{ position: 'absolute', width: '100%', objectFit: 'cover' }}
            />
        )}

        <LandscapeSubContainerLeft>
          { labelText && (
            <LocationLabel targetType={targetType}>
              { labelText }
            </LocationLabel>
          )}
        </LandscapeSubContainerLeft>
        <LandscapeSubContainerRight>
          { (showLikeToggle || secondaryRender) && 
            <LandscapeSubContainerButtons>
              { showLikeToggle && 
                <LikeComponent targetId={targetId} targetType={targetType} isLikedInitial={isLiked} />
              }
              { secondaryRender && secondaryRender() }
            </LandscapeSubContainerButtons>
          }
        </LandscapeSubContainerRight>
      </LandscapeContainer>
      <BottomContainer>
        <BottomSubContainerLeft>
          <ProfileImg targetType={targetType}>
            {/* Filler user icon for user profile pic */}
            <Logo>
              { hasImage 
                ? <S3ImageComponent 
                    imageName={imageUrl} 
                    style={{ width: '10rem', height: '10rem' }}
                    imageStyle={{ borderRadius: '100%' }}
                  />
                : <FontAwesomeIcon icon={faUser} />
              }
            </Logo>
          </ProfileImg>
          <DetailsContainer>
            <Title>
              { name && name }
            </Title>
            <Description>
              { description && description }
            </Description>
          </DetailsContainer>
        </BottomSubContainerLeft>
        <BottomSubContainerRight>
        { primaryRender && primaryRender() }
        </BottomSubContainerRight>
      </BottomContainer>
    </BaseHeroContainer>
  )
}

export default BaseHeroComponent
