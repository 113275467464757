import React from 'react'
import { DragSource } from 'react-dnd'

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  color: 'black',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
}

const DndPartySource = ({
  peer, myProfileId, isDragging, connectDragSource,
}) => {
  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={connectDragSource} style={{ ...style, opacity }}>
      {peer.displayName}
    </div>
  )
}

export default DragSource(
  'box',
  {
    beginDrag: ({ peer }) => ({ peer }),
    endDrag({ setPeerDragged }, monitor) {
      const dropResult = monitor.getDropResult()
      if (dropResult) {
        setPeerDragged(dropResult)
      }
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(DndPartySource)
