import styled, { css } from 'styled-components'
import { mediaQueries, boldDefaultSmallFont } from '../../styles/helpers'

export const TopNavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: ${ ({ theme }) => theme.components.navBar.backgroundColor };
    border-bottom: 1px solid ${({theme}) => theme.components.navBar.borderColor };

`;

export const ProfileThumbnailContainer = styled.div`
    display: none;
    justify-content: space-between;
    position: relative;
    height: 53px;
    border-radius: 3rem;
    padding: 0.3rem 0rem 0.3rem 1rem;
    margin: auto 0 auto .5rem;    
    background-color: ${ ({theme}) => {
        return theme.components.navBar.thumbnailContainerColor
    }};

    ${mediaQueries('tablet')`
        display: flex;
    `}
    
`;

export const ProfileThumbnailText = styled.div`
    ${boldDefaultSmallFont}
    padding: 0.65rem 0 0.5rem;
    white-space: nowrap;
    color: ${ ({theme}) => {
        return theme.components.navBar.logoColor;
    }};
`;

export const ProfileImageStyles = css`
    width: 100%;
    border-radius: 2rem;
`;

export const UserIconStyles = css`
    padding: 0.5rem;
    svg {
        width: 1.5rem !important;
        height: 1.5rem;
        path {
            fill: ${ ({theme}) => {
                return theme.components.navBar.iconColor
            }};
        };
    };
`;

export const ProfileThumbnailImageContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.1rem 0.3rem 0 1rem;
    border-radius: 2rem;
    background-color: ${ ({theme}) => {
        return theme.components.navBar.iconBackgroundColor
    }};

    ${(props) => props.hasImage 
        ? ProfileImageStyles
        : UserIconStyles
    };
`;


export const SideNavToggler = styled.div`

    ${mediaQueries('tablet')`
        display: none;
    `}


`