import React from 'react'


const BaseLogoComponent = ({ logo, style, goToLanding }) => (
  <span onClick={goToLanding} style={{ cursor: 'pointer', ...style }} className="font-logo my-auto"  >
    { logo }
  </span>
)


const AppLogo = ({ appId, logo, goToLanding }) => {
  let style = {}

  switch (appId) {
    case 'SPEAKEASY':
      style = { fontSize: '28px', paddingTop: '0.45rem' }
      break
    case 'TV':
    case 'TESSERACT':
      style = { fontSize: '18px', fontStyle: 'italic', fontWeight: 'bold' }
      break
    case 'NEXTSTEP':
      style = { fontSize: '28px' }
      break
    default:
      break
  }

  return (
    <BaseLogoComponent
      style={style}
      appId={appId}
      logo={logo}
      goToLanding={goToLanding}
    />
  )
}

export default AppLogo

