import { gql } from '@apollo/client'

export const LIKE_ACTION_MUTATION = gql`
  mutation likeAction($request: LikeActionRequest) {
    likeAction(request: $request) {
        appId
        targetId
        targetType
        value
    }
  }
`
