import React from 'react'
import ThumbnailImageComponent from '../../thumbnail-image-container'
import { StreamThumbnailContainer } from './style'


const BaseStreamComponent = ({ render, profile, showThumbnail }) => {

    const containerStyle = {
        width: '100%',
        // height: '100px',
        position: 'relative'
    }
        
    const videoStyle = {
        width: '100%',
        objectFit: 'cover',
        backgroundColor: 'gray'
    }
    
    return (
        <div style={containerStyle} >
            <div className="px-1" >
                { render(videoStyle) }
            </div>
            <StreamThumbnailContainer >
                { showThumbnail && (
                    <ThumbnailImageComponent profile={profile} noMargin={true} />
                )}
                { profile.displayName && (
                    <span className="text-white" >{profile.displayName.substring(0, 13) }</span>
                ) }

            </StreamThumbnailContainer>
        </div>
    )
}

export default BaseStreamComponent