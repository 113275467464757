import styled from 'styled-components'
import { semiBoldDefaultSmallFont } from '../../styles/helpers'

export const CommentInputcontainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.isCommentThread ? 'column' : 'row'};
    margin-top: ${(props) => props.isCommentThread ? '0.8rem' : '0'};
`;

export const CommentWriterContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const CommentWriter = styled.input`
    outline: none;
    width: 100%;
    height: 2.3rem;
    border-radius: 1.3rem;
    padding: 0 2.3rem 0 1rem;
    font-size: 1rem;
    background-color: rgba(125, 131, 134, 0.3);
    border: 1px solid #7D838680;
    color: rgb(167, 167, 167, 1);
`;

export const SmileIcon = styled.span`
    position: absolute;
    display: inline;
    right: 0.5rem;
    top: 0.5rem;

    svg {
        width: 1.3rem !important;
        height: 1.3rem;
    }
`;

export const FormButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${(props) => props.isCommentThread ? '1rem' : '0'};
    ${semiBoldDefaultSmallFont}
`;

export const SendButton = styled.button`
    padding: 0.3rem 0.8rem;
    background-color: ${({theme}) => {
        return theme.color.primary;
    }};
    margin-left: .2rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
`;

export const CancelButton = styled.button`
    border: none;
    background-color: transparent;
    color: ${({theme}) => {
        return theme.color.primary;
    }};
`;
