import styled from 'styled-components'
import { mediaQueries } from '../../../styles/helpers';

export const StreamThumbnailContainer = styled.div`
    width: 100%;
    position: absolute;
    left: 0;

    ${mediaQueries('smallPhone')`
        top: .2rem;
    `}
    ${mediaQueries('phone')`
        top: 2.3rem;
    `}

    ${mediaQueries('tablet')`
        top: 2rem;
    `}

    ${mediaQueries('desktop')`
        top: 4.3rem;
    `}

    ${mediaQueries('maxDesktop')`
        top: 5rem;
    `}
`;
