import React, { useEffect } from 'react'

const DotsAnimation = () => {
  useEffect(() => {
    const canvas = document.getElementById('dotCanvas')
    const context = canvas.getContext('2d')

    // Set display size (css pixels).
    const width = 300
    const height = 350
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`

    // Set actual size in memory (scaled to account for extra pixel density).
    const scale = window.devicePixelRatio // <--- Change to 1 on retina screens to see blurry canvas.
    canvas.width = width * scale
    canvas.height = height * scale

    // Normalize coordinate system to use css pixels.
    context.scale(scale, scale)

    console.log({ canvas })
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height

    const color1 = '#7230F7'
    const color2 = '#F73069'
    const color3 = '#0FB947'
    const color4 = '#E4B00F'
    const transitionColor = '#CCD1D1'
    const userIconColor = '#f7f7f7'

    const delay = 50
    const defaultRadius = 25
    const dot1x = 2 * defaultRadius
    const dot1y = 2 * defaultRadius
    const dot2x = 4 * defaultRadius
    const dot2y = dot1x
    const dot3x = dot2x
    const dot3y = 4 * defaultRadius
    const dot4x = dot1x
    const dot4y = dot3y
    /// //////
    const dot5x = 9 * defaultRadius
    const dot5y = dot1y
    const dot6x = 8 * defaultRadius
    const dot6y = dot3y
    const dot7x = 10 * defaultRadius
    const dot7y = dot3y
    /// //////
    const dot8x = dot6x
    const dot8y = 8 * defaultRadius
    const dot9x = dot7x
    const dot9y = dot8y
    const dot10x = dot8x
    const dot10y = 10 * defaultRadius
    const dot11x = dot9x
    const dot11y = dot10y
    const dot12x = 9 * defaultRadius
    const dot12y = 12 * defaultRadius
    /// /////
    const dot13x = dot1x
    const dot13y = dot8y
    const dot14x = dot2x
    const dot14y = dot13y
    const dot15x = 3 * defaultRadius
    const dot15y = dot10y

    let i = 0
    const dot1 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot2 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot3 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot4 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot5 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot6 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot7 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot8 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot9 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot10 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot11 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot12 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot13 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot14 = {
      x: 0, y: 0, radius: 0, fill: '',
    }
    const dot15 = {
      x: 0, y: 0, radius: 0, fill: '',
    }

    setDotsToInitialConditions()
    const dots = [dot1, dot2, dot3, dot4, dot5, dot6, dot7, dot8, dot9, dot10, dot11, dot12, dot13, dot14, dot15]

    drawAllDots()

    setTimeout(() => {
      window.requestAnimationFrame(moveDot)
    }, 0)

    function setDotsToInitialConditions() {
      dot1.x = dot1x
      dot1.y = dot1y
      dot1.radius = defaultRadius
      dot1.fill = color1

      dot2.x = dot2x
      dot2.y = dot2y
      dot2.radius = defaultRadius
      dot2.fill = color1

      dot3.x = dot3x
      dot3.y = dot3y
      dot3.radius = defaultRadius
      dot3.fill = color1

      dot4.x = dot4x
      dot4.y = dot4y
      dot4.radius = defaultRadius
      dot4.fill = color1
      /// ///////////
      dot5.x = dot5x
      dot5.y = dot5y
      dot5.radius = defaultRadius
      dot5.fill = color2

      dot6.x = dot6x
      dot6.y = dot6y
      dot6.radius = defaultRadius
      dot6.fill = color2

      dot7.x = dot7x
      dot7.y = dot7y
      dot7.radius = defaultRadius
      dot7.fill = color2
      /// //////////////
      dot8.x = dot8x
      dot8.y = dot8y
      dot8.radius = defaultRadius
      dot8.fill = color3

      dot9.x = dot9x
      dot9.y = dot9y
      dot9.radius = defaultRadius
      dot9.fill = color3

      dot10.x = dot10x
      dot10.y = dot10y
      dot10.radius = defaultRadius
      dot10.fill = color3

      dot11.x = dot11x
      dot11.y = dot11y
      dot11.radius = defaultRadius
      dot11.fill = color3

      dot12.x = dot12x
      dot12.y = dot12y
      dot12.radius = defaultRadius
      dot12.fill = color3
      /// ///////////////
      dot13.x = dot13x
      dot13.y = dot13y
      dot13.radius = defaultRadius
      dot13.fill = color4

      dot14.x = dot14x
      dot14.y = dot14y
      dot14.radius = defaultRadius
      dot14.fill = color4

      dot15.x = dot15x
      dot15.y = dot15y
      dot15.radius = defaultRadius
      dot15.fill = color4
    }

    function drawAllDots() {
      dots.forEach((dot) => drawDot(dot))
    }

    function moveDot() {
      context.clearRect(0, 0, canvasWidth, canvasHeight)
      i += 1

      if (i < 1) {
        dot2.fill = color1
      } else if (i >= 1 && i < (defaultRadius) + 1) {
        dot1.x += 1
        dot5.x += 1
        dot2.x += 4
        dot2.fill = transitionColor
      } else {
        dot2.fill = color2
      }

      if (i < defaultRadius + 1 * delay) {
        dot8.fill = color3
        dot10.fill = color3
      } else if (i >= defaultRadius + 1 * delay && i < 2 * defaultRadius + 1 * delay) {
        dot9.x -= 1
        dot12.x -= 1
        dot15.x -= 1
        dot1.x -= 1
        dot12.y -= 2
        dot3.y -= 2
        dot8.x -= 4
        dot8.y -= 4
        dot10.x -= 4
        dot8.fill = transitionColor
        dot10.fill = transitionColor
      } else {
        dot8.fill = color1
        dot10.fill = color4
      }

      if (i < 2 * defaultRadius + 2 * delay) {
        dot6.fill = color2
        dot7.fill = color2
      } else if (i >= 2 * defaultRadius + 2 * delay && i < 3 * defaultRadius + 2 * delay) {
        dot9.x += 1
        dot11.x -= 1
        dot9.y += 2
        dot11.y += 2
        dot5.y += 2
        dot6.y += 4
        dot6.fill = transitionColor
        dot7.y += 4
        dot7.fill = transitionColor
      } else {
        dot6.fill = color3
        dot7.fill = color3
      }

      if (i < 3 * defaultRadius + 3 * delay) {
        dot14.fill = color4
        dot14.fill = color4
      } else if (i >= 3 * defaultRadius + 3 * delay && i < 4 * defaultRadius + 3 * delay) {
        dot15.x += 1
        dot2.x += 1
        dot10.y -= 2
        dot14.x += 4
        dot14.y -= 4
        dot14.fill = transitionColor
      } else {
        dot14.fill = color2
      }

      if (i > 4 * defaultRadius + 4 * delay - 1) {
        i = 0
        setDotsToInitialConditions()
      }

      // Draw the dot in its new position.
      drawAllDots()

      // Render it again
      window.requestAnimationFrame(moveDot)
    }

    function drawDot(dot) {
      context.beginPath()
      context.arc(dot.x, dot.y, dot.radius, 0, 2 * Math.PI, false)
      context.fillStyle = dot.fill
      context.fill()

      const semiRadius = 15
      const semiStart = 11 * Math.PI / 6
      const semiFinish = 7 * Math.PI / 6
      context.beginPath()
      context.arc(dot.x, dot.y + 18, semiRadius, semiStart, semiFinish, true)
      context.fillStyle = userIconColor
      context.fill()

      context.beginPath()
      context.arc(dot.x, dot.y - 4, 7, 0, 2 * Math.PI, false)
      context.fillStyle = userIconColor
      context.fill()
    }
  }, [])

  return (
    <canvas id="dotCanvas" />
  )
}

export default DotsAnimation
