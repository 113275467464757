import React from 'react'
import { Row, Col } from 'reactstrap'
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react'

const AuthorizeComponent = ({
  page, appData, render,
}) => {
  const pageConfig = appData.pageConfigs.find((c) => c.key === page)
  const { isAuthorized } = pageConfig

  // TODO:
  // figure this casing issue fromt this link
  // https://github.com/aws-amplify/amplify-js/issues/4035
  
  if (isAuthorized) {
    return (
      <Row className="w-100 mt-3 mx-0">
        <Col xs={12} className="text-center px-0">
          <AmplifyAuthenticator >
            <AmplifySignUp
              slot="sign-up"
              usernameAlias="email"
              formFields={[
                {
                  type: "email",
                  label: "Email Address",
                  placeholder: "Email Address",
                  required: true,
                },
                {
                  type: "password",
                  label: "Password",
                  placeholder: "Password",
                  required: true,
                },
                {
                  type: "phone_number",
                  label: "Phone Number",
                  placeholder: "(555) 555-1212",
                  required: true,
                },
              ]} 
            />
            { render() }
          </AmplifyAuthenticator>
        </Col>
      </Row>
    )
  }
  return render()
}

export default AuthorizeComponent
