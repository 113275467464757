import React from 'react'
import Amplify, { Storage } from 'aws-amplify'
import { ApolloProvider } from '@apollo/client'
import AppOrchestrator from './components/orchestrator'
import ErrorBoundary from './components/error-boundary'
import ApolloClient from './graphql'
import aws_exports from './aws-exports'

Amplify.configure(aws_exports)
Storage.configure(aws_exports)

window.onpopstate = ({ currentTarget }) => {
  const { hash } = currentTarget.location
  if (hash === '') {
    window.location.reload()
  }
}

const App = () => (
  <ErrorBoundary >
    <ApolloProvider client={ApolloClient}>
      <AppOrchestrator />
    </ApolloProvider>
  </ErrorBoundary>
)

export default App
