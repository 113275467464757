import React from 'react'
import { Container } from 'reactstrap'
import { useQuery } from '@apollo/client'
import ChannelHeroComponent from '../../../hero/channel-hero-component'
import BaseGalleryComponent from '../../../../shared/base-gallery'
import { SEARCH_CHANNELS_QUERY } from './queries'
import { SEARCH_EVENTS_QUERY } from '../../../orchestrator/director/explore-director/queries'
import LoadingComponent from '../../../loading-component'
import { mapAppEvents } from '../../../../utils/mapper'
import { myProfile } from '../../../../shared/cache'

const ChannelGalleryView = (props) => {
  const { channel, isChannelMember } = props

  const variables = {
    request: {
      channelId: channel.channelId
    },
  }

  const { data, loading } = useQuery(SEARCH_EVENTS_QUERY, { variables })

  if (loading) {
    return (
      <LoadingComponent />
    )
  }

  const { results: events } = data.searchEvents
  const { displayName: gn, affiliateGroupId: groupId } = channel
  const groups = isChannelMember ? [{ displayName: `${gn} Affiliates`, groupId, members: [] }] : []
  const mappedEvents = mapAppEvents(events)

  return(
    <BaseGalleryComponent
     events={mappedEvents} 
     targetType={'CHANNEL'}
     groups={groups}
     isMyProfile={false}
     {...props} />
  )

}


const ChannelDirector = ({
  appConfig,
  messenger,
  setMessengerContext,
  goToEvent,
  goToGroupDetails,
  goToEventDetails,
  goToProfile,
  channel,
  authUser,
}) => {
  
  let currentChannel = channel

  const variables = {
    request: {
      channelId: currentChannel.channelId,
      slug: currentChannel.slug
    },
  }

  const { data: channelSearchResults , loading: loadingChannels } = useQuery(SEARCH_CHANNELS_QUERY, { variables })

  if (!currentChannel.appId) {
    if (loadingChannels) {
      return <LoadingComponent isInline />
    } else {
      if (channelSearchResults.searchChannels.metadata.count > 0) {
        currentChannel = channelSearchResults.searchChannels.results[0]
      }
    }
  } 

  const profile = myProfile.reactive()

  const myProfileId = profile && profile.profileId ? profile.profileId : ''
  const isChannelMember = myProfileId === currentChannel.profileId

  return (
    <Container className="mt-3" >
      <ChannelHeroComponent channel={currentChannel} myProfileId={myProfileId} authUser={authUser} profile={profile} />
      <ChannelGalleryView 
          isChannelMember={isChannelMember}
          appConfig={appConfig}
          channel={currentChannel}
          goToEvent={goToEvent} 
          messenger={messenger}
          setMessengerContext={setMessengerContext}
          myProfileId={myProfileId}
          goToProfile={goToProfile}
          goToGroupDetails={goToGroupDetails}
          goToEventDetails={goToEventDetails}
        />
    </Container>
  )
}

export default ChannelDirector
