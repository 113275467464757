import { css } from 'styled-components'
import { screenSizes } from './constants'

export const mediaQueries = key => {
    return style => `@media (min-width: ${screenSizes[key]}px) { ${style} }`
}

export const defaultFontFace = css`
    font-family: 'Montserrat';
`;

export const semiBoldFontWeight = css`
    font-weight: 600;
`;

export const boldFontWeight = css`
    font-weight: 700;
`;

export const semiBoldDefaultFont = css`
    font-size: 1.1rem;
    ${semiBoldFontWeight}
    ${defaultFontFace}
`;

export const boldDefaultFont = css`
    font-size: 1.1rem;
    ${boldFontWeight}
    ${defaultFontFace}
`;

export const boldDefaultSmallFont = css`
    font-size: 0.9rem;
    ${boldFontWeight}
    ${defaultFontFace}
`;

export const semiBoldDefaultSmallFont = css`
    font-size: 0.9rem;
    ${boldFontWeight}
    ${defaultFontFace}
`;
