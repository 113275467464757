import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { generateUrlPath } from '../../utils/routing'



const SettingsModal = ({ isOpen, toggle, appConfig }) => {

    const ServiceLink = ({ page, verbiage }) => {
        const { appId } = appConfig
        const DIR = {[page]:page}
        const termsUrl = generateUrlPath(appId, page, {}, {}, DIR)
        return (
            <a href={termsUrl} target="_blank" rel="noopener noreferrer" ><u>{ verbiage }</u></a>
        )
    }
    
    const TermsAndConditionsLink = () => 
        <ServiceLink page={'TERMS'} verbiage={'terms of service'} />
    
    const PrivacyPolicyLink = () => 
        <ServiceLink page={'PRIVACY'} verbiage={'privacy policy'} />


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Settings</ModalHeader>
            <ModalBody>
                Read our updated <PrivacyPolicyLink /> and <TermsAndConditionsLink />
            </ModalBody>
        </Modal>
    )
}

export default SettingsModal
