import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { ATTRIBUTE_EDITS_MUTATION } from './../mutations'
import { GET_SESSION_REQUEST } from '../../../utils/graphql'
import BaseFormComponent from '../../../shared/base-form'

const EditProfileAttributeModal = ({
  isOpen, toggle, profileId, appId, authUser, type, attributeData
}) => {

const [editProfile] = useMutation(ATTRIBUTE_EDITS_MUTATION)

const handleSubmitProfileAttributeEdits = (props) => {
    const { title, position, startDate, endDate, description } = props
    let attributeId = undefined
    if (attributeData && attributeData.attributeId) {
        attributeId = attributeData.attributeId
    }

    const attribute = {
        type, 
        title, 
        position, 
        startDate, 
        endDate, 
        description,
        entityId: profileId,
        entityType: 'PROFILE',
        attributeId,
        // primaryImageUrl
    }

    const variables = generateVariables(attribute)
    editProfile(variables)
    toggle()
}


const generateVariables = (attribute) => ({
    variables: {
        request: {
            ...attribute
        }
    },
    refetchQueries: [{
      ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
      fetchPolicy: 'network-only',
    }],
  })

const getDefaultValue = (attr, name) => {
    return attr && attr[name]
        ? attr[name]
        : ''
}

const editProfileAttributeForm = {
    isInline: false,
    showErrors: true,
    fields: {
        title: {
            dataType: 'STRING',
            type: 'text',
            name: 'title',
            label: 'Title',
            showLabel: true,
            defaultValue: getDefaultValue(attributeData, 'title'),
            placeholder: '',
            disabled: getDefaultValue(attributeData, 'title') !== '',
            isRequired: true,
            minLength: 1,
            maxLength: 250,
            xs: 12,
        },
        position: {
            dataType: 'STRING',
            type: 'text',
            name: 'position',
            label: 'Position',
            showLabel: true,
            defaultValue: getDefaultValue(attributeData, 'position'),
            placeholder: '',
            isRequired: true,
            minLength: 1,
            maxLength: 250,
            xs: 12,
        },
        startDate: {
            dataType: 'STRING',
            type: 'datetime',
            name: 'startDate',
            label: 'Start Date',
            showLabel: true,
            defaultValue: new Date(getDefaultValue(attributeData, 'startDate')),
            isRequired: false,
            xs: 12,
        },
        endDate: {
            dataType: 'STRING',
            type: 'datetime',
            name: 'endDate',
            label: 'End Date',
            showLabel: true,
            defaultValue: new Date(getDefaultValue(attributeData, 'endDate')),
            isRequired: false,
            xs: 12,
        },
        description: {
            dataType: 'STRING',
            type: 'text',
            name: 'description',
            label: 'Description',
            showLabel: true,
            defaultValue: getDefaultValue(attributeData, 'description'),
            placeholder: '',
            isRequired: true,
            minLength: 1,
            maxLength: 250,
            xs: 12,
        },

    //   primaryImageUrl: {
    //     dataType: 'IMAGE',
    //     type: 'image',
    //     name: 'primaryImageUrl',
    //     label: 'Image',
    //     showLabel: true,
    //     defaultValue: "default.png",
    //     placeholder: profile.interest,
    //     isRequired: true,
    //     minLength: 5,
    //     maxLength: 250,
    //     xs: 12,
    //   },
    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Attribute</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <BaseFormComponent form={editProfileAttributeForm} submit={handleSubmitProfileAttributeEdits} />
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default EditProfileAttributeModal
