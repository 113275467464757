import React from 'react'

const Tesseract = ({ addPadding }) => (
  <div className={`App-logo block ${ addPadding ? 'mt-3 mb-5' : '' }`}>
    <div className="shape">
      <div className="cube outer">
        <div className="side left" />
        <div className="side right" />
        <div className="side top" />
        <div className="side bottom" />
        <div className="side front" />
        <div className="side back" />
        <div className="cube">
          <div className="side left" />
          <div className="side right" />
          <div className="side top" />
          <div className="side bottom" />
          <div className="side front" />
          <div className="side back" />
        </div>
      </div>
    </div>
  </div>
)

export default Tesseract
