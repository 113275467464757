import React, { useState } from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col, Button, Label,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { REMOVE_MEMBER_FROM_GROUP_MUTATION } from './mutations'
import { GET_SESSION_REQUEST } from '../../utils/graphql'

const RemoveGroupUserComponent = ({ removeUser }) => {
  const [currentView, setCurrentView] = useState(0)

  const handleRemoveUser = () => {
    removeUser()
  }

  const resolveView = () => {
    switch (currentView) {
      case 0:
        return (<Button className="ml-4" onClick={() => setCurrentView(1)}>Remove User</Button>)
      case 1:
        return (
          <>
            <Label>
              Are you sure you want to remove this user from the group?
            </Label>
            <Row>
              <Col xs={10} className="text-left">
                <Row>
                  <Col xs={12}>
                    <Button onClick={handleRemoveUser}>Yes</Button>
                    <Button className="ml-3" onClick={() => setCurrentView(0)}>No</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )
      default:
        return (<>not sure how you got here</>)
    }
  }
  return resolveView()
}

const ProfileModal = ({
  isOpen, toggle, selectedMember, groupOwnerId, userProfileId, groupId, authUser, goToProfile
}) => {
  const [removeMember] = useMutation(REMOVE_MEMBER_FROM_GROUP_MUTATION)

  const generateVariables = (groupId, profileId) => ({
    variables: {
      groupId,
      profileId,
    },
    refetchQueries: [{
      ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
      fetchPolicy: 'network-only',
    }],
  })

  const userIsOwnerAndOwnerNotSelected = () => userProfileId === groupOwnerId && selectedMember.profileId !== groupOwnerId

  const removeUser = (event) => {
    const variables = generateVariables(groupId, selectedMember.profileId)
    removeMember(variables)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {selectedMember.displayName}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            {userIsOwnerAndOwnerNotSelected() && (<RemoveGroupUserComponent removeUser={removeUser} />)}
          </Col>
          <Col xs={12}>
            <Button onClick={goToProfile(selectedMember)} >Go to profile</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>

  )
}

export default ProfileModal
