import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faComment, faThumbsUp, faThumbsDown
} from '@fortawesome/free-solid-svg-icons'
import BaseFormComponent from '../../shared/base-form'
import LoadingComponent from '../loading-component'
import { GET_COMMENTS_QUERY } from './queries'
import { CREATE_COMMENT_MUTATION } from './mutations'
import { getAppId } from '../../utils/brand-silo'
import { RECORD_ACTIVITY } from '../orchestrator/director/event-director/mutations'
import { CommentsComponentContainer, CommentsTitleBar, CommentsTitleBarRight, CommentsTitleText, NumComments, 
    CommentsBodyContainer, ThumbnailInitial, CommentWriterContainer, CommentsContainer, ThumbnailImageContainer, 
    CommentContainer, CommentBox, CommentText, CommentCounter, UserName, UsernameTimeContainer, CommentTime, InteractionContainer,
    LikeDislikeContainer, LikeDislikeCount, ReplyText, ReplyCommentContainer, ViewThreadComments, ThreadCommentsContainer
} from './style'
import S3ImageComponent from '../s3-image-component'
import { getInitials } from '../../shared/shared-functions/get-initials'
import { myProfile } from '../../shared/cache'

const CommentComponent = (props) => {

    const { comment, threadComments, threadId, index, likeCommentClick, dislikeCommentClick, entityId, entityType, authUser, submitComment } = props

    const [showCommentReply, setShowCommentReply] = useState(false)

    const [showThreadComments, setShowThreadComments] = useState(false)

    const [viewHideText, setViewHideText] = useState('View')

    const replyThreadText = (threadComments && threadComments.length === 1) ? 'reply' : 'replies'

    const displayReply = (prev) => {
        setShowCommentReply(prev)
    }

    const expandCommentReply = () => {
        setShowCommentReply(true)
    }

    const expandThreadComments = () => {
        setShowThreadComments(prev => !prev)
        showThreadComments ? setViewHideText('View') : setViewHideText('Hide')
    }

    const appId = getAppId()

    const getLikeIconColor = (likeComment) => {
        // If comment has been liked or disliked the icon will become 
        // Speakeasy's primary red color to signify the user liking or
        // Disliking the comment.
        const activeColor = appId === 'SPEAKEASY' ? '#cf1b30' : '#fff'
        // The icons' default colors are light grey for speakeasy and
        // 
        const inactiveColor = appId === 'SPEAKEASY' ? '#a7a7a7' : 'auto'
        return likeComment ? activeColor : inactiveColor
    }

    const getDislikeIconColor = (dislikeComment) => {
        // If comment has been disliked the icon will become 
        // Speakeasy's primary red color to signify the user liking
        // the comment.
        const activeColor = appId === 'SPEAKEASY' ? '#cf1b30' : '#fff'
        // The icons' default colors are light grey for speakeasy and
        // Tesseract
        const inactiveColor = appId === 'SPEAKEASY' ? '#a7a7a7' : 'auto'
        return dislikeComment ? activeColor : inactiveColor
    }

    const commentForm = {
        isInline: true,
        showErrors: true,
        fields: {
            text: {
                dataType: 'STRING',
                type: 'text',
                isComment: true,
                isCommentThread: true,
                name: 'text',
                label: '',
                showLabel: false,
                defaultValue: '',
                placeholder: 'Reply',
                isRequired: true,
                minLength: 1,
                maxLength: 1000,
                xs: 12,
                showReply: displayReply,
            },
        },
        submit: {
            isBlock: true,
        },
    }

    const [likeComment, setLikeComment] = useState(comment.myLikeAction === '1')

    const [dislikeComment, setDislikeComment] = useState(comment.myLikeAction === '-1')

    return (
        <CommentContainer key={index} isThreadComment={comment.threadId}>
            <ThumbnailImageContainer>
                { (comment.primaryImageUrl !== 'none')
                    ? <S3ImageComponent 
                        imageName={comment.primaryImageUrl} 
                        style={{ width: '2.3rem', height: '2.3rem', borderRadius: '100%'}}
                        imageStyle={{ borderRadius: '100%' }}
                        />
                    : <ThumbnailInitial>
                        { getInitials(comment) }
                      </ThumbnailInitial>
                }
            </ThumbnailImageContainer>
            <CommentBox>
                <UsernameTimeContainer>
                    <UserName appId={appId}>
                    { comment.displayName }
                    </UserName>
                    <CommentTime>
                        { moment(comment.createdAt).fromNow() }
                    </CommentTime>
                </UsernameTimeContainer>
                <CommentText>
                    { comment.text }
                </CommentText>
                <InteractionContainer>
                    <LikeDislikeContainer>
                        <FontAwesomeIcon
                            style={{ color: getLikeIconColor(likeComment) }}
                            icon={faThumbsUp}
                            onClick={() => likeCommentClick(likeComment, setLikeComment, comment.commentId)}
                        />
                        { (comment.likeCount > 0) &&
                            <LikeDislikeCount> 
                                { comment.likeCount } 
                            </LikeDislikeCount>
                        }
                    </LikeDislikeContainer>
                    <LikeDislikeContainer>
                        <FontAwesomeIcon
                            style={{ color: getDislikeIconColor(dislikeComment) }}
                            icon={faThumbsDown}
                            onClick={() => dislikeCommentClick(dislikeComment, setDislikeComment, comment.commentId)}
                        />
                        { (comment.dislikeCount > 0) &&
                            <LikeDislikeCount> 
                                { comment.dislikeCount } 
                            </LikeDislikeCount>
                        }
                    </LikeDislikeContainer>
                    { !comment.threadId && (
                        <ReplyText onClick={expandCommentReply}> Reply </ReplyText>
                    )}
                    <ReplyCommentContainer displayReplyComment={showCommentReply}>
                        <BaseFormComponent form={commentForm} submit={submitComment(threadId)}/>
                    </ReplyCommentContainer>
                    {
                        threadComments &&
                        <ViewThreadComments onClick={expandThreadComments} displayViewThreadComments={threadComments && threadComments.length > 0}> {viewHideText} {threadComments.length} {replyThreadText}</ViewThreadComments>
                    }
                    {
                        threadComments &&
                        <ThreadCommentsContainer displayThreadComments={showThreadComments}>
                            { threadComments.map((comment, index) => (
                                <CommentComponent comment={comment} threadId={threadId} key={index} authUser={authUser} entityId={entityId} entityType={entityType} index={index} likeCommentClick={likeCommentClick} dislikeCommentClick={dislikeCommentClick} submitComment={submitComment}/>
                            ))}
                        </ThreadCommentsContainer>
                    }
                </InteractionContainer>
            </CommentBox>
        </CommentContainer>
    )
}

const CommentsComponent = (props) => {
    const { entityId, entityType, comments: rawComments, authUser } = props

    const profile = myProfile.reactive()

    const [comments, setComments] = useState(rawComments)
    const [createComment] = useMutation(CREATE_COMMENT_MUTATION)

    const [recordActivity] = useMutation(RECORD_ACTIVITY)

    const appId = getAppId()

    const hasImage = profile && profile.primaryImageUrl && profile.primaryImageUrl !== 'none'


    const commentForm = {
        isInline: true,
        showErrors: true,
        fields: {
            text: {
                dataType: 'STRING',
                type: 'text',
                isComment: true,
                isCommentThread: false,
                name: 'text',
                label: '',
                showLabel: false,
                defaultValue: '',
                placeholder: 'Write a comment...',
                isRequired: true,
                minLength: 1,
                maxLength: 1000,
                xs: 12,
            },
        },
        submit: {
            isBlock: true,
        },
    }

    const addCommentToList = (newComment) => setComments([newComment, ...comments])

    const submitComment = (threadId) => async ({ text }) => {
  
        if (!authUser.cognitoId) {
            alert("Sign up or log in to add a comment")
        } else {

            const payload = {
                text,
                entityId, 
                entityType,
            }

            if (threadId) {
                payload['threadId'] = threadId
            }

            const variables = generateVariables(payload)
            const { data } = await createComment(variables) 

            const { commentId } = data.postComment

            const newComment = {
                text,
                entityId,
                entityType,
                commentId,
                threadId,
                createdAt: moment(),
                displayName: profile.displayName,
                primaryImageUrl: profile.primaryImageUrl,
            }

            addCommentToList(newComment)  
        }
    }

    const likeCommentClick = (likeComment, setLikeComment, commentId) => {
        
        if (!authUser.cognitoId) {
            alert("Sign up or log in to like a comment")
        } else {

            setLikeComment(!likeComment)

            const variables = {
                request: {
                appId,
                entityId: commentId,
                entityType: 'COMMENT',
                activityType: '1'
                }
            }
    
            recordActivity({ variables })
        }
    }

    const dislikeCommentClick = (dislikeComment, setDislikeComment, commentId) => {
        
        if (!authUser.cognitoId) {
            alert("Sign up or log in to dislike a comment")
        } else {

            setDislikeComment(!dislikeComment)

            const variables = {
                request: {
                appId,
                entityId: commentId,
                entityType: 'COMMENT',
                activityType: '-1'
                }
            }
    
            recordActivity({ variables })
        }
    }

    const generateVariables = (payload) => ({
        variables: {
            request: {
                ...payload
            }
        },
    })

    // sort comments from newest to oldest
    const commentsCopy = [...comments]
    const sortedComments = commentsCopy.sort((a, b) => {
        return moment(b.createdAt).diff(a.createdAt);
    });

    console.log('COMMENTS: ', sortedComments)

    const baseComments = sortedComments.filter((comment, i) => {
        return !comment.threadId
    })

    const threadComments = sortedComments.filter((comment, i) => {
        return comment.threadId
    })

    let parentChildArr = []
    baseComments.forEach((baseComment) => {
        let matchingThreadComments = []
        if (threadComments.length > 0) {
            matchingThreadComments = threadComments.filter((threadComment) => {
                return baseComment.commentId === threadComment.threadId
            })
        }
        parentChildArr.push({baseComment: baseComment, threadComments: matchingThreadComments})
    })
        
    return (
        <CommentsComponentContainer>
            <CommentsTitleBar>
                <CommentsTitleText>
                    Comments
                </CommentsTitleText>
                <CommentsTitleBarRight>
                    <NumComments>
                        <CommentCounter>
                            <span> { sortedComments.length } </span> 
                            <span> Comments </span>
                        </CommentCounter>
                        <FontAwesomeIcon icon={faComment} />
                    </NumComments>
                </CommentsTitleBarRight>
            </CommentsTitleBar>
            <CommentsBodyContainer>
                <CommentWriterContainer>

                    <ThumbnailImageContainer>
                        { hasImage
                            ? <S3ImageComponent 
                                imageName={profile.primaryImageUrl} 
                                style={{ width: '2.3rem', height: '2.3rem', borderRadius: '100%'}}
                                imageStyle={{ borderRadius: '100%' }}
                                />
                            : <ThumbnailInitial>
                                { getInitials(profile) }
                              </ThumbnailInitial>
                        }
                    </ThumbnailImageContainer>

                    <BaseFormComponent form={commentForm} submit={submitComment(null)} />
                </CommentWriterContainer>
                <CommentsContainer>
                    { parentChildArr.map((comment, index) => (
                        threadComments.length > 0 ?
                            <CommentComponent key={index} threadId={comment.baseComment.commentId} comment={comment.baseComment} threadComments={comment.threadComments} authUser={authUser} entityId={entityId} entityType={entityType} index={index} likeCommentClick={likeCommentClick} dislikeCommentClick={dislikeCommentClick} submitComment={submitComment} />
                            :
                            <CommentComponent key={index} threadId={comment.baseComment.commentId} comment={comment.baseComment} authUser={authUser} entityId={entityId} entityType={entityType} index={index} likeCommentClick={likeCommentClick} dislikeCommentClick={dislikeCommentClick} submitComment={submitComment} />
                    ))}
                </CommentsContainer>
            </CommentsBodyContainer>
        </CommentsComponentContainer>
    )
}

const CommentsComponentWithQuery = (props) => {
    const { entityId } = props
    
    const variables = {
        request: {
            entityId,
        },
    }
    
    const { data, loading } = useQuery(GET_COMMENTS_QUERY, { variables })
    
    if (loading) {
        return <LoadingComponent isInline />
    } else {
        const { results } = data.getComments
        return <CommentsComponent {...props} comments={results} />
    }
}

export default CommentsComponentWithQuery