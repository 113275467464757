import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { CHANNEL_EDITS_MUTATION } from './mutations'
import { GET_SESSION_REQUEST } from '../../utils/graphql'
import BaseFormComponent from '../../shared/base-form'

const EditChannelModal = ({
  isOpen, toggle, channel, authUser
}) => {

const [editChannel] = useMutation(CHANNEL_EDITS_MUTATION)

const handleSubmitChannelEdits = (props) => {
    const attributes = {
        ...props,
        channelId: channel.channelId
    }
    const variables = generateVariables(attributes)
    editChannel(variables)
    toggle()
}


const generateVariables = (attributes) => ({
    variables: {
        request: {
            ...attributes
        }
    },
    refetchQueries: [{
      ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
      fetchPolicy: 'network-only',
    }],
  })


  const editChannelForm = {
    isInline: false,
    showErrors: false,
    fields: {
        displayName: {
        dataType: 'STRING',
        type: 'text',
        name: 'displayName',
        label: 'Name',
        showLabel: true,
        defaultValue: '',
        placeholder: channel.displayName,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      description: {
        dataType: 'STRING',
        type: 'text',
        name: 'description',
        label: 'Description',
        showLabel: true,
        defaultValue: '',
        placeholder: channel.description,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      slug: {
        dataType: 'STRING',
        type: 'text',
        name: 'slug',
        label: 'Slug',
        showLabel: true,
        defaultValue: '',
        placeholder: channel.slug,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },

      primaryImageUrl: {
        dataType: 'IMAGE',
        type: 'image',
        name: 'primaryImageUrl',
        label: 'Profile Picture',
        showLabel: true,
        defaultValue: channel.primaryImageUrl,
        placeholder: channel.primaryImageUrl,
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
      headerImageUrl: {
        dataType: 'IMAGE',
        type: 'image',
        name: 'headerImageUrl',
        label: 'Banner Image',
        showLabel: true,
        defaultValue: channel.headerImageUrl,
        placeholder: channel.headerImageUrl,
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Channel</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <BaseFormComponent form={editChannelForm} submit={handleSubmitChannelEdits} />
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default EditChannelModal
