import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCompass, faUser, faHome, faPaperPlane,
  faCog, faSignOutAlt, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import { PAGES } from '../../../utils/pages'
import { SideBarNavContainer, NavItemsContainer, NavItem, Logo, Text } from './style'
import { Hub } from 'aws-amplify'

const signOutAction = () => {
    Hub.dispatch(
        'auth', 
        { 
            event: 'signOut', 
    })
}

const SideBarNavComponent = ({
    showNavBar, cognitoUser, appConfig, currentView, goTo, profile, toggleMessenger, showMessenger, toggleSettingsModal
}) => {
  const hasProfile = profile && profile.profileId !== null

  const hasCognito = cognitoUser && cognitoUser.cognitoId !== null

  if (!showNavBar) {
      return (<></>)
  }
  return (

    <SideBarNavContainer>
        <NavItemsContainer>
        { appConfig.pages.includes(PAGES.LANDING)
                    && (
                        <NavItem active={currentView.path === PAGES.LANDING} onClick={goTo(PAGES.LANDING)} href="#">
                            <Logo active={currentView.path === PAGES.LANDING} >
                                <FontAwesomeIcon icon={faHome} />
                            </Logo>
                            <Text>HOME</Text>
                        </NavItem>
                    )}
        { appConfig.pages.includes(PAGES.EXPLORE)
        && (
            <NavItem active={currentView.path === PAGES.EXPLORE} onClick={goTo(PAGES.EXPLORE)} href="#">
                <Logo active={currentView.path === PAGES.EXPLORE} >
                    <FontAwesomeIcon icon={faCompass} />
                </Logo>
                <Text>EXPLORE</Text>
            </NavItem>
        )}
        { appConfig.pages.includes(PAGES.PROFILE)
            ? (hasProfile || hasCognito)
              ? (
                <NavItem active={currentView.path === PAGES.PROFILE} onClick={goTo(PAGES.PROFILE)} href="#">
                    <Logo active={currentView.path === PAGES.PROFILE} >
                        <FontAwesomeIcon icon={faUser} />
                    </Logo>
                    <Text>PROFILE</Text>
                </NavItem>
              )
              : (
                <NavItem active={currentView.path === PAGES.PROFILE} onClick={goTo(PAGES.PROFILE)} href="#">
                    <Logo active={currentView.path === PAGES.PROFILE} >
                        <FontAwesomeIcon icon={faUser} />
                    </Logo>
                    <Text>LOG IN</Text>
                </NavItem>
              )
            : <></>}
        { appConfig.pages.includes(PAGES.MESSENGER) && (hasProfile || hasCognito)
            && (
                <NavItem onClick={toggleMessenger} href="#">
                    <Logo active={currentView.path === PAGES.MESSENGER} >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </Logo>
                    <Text>MESSENGER</Text>
                </NavItem>
            )}
        { appConfig.pages.includes(PAGES.FAQ)
            && (
                <NavItem active={currentView.path === PAGES.FAQ} onClick={goTo(PAGES.FAQ)} href="#">
                    <Logo active={currentView.path === PAGES.FAQ} >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </Logo>
                    <Text>FAQ</Text>
                </NavItem>
            )}
        </NavItemsContainer>
        <NavItemsContainer>
            <NavItem onClick={toggleSettingsModal} href="#">
                <Logo>
                    <FontAwesomeIcon icon={faCog} />
                </Logo>
                <Text>SETTINGS</Text>
            </NavItem>
            { hasCognito && 
                <NavItem onClick={signOutAction}>
                    <Logo>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </Logo>
                    <Text>LOG OUT</Text>
                </NavItem> }
        </NavItemsContainer>
    </SideBarNavContainer>

  )
}

export default SideBarNavComponent
