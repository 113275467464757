import React from 'react'
import { LandingItemContainer } from '../../shared/landing/styles'


const FooterComponent = ({ appConfig, authUser, page, setNotification, index }) => {

  const year = new Date().getFullYear()
  const footerCopytright = `© ${appConfig.name} ${year}`

  const showFooter = page === 'LANDING'

  const isDark = index % 2 === 0

  return (
    <LandingItemContainer style={{ height: 'auto', width: '100%' }} isDark={isDark} >
      { showFooter &&
        <div style={{ width: '100%' }} className="py-3" >
          <p className="text-center">
            { footerCopytright }
          </p>
        </div>
      }
    </LandingItemContainer>
  )
}

export default FooterComponent
