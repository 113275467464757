import React from 'react'
import Helmet from 'react-helmet'
import AppContext from './context'
import AppDirector from './director'
import MessengerComponent from '../messenger'
import { mySession } from '../../shared/cache'

const AppOrchestrator = () => {
  
  const { appData } = mySession.reactive()
  

  return (
    <AppContext render={(authUser, notification, setNotification, partyDetails, addPeers, profile, messengerRef, setMessenger, setProfile, linkProfileRef, showSystemUserModal, toggleSystemUserModal, refetchSession, appEvents, isSessionLoaded, clearPartyDetails, shouldToggleSystemUserModal, updateEventDetails, pollDetailsRef, setPollDetails) => (
      <>
        <Helmet defaultTitle={appData.name}>
          <link id="favicon" rel="icon" href={`./${appData.favicon}`} type="image/x-icon"/>
        </Helmet>

        <MessengerComponent />

        <AppDirector
          appData={appData}
          authUser={authUser}
          partyDetails={partyDetails}
          addPeers={addPeers}
          profile={profile}
          setProfile={setProfile}
          linkProfileRef={linkProfileRef}
          notification={notification}
          setNotification={setNotification}
          appEvents={appEvents}
          isSessionLoaded={isSessionLoaded}
          clearPartyDetails={clearPartyDetails}
          updateEventDetails={updateEventDetails}
          pollDetails={pollDetailsRef}
          setPollDetails={setPollDetails}
        />



      </>
    )}
    />
  )
}

export default AppOrchestrator
