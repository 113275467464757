import React, { useState, useEffect } from 'react'
import { Button, Container } from 'reactstrap'
import EventHeroComponent from '../../components/hero/event-hero-component'
import { SEARCH_EVENTS_QUERY } from '../../components/orchestrator/director/explore-director/queries'
import { mapAppEvents } from '../../utils/mapper'
import CommentsComponent from '../../components/comments-component'
import ActiveTemplateComponent from '../../components/active-template-component'
import TemplateEditorComponent from '../../components/template-editor-component'
import ScorecardComponent from '../../components/scorecard-component'
import { eventList, myProfile } from '../../shared/cache'
import CachedFieldComponent from '../../shared/cache/helpers/CachedFieldComponent'
import { getAppId } from '../../utils/brand-silo'
import { useQuery } from '@apollo/client'
import { GET_CHANNEL_MEMBERSHIP_QUERY } from './queries'
import { SEARCH_CHANNELS_QUERY } from '../../components/orchestrator/director/channel-director/queries'
import SubscribeToChannelModal from '../../components/subscribe-channel-modal'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const publishableKey = process.env.REACT_APP_STRIPE_CHECKOUT_ID

const stripePromise = loadStripe(publishableKey);

const EventDetailsPage = ({ event, goToEvent, authUser, membership, channelProducts, displayName, profile }) => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [showSubscribeModal, setShowSubscribeModal] = useState(false)

  const myProfileId = profile && profile.profileId ? profile.profileId : undefined

  const [showTemplateEditor, setShowTemplateEditor] = useState(false)

  const toggleTemplateEditor = () => setShowTemplateEditor(!showTemplateEditor)

  const isEventOwner = ( profile && profile.profileId && event.profileId === profile.profileId )

  const goToApp = () => {
    if (!isSubscribed) {
      setShowSubscribeModal(true)
    } else if (event.type === 'PROFILE_EVENT') {
      goToEvent()
    } else if (event.type === 'REMOTE_URL') {
      const isProd = process.env.REACT_APP_STAGE === 'production'
      if (isProd) {
        window.location.replace(event.remoteUrl)
        window.open(event.remoteUrl, '_blank')
      } else {
        const launchAppUrl = `${window.location.origin}/${event.path}`
        window.open(launchAppUrl, '_blank')
      }
    } else {
      goToEvent()
    }
  }

  let allowAccess = event.allowEntry
  let buttonText = 'Join Event'

  const { channelId } = event

  if (event.type === 'REMOTE_URL') {
    allowAccess = true
    buttonText = 'Launch App'
  } else if (event.isLive) {
    buttonText = 'Join Live'
  } else if (!event.allowEntry) {
    buttonText = event.fromNow
  }

  const getValidTiersForRequiredTiers = (requiredTier) => {
    switch(requiredTier) {
      case 'FREE': return ['FREE', 'BASIC', 'PLUS', 'PREMIUM']
      case 'BASIC': return ['BASIC', 'PLUS', 'PREMIUM']
      case 'PLUS': return ['PLUS', 'PREMIUM']
      case 'PREMIUM': return ['PREMIUM']
      default: return []
    }
  }

  const requiredTier = event.tier ? event.tier.name.toUpperCase() : 'FREE'
  const validTiers = getValidTiersForRequiredTiers(requiredTier)
 
  const validChannelProducts = channelProducts && channelProducts.length ? channelProducts.filter(p => validTiers.includes(p.name.toUpperCase())) : []

  const tierOfMembership = membership && membership.product ? membership.product.name.toUpperCase() : 'FREE'

  if(!validTiers.includes(tierOfMembership)) {
    buttonText = 'Subscribe to Channel'
  } 

  useEffect(() => {
    if(!validTiers.includes(tierOfMembership)) {
      setIsSubscribed(false)
    } else {
      setIsSubscribed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showDevFeatures = false
  const toggle = () => setShowSubscribeModal(!showSubscribeModal)
  
  return (
    <>
      <Elements stripe={stripePromise}>
        <SubscribeToChannelModal
          isOpen={showSubscribeModal}
          toggle={toggle}
          channelId={channelId} 
          products={validChannelProducts}
          displayName={displayName}
          isSubscribed={isSubscribed} 
          setIsSubscribed={setIsSubscribed} 
          showSubscribeModal={showSubscribeModal} 
          setShowSubscribeModal={setShowSubscribeModal} 
          profileId={myProfileId}
          authUser={authUser}
        />
      </Elements>
      <Container className="mb-5" >
        <EventHeroComponent
          event={event}
          isEventOwner={isEventOwner}
          myProfileId={myProfileId}
          authUser={authUser}
          render={() => <Button block color="secondary" className="mt-3" onClick={goToApp} disabled={ !allowAccess && isSubscribed }>{ buttonText }</Button>}
        />

        { showDevFeatures && (<ScorecardComponent templateId={event.templateId} />) }

        {(showDevFeatures && isEventOwner) && (
          <>
            { ((event.templateId || showTemplateEditor) && !event.isLive) && (
              <TemplateEditorComponent templateId={event.templateId} entityType={"EVENT"} entityId={event.eventId} hostType={"PROFILE"} hostId={myProfileId} />
            )}
            
            { (event.templateId && event.isLive) && (
              <ActiveTemplateComponent templateId={event.templateId} />
            )}

            { (!event.templateId || showTemplateEditor) 
              ? (
                  <Button block className="my-3" onClick={toggleTemplateEditor} >Create Event Template</Button>
              )
              : (<></>)
            }
          </>

        )}

        <CommentsComponent
          entityId={event.eventId}
          entityType={'EVENT'}
          authUser={authUser}
          profile={profile}
        />
      </Container>
    </>
  )
}

const EventDetailsPageWithMembershipQuery = ({event, goToEvent, authUser, profile}) => {
  const myProfileId = profile && profile.profileId ? profile.profileId : undefined

  const {channelId} = event

  const variables = { request: { channelId, profileId: myProfileId } }
  const { data, loading } = useQuery(GET_CHANNEL_MEMBERSHIP_QUERY, { variables })

  const variables2 = {
    request: {
      channelId
    },
  }

  const { data: channelSearchResults , loading: loadingChannels } = useQuery(SEARCH_CHANNELS_QUERY, { variables: variables2 })
    
  if (loading || loadingChannels) {
    return (<></>)
  } 

  const { products: channelProducts, displayName } = channelSearchResults.searchChannels.results[0]
  const membership = data.getChannelMembership

  return (
    <EventDetailsPage
        event={event}
        goToEvent={goToEvent}
        authUser={authUser}
        membership={membership}
        channelProducts={channelProducts}
        displayName={displayName}
        profile={profile}
    />
  )
}

const EventDetailsPageWithChannelCheck = (props) => {
  
  const { rawEvent } = props

  const profile = myProfile.reactive()

  const mappedEvent = mapAppEvents([rawEvent])[0]

  const { channelId, tier } = mappedEvent

  if ((!channelId || channelId === 'none') || !tier) {
    return (
      <EventDetailsPage
        {...props}
          event={mappedEvent}
          profile={profile}
      />
    )
  } else {
    return (
      <EventDetailsPageWithMembershipQuery
        {...props}
        event={mappedEvent}
        profile={profile}
      />
    )      
  }
}

const EventDetailsPageWithData = (props) => {
  const { event: { eventId } } = props

  const variables = { request: { eventId } }

  if (getAppId() === 'TESSERACT') {
    return (<EventDetailsPage {...props} />)
  } else {
    return (
      <CachedFieldComponent
          query={SEARCH_EVENTS_QUERY}
          variables={variables}
          cache={eventList}
          find={eventId}
          render={(cacheProps) => (
              <EventDetailsPageWithChannelCheck
                  {...props}
                  rawEvent={cacheProps}
              />
          )}
      />
    )
  }
}

export default EventDetailsPageWithData
