import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { myProfile } from '../../shared/cache'
import { PROFILE_EDITS_MUTATION } from './mutations'
import BaseFormComponent from '../../shared/base-form'

const cleanProps = (props) => {
  const cleaned = {}

  const keys = Object.keys(props)

  keys.forEach(k => {
    const val = props[k]
    if (val !== '' && val !== undefined && val !== null) {
      cleaned[k] = val
    }
  })

  return cleaned
}

const EditProfileModal = ({
  isOpen, toggle, profile, appId, authUser
}) => {

const [editProfile] = useMutation(PROFILE_EDITS_MUTATION)

const handleSubmitProfileEdits = (props) => {
    const variables = generateVariables(props)
    editProfile(variables)
    const newState = {
      ...myProfile.reactive(),
      ...cleanProps(props)
    }
    myProfile.reactive(newState)
    toggle()
}


const generateVariables = (attributes) => ({
    variables: {
        request: {
            ...attributes
        }
    },
  })


const editProfileForm = {
    isInline: false,
    showErrors: true,
    fields: {
      displayName: {
        dataType: 'STRING',
        type: 'text',
        name: 'displayName',
        label: 'Display Name',
        showLabel: true,
        defaultValue: '',
        placeholder: profile.displayName,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      interest: {
        dataType: 'STRING',
        type: 'text',
        name: 'interest',
        label: 'Interest',
        showLabel: true,
        defaultValue: '',
        placeholder: profile.interest,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      location: {
        dataType: 'STRING',
        type: 'text',
        name: 'location',
        label: 'Location',
        showLabel: true,
        defaultValue: '',
        placeholder: profile.location,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      primaryImageUrl: {
        dataType: 'IMAGE',
        type: 'image',
        name: 'primaryImageUrl',
        label: 'Profile Picture',
        showLabel: true,
        defaultValue: profile.primaryImageUrl,
        placeholder: profile.primaryImageUrl,
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
      headerImageUrl: {
        dataType: 'IMAGE',
        type: 'image',
        name: 'headerImageUrl',
        label: 'Banner Image',
        showLabel: true,
        defaultValue: profile.headerImageUrl,
        placeholder: profile.headerImageUrl,
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Profile</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <BaseFormComponent form={editProfileForm} submit={handleSubmitProfileEdits} />
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default EditProfileModal
