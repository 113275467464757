import React, { useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faComments, faEdit } from '@fortawesome/free-solid-svg-icons'
import hash from 'object-hash'
import GroupHeroComponent from '../../components/hero/group-hero-component'
import InviteMemberModal from '../../components/invite-member-modal'
import BaseGalleryComponent from '../../shared/base-gallery'
import { getAppId, getCurrentBrandConfig } from '../../utils/brand-silo'
import EditGroupModal from '../../components/edit-group-modal'
import { myProfile } from '../../shared/cache'
import LoadingComponent from '../../components/loading-component'
import { currentMessengerContext } from '../../shared/cache'

const GroupDetailsPage = ({
  group, isGroupOwner, goToEvent, setMessengerContext, authUser, profile, appConfig, goToProfile
}) => {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [isEditModalShowing, setEditModalShowing] = useState(false)

  const { pageConfigs } = getCurrentBrandConfig()

  const shouldShowPrivateEvent = pageConfigs.find((p) => p.key === 'GROUP').hasEvent

  const toggleInviteModal = () => setShowInviteModal(!showInviteModal)

  const openMessageContext = (event) => {
    const contextType = 'GROUP'
    const targetId = group.groupId
    const isOpen = true
    const { displayName } = group
    const appId = getAppId()
    const contextHash = hash(`${appId}-${targetId}`)
    currentMessengerContext.reactive({ contextType, targetId, isOpen, displayName, contextHash })
    event.stopPropagation()
  }

  const toggleEditModal = () => setEditModalShowing(!isEditModalShowing)

  const groupEvent = {
    key: 1,
    eventId: group.groupId,
    title: group.displayName,
    description: 'Group Party',
    type: 'GROUP_EVENT',
    showLikeToggle: false,
    viewerTypes: {
      allowSolo: false,
      allowParties: true,
    }
  }

  return (
    <>
      <InviteMemberModal groupId={group.groupId} isOpen={showInviteModal} toggle={toggleInviteModal} friends={profile.friends} members={group.members} authUser={authUser} />

      { isGroupOwner && (
        <EditGroupModal isOpen={isEditModalShowing} toggle={toggleEditModal} group={group} authUser={authUser} />
      )}

      <Container >
        <GroupHeroComponent
          group={group}
          primaryRender={() => (
            <Row>
              <Col xs={12} className="text-right">
                { shouldShowPrivateEvent
                    && (
                    <Button color="secondary" block className="mr-3" onClick={goToEvent(groupEvent, profile)}>
                      Join Party
                    </Button>
                    )}
              </Col>
            </Row>
          )}
          secondaryRender={() => (
            <Row>
              <Col xs={12} >
                <Button color="secondary" className="mr-2" onClick={toggleInviteModal}>
                  <FontAwesomeIcon color="secondary" icon={faUserPlus} />
                </Button>

                <Button color="secondary" className="" onClick={(e) => openMessageContext(e)}>
                  <FontAwesomeIcon icon={faComments} color="primary" />
                </Button>

                { isGroupOwner && (
                    <Button color="secondary" className="ml-2" onClick={toggleEditModal}>
                      <FontAwesomeIcon icon={faEdit} color="primary" />
                    </Button>
                )}
              </Col>
            </Row>
          )}
        />
        <BaseGalleryComponent 
          members={group.members}
          targetType={'GROUP'}
          isMyProfile={true}
          
          appConfig={appConfig}
          friends={group.members} 
          ownerId={group.ownerId} 
          profileId={profile.profileId} 
          entityId={group.groupId} 
          authUser={authUser} 
          setMessengerContext={setMessengerContext}
          goToProfile={goToProfile}
        />

      </Container>

    </>
  )
}

const GroupDetailsPageWithData = (props) => {

  const { group } = props

  const myProfileData = useReactiveVar(myProfile.reactive)

  if (!myProfileData) {
    return (<LoadingComponent />)
  }

  const currentGroup = myProfileData.groups.find((g) => g.groupId === group.groupId)

  const isGroupOwner = (currentGroup.ownerId === myProfileData.profileId)

  return (
    <GroupDetailsPage
      {...props}
      group={currentGroup}
      profile={myProfileData}
      isGroupOwner={isGroupOwner}
    />
  )

}

export default GroupDetailsPageWithData
