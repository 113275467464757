import React, { useState, useRef } from 'react'
import AuthGuard from '../../guards/auth'
import SessionGuard from '../../guards/session'
import WebsocketGuard from '../../guards/websocket'
import AppTheme from '../theme'

const AppContext = ({ render }) => {
  const [profile, setProfile] = useState(undefined)
  const [notification, setNotification] = useState()
  const [messenger, setMessenger] = useState({ messages: [] })
  const [partyDetails, setPartyDetails] = useState({ parties: [], peers: [] })
  const [pollDetails, setPollDetails] = useState(undefined)
  const [showSystemUserModal, setShowSystemUserModal] = useState(false)
  const [isSessionLoaded] = useState(false)

  const partyDetailsRef = useRef(partyDetails)
  partyDetailsRef.current = partyDetails

  const messengerRef = useRef(messenger)
  messengerRef.current = messenger

  const profileRef = useRef(profile)
  profileRef.current = profile

  const pollDetailsRef = useRef(pollDetails)
  pollDetailsRef.current = pollDetails

  const toggleSystemUserModal = () => setShowSystemUserModal(!showSystemUserModal)

  const clearPartyDetails = () => setPartyDetails({ parties: [], peers: [], partyId: '' })

  return (
    <AppTheme render={() => (
      <AuthGuard render={(authProps) => {
        const { cognitoId, email = '' } = authProps
        return (
          <SessionGuard
            cognitoId={cognitoId}
            email={email}
            render={(sessionProps) => {
              
              const {
                shouldToggleSystemUserModal, profile, events, refetchSession, 
              } = sessionProps

              setProfile(profile)
              return (
                <WebsocketGuard
                  profile={profile}
                  partyDetailsRef={partyDetailsRef}
                  pollDetailsRef={pollDetailsRef}
                  setPollDetails={setPollDetails}
                  setPartyDetails={setPartyDetails}
                  setMessenger={setMessenger}
                  setNotification={setNotification}
                  render={(websocketProps) => {
                    const { linkProfileRef, addPeers, updateEventDetails } = websocketProps
                    return render(authProps, notification, setNotification, partyDetails, addPeers, profile, messengerRef, setMessenger, setProfile, linkProfileRef, showSystemUserModal, toggleSystemUserModal, refetchSession, events, isSessionLoaded, clearPartyDetails, shouldToggleSystemUserModal, updateEventDetails, pollDetails, setPollDetails)
                  }}
                />
              )
            }}
          />
        )
      }}
      />
    )}
    />
  )
}

export default AppContext
