import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Card, CardText, CardBody, CardTitle } from 'reactstrap'
import BaseFormComponent from '../../shared/base-form'
import { TEMPLATE_SUBMISSION_MUTATION } from './mutations'  
import { GET_SUBMISSION_QUERY } from './queries'
import LoadingComponent from '../loading-component'


const mapPollForm = (pollDetails, submission) => {
    const { label, inputType, inputOptions, answerType } = pollDetails

    const options = inputOptions.map(opt => (
        {
            key: opt.key,
            value: opt.key,
            label: opt.label
        })
    )

    let defaultAnswer = undefined
    if (submission && submission.answer) {
        if (inputType === 'RADIO') {
            defaultAnswer = submission.answer.optionKey
        } else {
            defaultAnswer = submission.answer[answerType]
        }
    }

    const form = {
        isInline: false,
        showErrors: true,
        fields: {
            answer: {
                dataType: answerType,
                type: inputType.toLowerCase(),
                name: 'answer',
                label,
                options,
                showLabel: true,
                defaultValue: defaultAnswer,
                placeholder: '',
                isRequired: true,
                minLength: 1,
                maxLength: 200,
                xs: 12,
            }
        },
        submit: {
          isBlock: true,
          canReSubmit: true,
        },
    }

    return form
}

const PollComponent = ({ pollDetails, submission }) => {

    const {
        templateId,
        templateItemId,
        principalId,
        answerType,
        inputType
    } = pollDetails

    const [putTemplateSubmission] = useMutation(TEMPLATE_SUBMISSION_MUTATION)

    const [pollForm, setPollForm] = useState(undefined)

    const submitPoll = ({ answer }) => {
        const payload = {
            variables: {
                request: {
                    templateId,
                    templateItemId,
                    principalId,
                    answer: `${answer}`,
                    answerType,
                    inputType
                },
            },
            refetchQueries: [{
                query: GET_SUBMISSION_QUERY,
                variables: {
                    request: {
                        templateItemId,
                    },
                },
                fetchPolicy: 'network-only',
            }]
        }
        putTemplateSubmission(payload)   
        setPollForm(undefined) 
    }

    useEffect(() => {
        const mappedForm = mapPollForm(pollDetails, submission)
        setPollForm(mappedForm)
    }, [pollDetails, submission])


    return (
        <div className="p-3" >
            <Card className="text-left" >
                <CardBody>
                    <CardTitle tag="h5">Event Poll</CardTitle>
                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
                    <CardText>
                        { pollForm 
                            ? <BaseFormComponent form={pollForm} submit={submitPoll} />
                            : <LoadingComponent isInline />
                        }
                    </CardText>
                </CardBody>
            </Card>
        </div>
      )
}


const PollComponentWithQuery = (props) => {

    const { pollDetails } = props

    const { submissionId, templateItemId } = pollDetails

    const variables = {
        request: {
            submissionId,
            templateItemId
        },
    }
    
    const { data, loading } = useQuery(GET_SUBMISSION_QUERY, { variables })
    
    if (loading) {
        return <LoadingComponent isInline />
    } else {
        const { metadata, results } = data.getSubmission
        const submission = metadata.count > 0 ? results[0] : {}
        return <PollComponent {...props} submission={submission} />
    }

}

export default PollComponentWithQuery
