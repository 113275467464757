import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { EventTitleBarContainer } from '../styles'
import { Button, Col, Row } from 'reactstrap'
import EventChatComponent from '../event-chat'


const ActiveEventHeaderComponent = ({ event, getNextParty, isParty, currentViewConfig, isSolo }) => {

    const forceChatOpen = isSolo || (!event.hosts || !event.hosts.length)

    const [isNextDisabled, setNextDisabled] = useState(false)

    const getNextPartyWithState = () => {
        setNextDisabled(true)
        getNextParty()
        setTimeout(() => setNextDisabled(false), 10000)
    }

    return(
        <Row>
            <Col xs={currentViewConfig.media.xs} sm={currentViewConfig.media.sm} >
                <EventTitleBarContainer>
{/* 
                    <EventPeerCount>
                        <FontAwesomeIcon icon={faUserFriends} /> 0

                    </EventPeerCount> */}

                    <div className="w-100">
                        <strong>
                            {' '}
                            { event.title }
                            {' '}
                        </strong>
                        {' '}
                        <br />
                        { event.description }
                    </div>

                    { isParty && (
                        <div className="my-auto">
                            <Button color="primary" disabled={isNextDisabled} onClick={getNextPartyWithState} style={{ whiteSpace: 'nowrap'}} >Next Party <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </div>
                    )}

                </EventTitleBarContainer>

            </Col>
            <Col xs={currentViewConfig.party.xs} sm={currentViewConfig.party.sm} >
                
                <EventChatComponent 
                    style={{ position: 'absolute', height: '500px', width: '100%' }} 
                    messengerStyle={{ top: '88px', right: '0px', left: '0px' }}
                    className="d-none d-sm-block"
                    isChatButtonDisabled={forceChatOpen}
                    showInitial={forceChatOpen}
                    targetId={event.eventId}
                />

            </Col>

        </Row>
    )
}

export default ActiveEventHeaderComponent