import { gql } from '@apollo/client'

export const SEARCH_POSTS_QUERY = gql`
  query getPosts($request: GetPostsRequest) {
    getPosts(request: $request) {
        results {
            postId
            profileId
            text
            primaryImageUrl
            linkUrl
            createdAt
            activity {
              likes
              views
              comments {
                text
              }
            }
        }
        metadata {
            count
            skip
            limit
        }
    }
  }
`
