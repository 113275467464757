import { useMutation } from '@apollo/client'
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import EmailSubmissionComponent from '../../../components/email-submission-component'
import BaseFormComponent from '../../base-form'
import { LandingItemContainer } from '../styles'
import { SUBMIT_EMAIL_MUTATION } from '../../../components/email-submission-component/mutations'
import { getAppId } from '../../../utils/brand-silo'


const generateForm = (details) => {
    const form = {
        isInline: details.isInline,
        showErrors: true,
        fields: {},
        submit: {
          isBlock: true,
        },
    }

    if (details.email) {
        form.fields.email = {
            dataType: 'STRING',
            type: 'text',
            name: 'email',
            label: 'Email',
            showLabel: false,
            defaultValue: '',
            placeholder: 'Email',
            isRequired: true,
            minLength: 1,
            maxLength: 250,
            xs: 12,
        }
      }

    if (details.name) {
        form.fields.name =  {
          dataType: 'STRING',
          type: 'text',
          name: 'name',
          label: 'Name',
          showLabel: false,
          defaultValue: '',
          placeholder: 'Your Name',
          isRequired: false,
          minLength: 0,
          maxLength: 1000,
          xs: 12,
        }
    }


    if (details.company) {
        form.fields.company =  {
          dataType: 'STRING',
          type: 'text',
          name: 'company',
          label: 'Company',
          showLabel: false,
          defaultValue: '',
          placeholder: 'Company',
          isRequired: false,
          minLength: 0,
          maxLength: 1000,
          xs: 12,
        }
    }

    if (details.body) {
        form.fields.body =  {
          dataType: 'STRING',
          type: 'text',
          name: 'body',
          label: 'Body',
          showLabel: false,
          defaultValue: '',
          placeholder: 'Body',
          isRequired: false,
          minLength: 0,
          maxLength: 1000,
          xs: 12,
        }
    }

    return form
}

const generateVariables = (payload) => {
    return {
        variables: {
            request: {
                ...payload,
                appId: getAppId()
            }
        },
    }
}

const ContactComponent = ({ submitEmail, details, index, setNotification }) => {

    const isDark = index % 2 === 0

    const form = generateForm(details)

    const submit = async (fields) => {
        const variables = generateVariables(fields)
        submitEmail(variables)
    }

    const isEmailOnly = details.email && !details.company && !details.body

    return (
        <LandingItemContainer id={details.id} className="py-5" isDark={isDark}  >
            <Container>
                <div className="my-auto" >
                    <h2 className="text-center w-100 my-3" >Contact Us</h2>
                    <Row className="w-100" >
                        <Col xs={12} md={{ size: 6, offset: 3 }} >
                            { isEmailOnly
                                ? <EmailSubmissionComponent setNotification={setNotification} />
                                : <BaseFormComponent form={form} submit={submit} setNotification={setNotification} />
                            }
                        </Col>
                    </Row>
                </div>
            </Container>
        </LandingItemContainer>
    )
}

const ContactComponentWithMutation = (props) => {

    const [submitEmail] = useMutation(SUBMIT_EMAIL_MUTATION)

    return (
        <ContactComponent {...props} submitEmail={submitEmail} />
    )

}

export default ContactComponentWithMutation

