import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useQuery } from '@apollo/client'
import PostComponent from './post-component'
import CreatePostModal from './create-post-modal'
import { SEARCH_POSTS_QUERY } from './queries'
import LoadingComponent from '../loading-component'

const PostListComponent = (props) => {

    const { authUser, profile, posts } = props
    const { profileId } = profile

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    const sortedPosts = posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    return (
        <>
        <CreatePostModal toggle={toggle} isOpen={isOpen} profileId={profileId} authUser={authUser} />
        <Row>
            <Col xs={12} className="mt-3" >
                <Button onClick={toggle}>Create Post</Button>
            </Col>
        </Row>
        <Row>
            { sortedPosts.length === 0
                ? ( <Col xs={12} className="text-center" >
                        <i>No posts yet</i>
                    </Col>
                )
                : sortedPosts.map( (post, index) => 
                    <Col xs={12} md={6} >
                        <PostComponent profile={profile} post={post} index={index} /> 
                    </Col>
                ) 
            }
        </Row>
        </>
    )
}


const PostListComponentWithQuery = (props) => {
    const { profile } = props
    const { profileId } = profile

    const variables = {
        request: {
            entityId: profileId
        }
    }
    const { data, loading } = useQuery(SEARCH_POSTS_QUERY, { variables })

    if (loading) {
        return (
            <div className="my-3">
                <LoadingComponent isInline />
            </div>
        )
    } else {
        const { results } = data.getPosts
        return <PostListComponent {...props} posts={results} />
    }

}

export default PostListComponentWithQuery
