import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import S3ImageComponent from '../../../components/s3-image-component'
import { LandingItemContainer, ColumnItemContainer } from '../styles'
import { resolveCta } from '../utils'


const resolveCols = (items) => {
    const colCount = items.length

    const cols = {
        xs: 12,
        md: 4,
        lg: 4
    }

    if (colCount === 1) {
        cols.xs = 12
        cols.md = 12
        cols.lg = 12
    } else if (colCount === 2) {
        cols.xs = 12
        cols.md = 6
        cols.lg = 6
    } else if (colCount === 3) {
        cols.xs = 12
        cols.md = 4
        cols.lg = 4
    }

    return cols
}

const ColumnComponent = ({  details, index, goTo }) => {
    const isDark = index % 2 === 0
    const cols = resolveCols(details.items)

    return (
        <LandingItemContainer id={details.id} className="py-5" isDark={isDark}  >
            <Container>
                <div className="my-auto" >
                    { details.tag && (
                        <p >
                            &mdash; <span style={{ fontWeight: 800, fontSize: '12px' }} >{ details.tag }</span>
                        </p>
                    )}

                    <h2  >{ details.title }</h2>
                    <Row>
                        { details.items.map(item => (
                            <Col xs={cols.xs} md={cols.md} lg={cols.lg} className={`p-3 ${item.className}`} >
                                <ColumnItemContainer showBorder={item.showBorder} >

                                    { item.image && (
                                        <div className="mx-5">
                                            <S3ImageComponent 
                                                imageName={item.image} 
                                                imageStyle={item.imageStyle ? item.imageStyle : {} }
                                                style={{ height: 'auto', width: '100%' }} 
                                            />
                                        </div>
                                    )}
                                    <h3>{ item.header }</h3>
                                    <p>{ item.description }</p>
                                    { item.cta && resolveCta(item.cta) }
                                </ColumnItemContainer>
                            </Col>
                        ))}
                        <Col xs={12} className="text-center my-3">
                            { details.cta && resolveCta(details.cta, undefined, goTo) }
                        </Col>
                    </Row>
                </div>
            </Container>
        </LandingItemContainer>
    )

}


export default ColumnComponent


