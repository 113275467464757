import React from 'react'

const Scrollable = ({ render }) => {

  return (
    <div style={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
      <div style={{ height: '100%', marginBottom: '-4%' }} >
        { render && render() }
      </div>
    </div>
  )
}

export default Scrollable
