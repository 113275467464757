import React, { useState, useEffect } from 'react'
import {
  Toast, ToastBody, ToastHeader,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer } from './style'

const resolveIcon = (type) => {
  switch (type) {
    case 'NEW_MESSAGE':
      return <FontAwesomeIcon icon={faEnvelope} />
    default:
      return <FontAwesomeIcon icon={faEnvelope} />
  }
}

const resolveHeaderText = (type) => {
  switch (type) {
    case 'NEW_MESSAGE':
      return 'New Message'
    case 'FORM_SUBMISSION':
      return 'Submission Successful'
    default:
      return 'Notification'
  }
}

const resolveBodyText = (notification) => {
  switch (notification.type) {
    case 'NEW_MESSAGE':
      switch (notification.context) {
        case 'PEER':
          return `${notification.senderName}: ${notification.text}`
        case 'PARTY':
          return 'Party Chat'
        default:
          return notification.text
      }
    case 'FORM_SUBMISSION':
      return notification.text
    default:
      return 'Notification'
  }
}

const NotificationComponent = ({ notification }) => {
  const [toastDisplay, setToastDisplay] = useState(false)
  useEffect(() => {
    setToastDisplay(true)
    setTimeout(() => {
      setToastDisplay(false)
    }, 5000)
  }, [notification])

  if (!notification) {
    return <></>
  }

  const style = {
    margin: '1rem auto 0',
  }

  if (window.innerWidth > 576) {
    style.left = '45%'
  }

  const icon = resolveIcon(notification.type)

  return (
    <ToastContainer toastDisplay={toastDisplay} animationDuration="5s">
      <Toast style={style} onClick={notification.action}>
        <ToastHeader icon={icon}>
          { resolveHeaderText(notification.type) }
        </ToastHeader>
        <ToastBody>
          { resolveBodyText(notification) }
        </ToastBody>
      </Toast>
    </ToastContainer>
  )
}

export default NotificationComponent
