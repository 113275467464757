import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { isDevInRuntime } from '../../utils/routing'


const S3ImageComponent = (props) => {

    const { 
        imageName, 
        style = { height: '100px', width: 'auto' },
        imageStyle,
        alt = 'picture-to-upload',
        centerMargin = false 
    } = props

    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {

        if (imageName && imageName.includes('https://')) {
            setImageUrl(imageName)
        } else {
            const defaultImage = isDevInRuntime()
                ? '1607701795320-tesseract-transparent.png'
                : '1607712163346-tesseract-transparent.png'

            const imageToRender = (imageName && imageName !== 'none') ? imageName : defaultImage
            Storage.get(imageToRender).then(setImageUrl)
        }

    }, [imageName])

    const imageOverrides = {
        ...imageStyle,
        maxWidth: '100%', 
        maxHeight: '100%'
    }

    if (imageName !== 'none') {
        return (
            <div style={style} className={`text-center ${centerMargin && 'mx-auto'}`} >
                <img src={imageUrl} style={imageOverrides} alt={alt} />
            </div>
        )    
    } else {
        return (
            <div style={style} className="text-center" >
                <img src={imageUrl} style={imageOverrides} alt={alt} />
            </div>
        )
}
}

export default S3ImageComponent
