import * as Yup from 'yup'

const addMinAttribute = (cxt, min, message = 'Too Short!') => cxt.min(min, message)
const addMaxAttribute = (cxt, max, message = 'Too Long!') => cxt.max(max, message)
const addEmailAttribute = (cxt) => cxt.email()
const addRequiredAttribute = (cxt) => cxt.required()

const validateStringDataType = (field, fieldValidation) => {
  if (field.type === 'email') fieldValidation = addEmailAttribute(fieldValidation)
  if (field.minLength) fieldValidation = addMinAttribute(fieldValidation, field.minLength)
  if (field.maxLength) fieldValidation = addMaxAttribute(fieldValidation, field.maxLength)
  if (field.isRequired) fieldValidation = addRequiredAttribute(fieldValidation)
  return fieldValidation
}

const validateNumberDataType = (field, fieldValidation) => {
  if (field.min) fieldValidation = addMinAttribute(fieldValidation, field.min)
  if (field.max) fieldValidation = addMaxAttribute(fieldValidation, field.max)
  if (field.isRequired) fieldValidation = addRequiredAttribute(fieldValidation)
  return fieldValidation
}

export const generateValidationSchema = (fields) => {
  const keys = Object.keys(fields)
  const shape = {}

  keys.map((key) => {
    const field = fields[key]
    let fieldValidation

    switch (field.dataType) {
      case 'STRING':
        fieldValidation = Yup.string()
        fieldValidation = validateStringDataType(field, fieldValidation)
        break
      case 'NUMBER':
        fieldValidation = Yup.number()
        fieldValidation = validateNumberDataType(field, fieldValidation)
        break
      case 'BOOLEAN':
        fieldValidation = Yup.boolean()
        if (field.isRequired) {
          fieldValidation = addRequiredAttribute(fieldValidation)
        }
        break
      default:
        break
    }
    shape[key] = fieldValidation
    return key
  })

  return Yup.object().shape(shape)
}
