import styled from 'styled-components'




export const HeroBackground = styled.div`

    background: ${ ({ theme }) =>
        `transparent linear-gradient(180deg, transparent 30%, ${theme.components.page.backgroundTransparency} 100%) 0% 0% no-repeat padding-box;`
    }

    color: ${ ({ theme }) => theme.components.page.color};
`



export const HeroHeaderText = styled.div`
    font-weight: 900;
    font-size: 35px;
`
