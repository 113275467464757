import { gql } from '@apollo/client'

export const GET_TEMPLATE_QUERY = gql`
  query getTemplate($request: GetTemplateRequest) {
    getTemplate(request: $request) {
      results {
        templateId
        entityType
        entityId
        hostType
        hostId
        principal
        scoringPrincipal
        title
        description
        templateItems {
            key
            templateId
            templateItemId
            type
            label
            inputType
            inputOptions {
              key
              label
              value {
                STRING
                DECIMAL
                BOOLEAN
              }
            }
            answerType
            answer {
              STRING
              DECIMAL
              BOOLEAN
            }
            shouldScore
        }
        allowResubmission
        relativeScoring
        passingScore
        notifyEntity
        deliveryType
      }
      metadata {
        count
      }
    }
  }
`
