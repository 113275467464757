import React, { useEffect } from 'react'
import { mySession, myProfile } from '../../shared/cache'


const refetchSession = () => mySession.reactive(null)

const SsoComponent = ({ authUser }) => {

  const shouldRefetch = (authUser && authUser.cognitoId) && (myProfile === null || (myProfile && myProfile.profileId === null)) 

  useEffect(() => {
    if (shouldRefetch) {
      refetchSession(authUser)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <></>
  )
}

export default SsoComponent
