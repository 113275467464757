import { gql } from '@apollo/client'

export const SEARCH_CHANNELS_QUERY = gql`
    query searchChannels($request: SearchChannelsRequest) {
        searchChannels(request: $request) {
            results {
                channelId
                profileId
                appId
                displayName
                affiliateGroupId
                slug
                primaryImageUrl
                headerImageUrl
                isSubscribed
                products {
                    productId
                    priceId
                    amount
                    name
                    description
                    symbol
                }
            }
            metadata {
                count
                limit
                skip
            }
        }
    }
`
