import React from 'react'
import { DropTarget } from 'react-dnd'
import { Badge } from 'reactstrap'
import DndPartySource from '../dnd-party-source'

const style = {
  height: '12rem',
  width: '100%',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  borderRadius: '15px',
}
const DndPartyTarget = ({
  peers, name, myProfileId, maxPartySize, goToThisParty, setPeerDragged, appConfig, canDrop, isOver, connectDropTarget,
}) => {
  // console.log({ appConfig })

  const isActive = canDrop && isOver
  let backgroundColor = appConfig.theme.color.quaternary
  if (isActive) {
    backgroundColor = appConfig.theme.color.primary
  } else if (canDrop) {
    backgroundColor = appConfig.theme.color.secondary
  }

  return (
    <div ref={connectDropTarget} style={{ ...style, backgroundColor }}>
      <p className="text-left d-flex">
        <Badge onClick={goToThisParty} className="cursor-pointer">
          {' '}
          { name }
          {' '}
        </Badge>

        <span className="ml-auto ">
          { peers.length }
          /
          { maxPartySize }
        </span>
      </p>
      { peers && peers.map((peer) => <DndPartySource peer={peer} myProfileId={myProfileId} setPeerDragged={setPeerDragged} />)}
      {isActive ? <span className="color-secondary">Release to drop</span> : <span className="color-tertiary">Drag a box here</span>}
    </div>
  )
}
export default DropTarget(
  'box',
  {
    drop: ({ partyId, name }) => ({ partyId, name }),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(DndPartyTarget)
