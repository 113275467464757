import { useMutation } from '@apollo/client'
import React from 'react'
import { Row, Col } from 'reactstrap'
import BaseFormComponent from '../../../../shared/base-form'
import { CREATE_APPLICATION_MUTATION } from './mutations'


const ApplyDirector = ({ appConfig, goToLanding, setNotification }) => {

    const title = '✨ Apply to be a Founding Creator'

    const [createApplication] = useMutation(CREATE_APPLICATION_MUTATION)


    const applyForm = {
        isInline: false,
        showErrors: true,
        fields: {
            firstName: {
                dataType: 'STRING',
                type: 'text',
                name: 'firstName',
                label: 'First Name',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: true,
                minLength: 1,
                maxLength: 20,
                xs: 12,
                sm: 6
            },
            lastName: {
                dataType: 'STRING',
                type: 'text',
                name: 'lastName',
                label: 'Last Name',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: true,
                minLength: 1,
                maxLength: 20,
                xs: 12,
                sm: 6
            },
            email: {
                dataType: 'STRING',
                type: 'email',
                name: 'email',
                label: 'Email Address',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: false,
                minLength: 1,
                maxLength: 100,
                xs: 12,
            },
            instagram: {
                dataType: 'STRING',
                type: 'text',
                name: 'instagram',
                label: 'Instagram Handle',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: false,
                minLength: 1,
                maxLength: 30,
                xs: 12,
            },
            twitter: {
                dataType: 'STRING',
                type: 'text',
                name: 'twitter',
                label: 'Twitter Handle',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: false,
                minLength: 1,
                maxLength: 30,
                xs: 12,
            },
            tikTok: {
                dataType: 'STRING',
                type: 'text',
                name: 'tikTok',
                label: 'TikTok Handle',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: false,
                minLength: 1,
                maxLength: 30,
                xs: 12,
            },
            description: {
                dataType: 'STRING',
                type: 'text',
                name: 'description',
                label: 'Why should you be a Founding Creator?',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: false,
                minLength: 1,
                maxLength: 1000,
                xs: 12,
            },

        },
        submit: {
          isBlock: true,
        },
    
    }

    const submitForm = async (payload) => {
        const { appId } = appConfig

        const variables = {
            request: {
                appId,
                ...payload
            }
        }

        createApplication({ variables }).then(() => {
            goToLanding()
        })
    }

    return (
        <Row className="m-3 text-left" >
            <Col xs={12} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }} >
                <h2 className="my-3">{ title }</h2>

                <BaseFormComponent
                    form={applyForm}
                    submit={submitForm}
                    setNotification={setNotification}
                />

            </Col>
        </Row>
    )
}


export default ApplyDirector

