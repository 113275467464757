import styled from 'styled-components'

export const LandingItemContainer = styled.div`
    minHeight: '65vh';
    display: 'flex';
    border-bottom: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    background-color: ${(props) => props.isDark ? 
        ({theme}) => {
            return theme.components.page.altBackgroundColor
        } : 
        ({theme}) => {
            return theme.components.page.backgroundColor
        }
    };

`;


export const ColumnItemContainer = styled.div`


    border: ${ (props) =>
        props.showBorder ? '1px solid #FFFFFF8B;' : 'none;'
    }

    border-radius: 10px;
    padding: 10px;
    height: 100%;


    background: ${ ({ showBorder, theme }) =>
        showBorder ? `transparent linear-gradient(180deg, transparent 70%, ${theme.color.denary} 100%) 0% 0% no-repeat padding-box;` : 'none;'
    }

`

