import { gql } from '@apollo/client'

export const EVENT_EDITS_MUTATION = gql`
    mutation editEvent($request: EditEventRequest) {
        editEvent(request: $request) {
            title
        }
    }
`

