import React, { useState } from 'react'
import {
  Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { INVITE_MEMBERS_MUTATION } from './mutations'
import BaseFormComponent from '../../shared/base-form'

const InviteMemberModal = ({
  groupId, isOpen, toggle, friends, members, authUser,
}) => {
  const [activeTab, setActiveTab] = useState('1')

  const [inviteMembers] = useMutation(INVITE_MEMBERS_MUTATION)

  const togglePane = (tab) => () => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const generateVariables = (members) => {
    return {
      variables: {
        request: {
          groupId,
          members,
        },
      },
    }
  }

  const emailForm = {
    isInline: false,
    showErrors: true,
    fields: {
      email: {
        dataType: 'STRING',
        type: 'email',
        name: 'email',
        label: 'Email',
        showLabel: true,
        defaultValue: '',
        placeholder: 'somebody@email.com',
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
    },
    submit: {
      isBlock: true,
    },
  }

  const mobileForm = {
    isInline: false,
    showErrors: true,
    fields: {
      phone: {
        dataType: 'STRING',
        type: 'tel',
        name: 'phone',
        label: 'Mobile Number',
        showLabel: true,
        defaultValue: '',
        placeholder: '1-555-1234',
        isRequired: true,
        minLength: 10,
        maxLength: 20,
        xs: 12,
      },

    },
    submit: {
      isBlock: true,
    },
  }

  const friendForm = {
    isInline: false,
    showErrors: true,
    fields: {},
    submit: {
      isBlock: true,
    },
  }

  const friendsNotInGroup = friends.filter((friend) => !members.some((member) => member.profileId === friend.profileId))

  friendsNotInGroup.map((friend) => {
    friendForm.fields[friend.profileId] = {
      dataType: 'BOOLEAN',
      type: 'checkbox',
      name: friend.profileId,
      label: friend.displayName,
      showLabel: true,
      isRequired: false,
      xs: 12,
    }
    return friend
  })

  const handleSubmitEmail = async ({ email }) => {
    const variables = generateVariables([{ email }])
    toggle()
    return inviteMembers(variables)
  }

  const handleSubmitPhone = async ({ phone }) => {
    const variables = generateVariables([{ phone }])
    toggle()
    return inviteMembers(variables)
  }

  const handleSubmitFriends = async (props) => {
    const profileIds = Object.keys(props)
    const payload = profileIds.map((profileId) => {
      const value = props[profileId]
      if (value === undefined) {
        return null
      }
      return ({ profileId })
    }).filter((profileId) => profileId !== null)
    const variables = generateVariables(payload)
    toggle()
    return inviteMembers(variables)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Invite Member</ModalHeader>
      <ModalBody>

        <Nav tabs>
          <NavItem>
            <NavLink
              active={activeTab === '1'}
              onClick={togglePane('1')}
            >
              Email Search
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              active={activeTab === '3'}
              onClick={togglePane('3')}
            >
              Friends
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>

          <TabPane tabId="1">
            <Row className="m-2">
              <Col sm={12}>
                <BaseFormComponent form={emailForm} submit={handleSubmitEmail} />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row className="m-2">
              <Col sm={12}>
                <BaseFormComponent form={mobileForm} submit={handleSubmitPhone} />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="3">
            <Row className="my-2">
              <Col sm={12}>
                {friendsNotInGroup.length === 0 && <i className="mt-3">All friends are already in group</i>}
                {friendsNotInGroup.length > 0 && <BaseFormComponent form={friendForm} submit={handleSubmitFriends} />}
              </Col>
            </Row>
          </TabPane>

        </TabContent>
      </ModalBody>
    </Modal>

  )
}

export default InviteMemberModal
