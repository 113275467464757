import React from 'react'
import { Container } from 'reactstrap'
import BaseGalleryComponent from '../../shared/base-gallery'

const ExplorePage = ({
  appConfig, goToEventDetails, goToChannelDetails, appEvents,
}) => {

  const isTesseract = appConfig.appId === 'TESSERACT'

  return (
    <Container className="mt-3" >

        <BaseGalleryComponent 
          appConfig={appConfig} 
          showSearch={!isTesseract} 
          goToEventDetails={goToEventDetails} 
          goToChannelDetails={goToChannelDetails}
          targetType={"EXPLORE"} 
          appEvents={appEvents}
          isTesseract={isTesseract}
        />

    </Container>
  )
}

export default ExplorePage
