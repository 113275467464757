import { gql } from '@apollo/client'

export const GROUP_EDITS_MUTATION = gql`
    mutation editGroup($request: EditGroupRequest) {
        editGroup(request: $request) {
            displayName
        }
    }
`

