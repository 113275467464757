import styled from 'styled-components'
import { mediaQueries, semiBoldFontWeight, boldFontWeight, boldDefaultFont, semiBoldDefaultFont, boldDefaultSmallFont, semiBoldDefaultSmallFont } from '../../styles/helpers'

export const MenuBarContainer = styled.div`
    display: contents;
    align-items: flex-start;
    justify-content: space-around;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    ${semiBoldDefaultFont}
    height: 75px;

    ${mediaQueries('desktop')`
        justify-content: flex-start;
    `};
`;

export const MenuBarScrollContainer = styled.div`
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    ${semiBoldDefaultFont}
`;

export const MenuBarItem = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: 0;
    padding-left: 0;
    text-transform: uppercase;
    color: ${({ active, theme }) => (active 
        ? theme.components.gallery.menuItemColorActive 
        : theme.components.gallery.menuItemColorPassive 
    )};

    &:hover {
        cursor: pointer;
    }

    padding-left: 2rem;
    padding-right: 1.3rem;

`;

export const MenuBarItemText = styled.div`
    padding: 1.7rem 0 1.1rem;
    border-bottom: 4px solid transparent;
    border-color: ${(props) => props.active ? 
        ({theme}) => {
            if (theme.color.primary) {
                return theme.color.primary; 
            }
        } : 
         'transparent' 
    };

    ${mediaQueries('desktop')`
        padding-top: 1.35rem;
    `};
`;

export const Counter = styled.div`
    width: 1.75rem;
    height: 1.25rem;
    border-radius: 12px;
    ${semiBoldDefaultSmallFont}
    background-color: ${(props) => props.active 
        ? ({theme}) => theme.components.gallery.menuItemBadgeBackgroundColorActive
        : ({theme}) => theme.components.gallery.menuItemBadgeBackgroundColorPassive
    };

    color: #fff;

    margin: 1.9rem 0 0 0.6rem;
    text-align: center;

    ${mediaQueries('desktop')`
        width: 2rem;
        height: 1.4rem;
        margin: 1.4rem 0 0 0.8rem;
        padding-top: 0.1rem;
    `};
`;

export const BaseGalleryCompContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    box-shadow: 0px 3px 6px #0000001A;
`;

export const TileContainer = styled.div`
    padding: 2rem;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
`;

export const TileSectionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    
    ${mediaQueries('tablet')`
        justify-content: space-between;
    `};

    ${mediaQueries('desktop')`
        justify-content: flex-start;
    `};
`;

export const Tile = styled.div`
    width: 97%;
    ${boldDefaultFont}
    background: ${({theme}) => {
        return theme.color.secondary;
    }};
    border: 0.75px solid ${({theme}) => theme.components.gallery.borderColor };
    margin: 0 0 5% 0;

    ${mediaQueries('tablet')`
        width: 46%;
    `};

    ${mediaQueries('desktop')`
        width: 18rem;
        margin: 0 5% 5% 0;
    `};
`;

export const TileImage = styled.div`
    height: 11rem;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${mediaQueries('desktop')`
        height: 14rem;
    `};
`;

export const StreamMoment = styled.div`
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    background-color: ${ ({theme}) => theme.components.hero.badgeBackgroundColor };
    color: ${ ({theme}) => theme.components.hero.badgeFontColor };

    padding: 0.3rem 0.5rem;
    ${boldDefaultSmallFont}
    border-radius: 6px;
`;

export const TileInfoContainer = styled.div`
    position: relative;
    padding: 1.5rem;
    box-shadow: 0px 3px 6px #0000001A;
    background-color: ${ ({theme}) => theme.components.gallery.tileLowerBackgroundColor };
    border-top: 1px solid ${({theme}) => theme.components.gallery.borderColor };
`;

export const TileTitle = styled.div`
    min-height: ${(props) => props.noHeight ? '' : '2.8rem'};
    ${semiBoldDefaultFont}
    letter-spacing: -0.18px;
    line-height: 1.4rem;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin-bottom: 0.8rem;
`;

export const IconBar = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LeftIconBar = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    svg {
        position: relative;
        width: 1.8rem !important;
        height: 1.8rem;
        padding: 0.3rem;
        border-radius: 0.3rem;
        border: 0.75px solid ${({theme}) => {
            return theme.components.gallery.borderColor
        }};

        path {
            fill: ${({theme}) => {
                return theme.color.primary;
            }};
        }
    }

    ${mediaQueries('desktop')`
        svg {
            width: 2rem !important;
            height: 2rem;
            &:nth-of-type(2) {
                margin-left: 0.8rem;
            }
        }
    `};
`;

export const RightIconBar = styled.div`
    display: flex;
    align-items: center;
    color: ${({theme}) => {
        return theme.components.gallery.viewCountColor;
    }};

    svg {
        width: 1.3rem !important;
        height: 1.3rem;
        margin-right: 0.8rem;

        path {
            fill: ${({theme}) => {
                return theme.components.gallery.viewCountColor;
            }};
        }
    }
`;

export const ViewCount = styled.span`
    ${boldDefaultSmallFont} 
    margin-right: 0.4rem;
    color: ${({theme}) => {
        return theme.components.gallery.viewCountColor;
    }};
`;

export const ListItemContainer = styled.div`
    ${semiBoldDefaultFont}
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
`;

export const ListItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    width: 100%;

    &:hover {
        cursor: pointer;
    }

    &:first-of-type {
        margin-top: 0;
    }

    svg {
        margin: auto 1rem;

        &:hover {
            cursor: pointer;
        }

        path {
            fill: ${({theme}) => {
                return theme.color.primary;
            }};
        }
    }
`;

export const ListItemThumbnailImageContainer = styled.div`
    padding: 0.8rem 0;
`;

export const ListItemThumbnailImage = styled.span`
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({theme}) => {
        return theme.color.primary;
    }};
    border-radius: 100%;
    padding: 0.5rem;
    margin-left: 1.2rem;
`;

export const ListItemContentContainer = styled.div`
    ${boldDefaultSmallFont}
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ListItemInitials = styled.span`
    ${boldDefaultSmallFont}
    width: 2.5rem;
    height: 2.5rem;
    color: white;
    border-radius: 100%;
`;

export const ListItemInfoContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const ListItemName = styled.span`
    ${semiBoldDefaultFont}
    position: absolute;
    left: 1.2rem;
    top: 0.5rem;
    color: #fff;
`;

export const ListItemDescription = styled.span`
    position: absolute;
    left: 1.2rem;
    bottom: 0.5rem;
    font-size: 0.8rem;
    ${semiBoldFontWeight}
    color: ${ ({theme}) => {
        return theme.color.quaternary ? theme.color.quaternary : '#fff'
    }};

    ${mediaQueries('desktop')`
        ${semiBoldDefaultFont}
    `};
`;

export const ListItemOnlineStatus = styled.span`
    ${semiBoldFontWeight}
    padding: 1.4rem 1.2rem 1.2rem 0;

    color: ${(props) => props.isOnline ? '#000' : '#7D8386'};
`;

export const ListItemMessageContainer = styled.span`
    padding: 1.4rem 1.2rem 1.2rem 0;
`;

export const CreateButtonContainer = styled.div`
    display: block;
    width: 100%;
    margin-top: .5rem;
`;

export const CreateButton = styled.button`
    width: 13rem;
    height: 3rem;
    border-radius: 3rem;
    margin: auto 0 1rem 0;
    ${boldFontWeight}
    background-color: ${ ({theme}) => {
        return theme.color.senary ? theme.color.senary : '#fff'
    }};
    color: ${ ({theme}) => {
        return theme.color.primary;
    }};
    border: none;
    outline: none;
`;