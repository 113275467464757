import styled from 'styled-components'
import { mediaQueries, defaultFontFace, boldDefaultSmallFont, semiBoldFontWeight, semiBoldDefaultSmallFont } from '../../../styles/helpers'

export const BaseHeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${({theme}) => theme.components.hero.borderColor };
    ${defaultFontFace}
`;

export const LandscapeContainer = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: ${ ({ theme }) =>
            `transparent linear-gradient(128deg, ${theme.color.quinary} 0%, ${theme.color.primary} 51%, ${theme.color.nonary} 100%) 0% 0% no-repeat padding-box`
        };
    };

    ${mediaQueries('smallPhone')`
        height: 5rem;
    `};

    ${mediaQueries('desktop')`
        height: 12rem;
    `};

`;

export const LandscapeSubContainerLeft = styled.div`
    width: 50%;
    height: 100%;
    text-align: left;

    ${mediaQueries('smallPhone')`
        padding: 1rem;
    `};

    ${mediaQueries('desktop')`
        padding: 2rem;
    `};
    
`;

export const LandscapeSubContainerRight = styled.div`
    width: 50%;
    height: 100%;
    text-align: right;

    ${mediaQueries('smallPhone')`
        padding: 1rem;
    `};

    ${mediaQueries('desktop')`
        padding: 2rem;
    `};

`;

export const LandscapeSubContainerButtons = styled.div`
    position: relative;
`;

const showLabel = (type) =>
    (type === 'PROFILE' || type === 'GROUP')

export const LocationLabel = styled.div`
    display: ${(props) => (showLabel(props.targetType) ? 'inline-block' : 'none')};
    background-color: ${ ({theme}) => theme.components.hero.badgeBackgroundColor };
    color: ${ ({theme}) => theme.components.hero.badgeFontColor };
    ${boldDefaultSmallFont}
    font-size: .75rem;
    position: relative;
    border-radius: 6px;
    padding: 0.3rem 0.5rem;
    white-space: nowrap;

    ${mediaQueries('tablet')`
        font-size: 0.9rem;
    `};
    
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    box-shadow: 0px 3px 6px #0000001A;
    background-color: ${({ theme }) => theme.components.hero.bottomBackgroundColor };
`;

export const BottomSubContainerLeft = styled.div`
    width: 50%;
    height: 100%;
    text-align: right;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    ${mediaQueries('smallPhone')`
        padding: 1rem;
    `};

    ${mediaQueries('desktop')`
        padding: 2rem;
        flex-direction: row;
    `};

`;

export const BottomSubContainerRight = styled.div`
    height: 100%;
    width: 50%;
    text-align: right;
    padding: 1.5rem;
`;

export const Logo = styled.div`
    border-radius: 100%;
    display: flex;
    padding: 0.5rem;

    background-color: ${({ theme }) => theme.components.hero.profileImgBackgroundColor };

    svg {

        ${mediaQueries('smallPhone')`
            width: 5rem !important;
            height: 4.5rem !important;
        `};

        ${mediaQueries('desktop')`
            width: 9rem !important;
            height: 8rem !important;
        `};
        
        path {
            fill: ${({ theme }) => theme.components.hero.profileImgColor };
        };
    }

    ${mediaQueries('smallPhone')`
        width: 6rem;
        height: 6rem;
    `};

    ${mediaQueries('desktop')`
        width: 10rem;
        height: 10rem;
    `};
`;

export const DetailsContainer = styled.div`
    position: relative;
    margin-left: 0.5rem;
    display: inline-block;
    text-align: left;

    ${mediaQueries('desktop')`
        margin-left: 2rem;
    `};
`;

export const Title = styled.div`
    ${semiBoldFontWeight}
    color: ${({ theme }) => theme.components.hero.titleColor };

    ${mediaQueries('smallPhone')`
        font-size: 1.2rem;
    `};

    ${mediaQueries('desktop')`
        font-size: 1.5rem;
    `};

`;

export const Description = styled.div`
    ${semiBoldDefaultSmallFont}
    color: ${({ theme }) => theme.components.hero.descriptionColor };
    margin-top: 0;
    line-height: 1.5rem;

    ${mediaQueries('smallPhone')`
        margin-top: 0.3rem;
        line-height: 1.3rem;
    `};
`;


const isAllowedToHaveProfileImg = (targetType) => {
    switch (targetType) {
        case 'PROFILE':
            return true
        case 'GROUP':
            return false
        case 'CHANNEL':
            return true
        case 'EVENT':
            return true    
        default:
            return false
    }
}

export const ProfileImg = styled.div`
    display: ${(props) => ( isAllowedToHaveProfileImg(props.targetType) ? 'block' : 'none' )};
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: 100%;
    margin-top: -5.5rem;
    z-index: 10;
    background: white;

    ${mediaQueries('smallPhone')`
        width: 6rem;
        height: 6rem;
        margin-top: -5.5rem;
    `};

    ${mediaQueries('desktop')`
        width: 10rem;
        height: 10rem;
        margin-top: -5.5rem;
    `};
    
`;
