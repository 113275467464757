import styled from 'styled-components'

export const PageContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 93%;
`;

export const PageContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin: 0;

`;
