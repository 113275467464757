import React, { useEffect, useState } from 'react'
import ExplorePage from '../../../../pages/explore-page'
import EventDetailsPageWithMembershipQuery from '../../../../pages/event-details-page'

const PAGES = {
  EXPLORE: 'EXPLORE',
  EVENT_DETAILS: 'EVENT_DETAILS',
}

const ExploreDirector = ({
  appConfig, authUser, goToEvent, appEvents, currentEvent, profile, goToChannelDetails, setSidebar, goToEventDetails, goToExplore
}) => {

  let initialState = {
    page: PAGES.EXPLORE
  }

  if (currentEvent && currentEvent.eventId) {

    initialState = {
      page: PAGES.EVENT_DETAILS,
      event: currentEvent
    }
  }

  const [current, setCurrent] = useState(initialState)

  useEffect(() => {
    const isMobile = (window.innerWidth < 769)
    if (!isMobile) {
      setSidebar(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToEventDetailsWithCurrent = (event) => () => {
    setCurrent({ page: PAGES.EVENT_DETAILS, event })
    goToEventDetails(event)()
  }

  const resolveView = () => {
    switch (current.page) {
      case PAGES.EXPLORE:
        return <ExplorePage 
                appConfig={appConfig} 
                authUser={authUser} 
                goToEventDetails={goToEventDetailsWithCurrent} 
                goToChannelDetails={goToChannelDetails}
                appEvents={appEvents} 
              />
      case PAGES.EVENT_DETAILS:
        return <EventDetailsPageWithMembershipQuery event={current.event} goToEvent={goToEvent(current.event)} profile={profile} authUser={authUser} />
      default:
        return <> Hmm, not sure how you got here </>
    }
  }

  return (
    <div className="mt-3" style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      { resolveView() }
    </div>
  )
}

export default ExploreDirector
