import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { GROUP_EDITS_MUTATION } from './mutations'
// import { GET_SESSION_REQUEST } from '../../utils/graphql'
import BaseFormComponent from '../../shared/base-form'

const EditGroupModal = ({
  isOpen, toggle, group, authUser
}) => {

const [editGroup] = useMutation(GROUP_EDITS_MUTATION)

const handleSubmitGroupEdits = (props) => {
    const attributes = {
        ...props,
        groupId: group.groupId
    }
    const variables = generateVariables(attributes)
    editGroup(variables)
    toggle()
}


const generateVariables = (attributes) => ({
    variables: {
        request: {
            ...attributes
        }
    },
    // refetchQueries: [{
    //   ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
    //   fetchPolicy: 'network-only',
    // }],
  })


  const editGroupForm = {
    isInline: false,
    showErrors: false,
    fields: {
        displayName: {
        dataType: 'STRING',
        type: 'text',
        name: 'displayName',
        label: 'Name',
        showLabel: true,
        defaultValue: '',
        placeholder: group.displayName,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      description: {
        dataType: 'STRING',
        type: 'text',
        name: 'description',
        label: 'Description',
        showLabel: true,
        defaultValue: '',
        placeholder: group.description,
        isRequired: false,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
      primaryImageUrl: {
        dataType: 'IMAGE',
        type: 'image',
        name: 'primaryImageUrl',
        label: 'Profile Picture',
        showLabel: true,
        defaultValue: group.primaryImageUrl,
        placeholder: group.primaryImageUrl,
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
      headerImageUrl: {
        dataType: 'IMAGE',
        type: 'image',
        name: 'headerImageUrl',
        label: 'Banner Image',
        showLabel: true,
        defaultValue: group.headerImageUrl,
        placeholder: group.headerImageUrl,
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Group</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <BaseFormComponent form={editGroupForm} submit={handleSubmitGroupEdits} />
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default EditGroupModal
