import React, { useEffect, useState } from 'react' 
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getCurrentBrandConfig } from '../../utils/brand-silo'
import Logo from '../logo'


const { appId, logo } = getCurrentBrandConfig()
const CONSENT_IDENTIFIER = `@${appId}/AGE_CONSENT`        
const TRUE_IDENTIFIER = 'TRUE'


const generateHotboxSplashpage = (hasConsent, setConsent) => ({
    isOpen: !hasConsent,
    headerText: (<p><b>WELCOME TO THE WORLD’S LARGEST VIRTUAL HOTBOX DESTINATION; BLENDING THE BEST OF CANNABIS, CULTURE, CONVERSATION, AND COMMUNITY.</b></p>),
    bodyText: (<p>ARE YOU OF LEGAL AGE FOR RECREATIONAL MARIJUANA AND AGREE TO OUR <a className="color-primary" href="https://docs.hotboxsocial.com/" target="_blank" rel="noopener noreferrer" >TERMS OF SERVICE</a>?</p>),
    primary: {
        text: "YES",
        action: () => {
            localStorage.setItem(CONSENT_IDENTIFIER, TRUE_IDENTIFIER)
            setConsent(true)
        }
    },
    secondary: {
        text: "NO",
        action: () => {
            window.location.replace("https://en.wikipedia.org/wiki/Main_Page")
        }
    },        
})


const processSplashPage = (appId, hasConsent, setConsent) => {
    let form = {
        isOpen: false,
        primary: {
            action: () => {},
            key: ''
        },
        secondary: {
            action: () => {},
            key: ''
        },
    }

    switch (appId) {
        case 'HOTBOX':
            form = generateHotboxSplashpage(hasConsent, setConsent)
            break
        default:
            break
    }
    return form
}



const SplashPage = () => {

    const [hasConsent, setConsent] = useState(localStorage.getItem(CONSENT_IDENTIFIER) === TRUE_IDENTIFIER)

    const form = processSplashPage(appId, hasConsent, setConsent)

    useEffect(() => {
        const consent = localStorage.getItem(CONSENT_IDENTIFIER) === TRUE_IDENTIFIER
        setConsent(consent)
    }, [])

    return (
        <Modal isOpen={form.isOpen} >
            <ModalHeader >
                <Logo appId={appId} logo={logo} />
            </ModalHeader>
            <ModalBody>
                { form.headerText }
                { form.bodyText }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" block onClick={form.primary.action}>{ form.primary.text }</Button>{' '}
                <Button color="secondary" block onClick={form.secondary.action}>{ form.secondary.text }</Button>
            </ModalFooter>
        </Modal>
    )

}


export default SplashPage