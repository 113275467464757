export const TesseractAppConfig = {
  appId: 'TESSERACT',
  name: 'Tesseract Media',
  logo: 'TESSERACT MEDIA',
  appUrl: 'tesseract.media',
  favicon: 'tesseract-favicon.ico',
  pages: [
    'LANDING',
    'EXPLORE',
  ],
  type: 'MULTI',
  forceSso: false,
  landing: {
    page: 'LANDING',
    header: 'DYNAMIC EXPERIENCES',
    subHeader: 'Media that transcends dimensions',
    cta: {
      primary: {
        type: 'REF',
        ref: 'create-an-app',
        text: 'Create an App',
        color: 'primary',
        isBlock: true
      },
      secondary: {
        type: 'REF',
        ref: 'what-is-it',
        text: 'Learn More',
        color: 'secondary',
        isBlock: true
      },
    },
    items: [
      {
        type: 'COLUMN',
        title: 'What is Tesseract Media?',
        tag: 'THE FEATURES',
        id: 'what-is-it',
        items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/platform-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '4rem' },
              header: 'App Platform',
              description: 'This is a platform that houses custom apps that are easily configurable to suit your media and communication needs. Built utilizing the power of cloud computing, all services are infinitely scalable for any level of usage. ',
              showBorder: true
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/streaming-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '4rem' },
              header: 'Dynamic Experiences',
              description: 'There are a number of experiences that are unique to tesseract apps which allow for a new level of virtual events. Live streams, VOD, party, front-row, host controls (trivia, polling, etc).',
              showBorder: true
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/network-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '2rem' },
              header: 'Social & Micro-social Networks',
              description: 'Apps are configurable to all levels of access control to meet any need. Whether a globally public application or tightly restricted access, there is a wealth of security options to meet your service needs.',
              showBorder: true
            },
        ]
      },
      {
        type: 'COLUMN',
        title: 'Create an App',
        tag: 'USING TESSERACT',
        id: 'create-an-app',
        items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/channel-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Community Platform',
              description: 'Get started creating content on our community platform for free, and experience our unique features. Join the community platform to grow your brand, have a public presense, and even monetize your content.',
              cta: {
                  type: 'REF',
                  ref: 'community-features',
                  text: 'Learn more',
                  isBlock: true
              },
              showBorder: true
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/business-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Business',
              description: 'Utilize the platform for a standalone private access application for your business. Choose from a wealth of custom features to include in your app including: a custom domain, theme, and communication options.',
              cta: {
                  type: 'REF',
                  ref: 'business-features',
                  text: 'Learn more',
                  isBlock: true
              },
              showBorder: true
            },
        ]
      },
      {
        type: 'COLUMN',
        title: 'Community Platform',
        tag: 'COMMUNITY',
        id: 'community-features',
        items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/tesseract-tv-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Free Platform',
              description: 'Tesseract.tv is a free platform to shwowcase content and interact with audiences. Join a network of content creators and users to experience your content. Create virtual events, socialize with your audience, and monetize your content.',
              cta: {
                  type: 'LINK',
                  ref: 'https://tesseract.tv',
                  text: 'Launch App',
                  isBlock: true
              },
              showBorder: false
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/your-channel-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Premium Channel',
              description: 'Create a standalone channel for your content, or upgrade your existing tesseract.tv channel. A tesseract.tv channel can be upgraded at any time and undergo a seamless transition to a standalone website with a customized domain and theme.',
              cta: {
                  type: 'REF',
                  ref: 'business-features',
                  text: 'Learn more',
                  isBlock: true
              },
              showBorder: true
            },
        ]
      },
      {
        type: 'COLUMN',
        title: 'Business Platform',
        tag: 'ENTERPRISE',
        id: 'business-features',
        items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/communications-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Communications',
              description: 'Increase communications in your business through: conferencing (video/audio), messaging, groups, and virtual events.',
              showBorder: true
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/customization-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Customized Channel',
              description: 'Implement interactive experiences for your business. Courses, VOD, live streams, party, front-row, host controls (trivia, polling, etc).',
              showBorder: true
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/monetization-blue.png',
              imageStyle: {  marginTop: '1rem', marginBottom: '1rem' },
              header: 'Monetization',
              description: 'Create revenue streams by monetizing content and virtual events.',
              showBorder: true
          },
        ],
        cta: {
          type: 'REF',
          ref: 'contact-us',
          text: 'Contact Us'
        }
      },
      {
        type: 'COLUMN',
        title: 'Our Favorite Apps',
        tag: 'PUBLIC TESSERACT APPS',
        id: 'business-features',
        items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/tesseract-tv-logo.png',
              imageStyle: { marginTop: '-30px', marginBottom: '-30px' },
              header: 'Tesseract TV',
              description: 'Join a network of content creators and users to experience your content. Create virtual events, socialize with your audience, and monetize your content.',
              cta: {
                type: 'LINK',
                ref: 'https://tesseract.tv',
                text: 'Launch App',
                isBlock: true
              }
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/speakeasy-logo.png',
              imageStyle: { marginTop: '-30px', marginBottom: '-30px' },
              header: 'Speakeasy Social',
              description: 'Come join the party, and join virtual events with likeminded individuals, host your own virtual events and more.',
              cta: {
                type: 'LINK',
                ref: 'https://speakeasysocial.app',
                text: 'Launch App',
                isBlock: true
              }  
            },
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/yotta-logo.png',
              imageStyle: { marginTop: '-30px', marginBottom: '-30px' },
              header: 'Yotta Chat',
              description: 'A private communications app for you and your groups. An invite only platform for your secure communications.',
              cta: {
                type: 'LINK',
                ref: 'https://yotta.chat',
                text: 'Launch App',
                isBlock: true
              }
          },
        ]
      },

      {
        type: 'CONTACT',
        id: 'contact-us',
        isInline: false,
        email: true,
        company: true,
        body: true,
        name: true
      },
      {
        type: 'FOOTER',
        id: 'footer',
      },
    
    ]
  },
  pageConfigs: [
    {
      key: 'EXPLORE',
      headerText: 'Current Platform Brands',
      isAuthorized: false,
      tabs: [
        'APPS'
      ],
    },
    {
      key: 'EVENT',
      headerText: 'Current Platform Brands',
      isAuthorized: false,
      tabs: [],
    },
  ],
  events: [
    {
      key: 1,
      eventId: '01',
      title: 'Tesseract TV',
      displayName: 'Tesseract TV',
      status: 'BETA',
      description: 'Media that transcending dimensions',
      primaryImageUrl: 'https://tes-media-dev.s3.amazonaws.com/content/tesseract-tv-logo.png',
      type: 'REMOTE_URL',
      remoteUrl: 'https://tesseract.tv',
      path: 'tv',
      showLikeToggle: false,
    },
    {
      key: 2,
      eventId: '2020',
      title: 'Speakeasy',
      displayName: 'Speakeasy',
      status: 'BETA',
      description: 'The last club open in the quarantine',
      primaryImageUrl: 'https://tes-media-dev.s3.amazonaws.com/content/speakeasy-logo.png',
      type: 'REMOTE_URL',
      remoteUrl: 'https://speakeasysocial.app',
      path: 'speakeasy',
      showLikeToggle: false,
    },
    {
      key: 3,
      eventId: '2069',
      title: 'yotta.chat',
      displayName: 'yotta.chat',
      status: 'BETA',
      description: 'The best messenger',
      primaryImageUrl: 'https://tes-media-dev.s3.amazonaws.com/content/yotta-logo.png',
      type: 'REMOTE_URL',
      remoteUrl: 'https://yotta.chat',
      path: 'yotta',
      showLikeToggle: false,
    },
    // {
    //   key: 4,
    //   eventId: '2021',
    //   title: 'NextStep',
    //   status: 'BETA',
    //   description: 'Preparing for the future',
    //   type: 'REMOTE_URL',
    //   remoteUrl: 'https://nextstepsomething.com',
    //   path: 'nextstep',
    //   showLikeToggle: false,
    // },
  ],
  theme: {
    components: {
      page: {
        backgroundColor: 'secondary',
        altBackgroundColor: 'tertiary',
        color: 'tertiary',
        backgroundTransparency: 'nonary',
      },
      navBar: {
        backgroundColor: 'secondary',
        logoColor: 'tertiary',
        thumbnailContainerColor: 'nonary',
        iconColor: 'primary',
        iconBackgroundColor: 'secondary',
        borderColor: 'senary',
      },
      sideBar: {
        fontColor: 'tertiary',
        backgroundColor: 'secondary',
        inActiveLogoBorderColor: 'senary',
        itemComponentColorPassive: 'secondary',
        itemComponentColorActive: 'nonary',
        itemBackgroundColorPassive: 'secondary',
        itemBackgroundColorActive: 'septenary',
        itemColorPassive: 'primary',
        itemColorActive: 'secondary',
        logoColor: 'tertiary',
        borderColor: 'senary',
      },
      hero: {
        titleColor: 'tertiary',
        descriptionColor: 'tertiary',
        bottomBackgroundColor: 'secondary',  
        badgeBackgroundColor: 'tertiary',
        badgeFontColor: 'octonary',
        borderColor: 'senary',
        profileImgBackgroundColor: 'quinary',
        profileImgColor: 'quaternary'
      },
      gallery: {
        backgroundColor: 'secondary',
        borderColor: 'senary',
        tileLowerBackgroundColor: 'quinary',
        viewCountColor: 'quaternary',
        menuItemColorPassive: 'quaternary',
        menuItemColorActive: 'tertiary',
        menuItemBadgeBackgroundColorPassive: 'quaternary',
        menuItemBadgeBackgroundColorActive: 'tertiary',
        menuItemBadgeColorPassive: 'tertiary',
        menuItemBadgeColorActive: 'secondary',
        menuItemAccent: 'primary'
      }
    },  
    color: {
      primary: '#1CA6ED', // primary green
      secondary: '#FFFFFF', // white
      tertiary: '#293848', // slate
      quaternary: '#A7A7A7', // gray
      quinary: '#f7f7f7', // light black
      senary: '#575757', // dark gray
      septenary: '#1CA6EDBE', // primary with transparency
      octonary: '#292D34', // middle black
      nonary: '#1CA6ED8B', // primary with more transparency
      denary: '#1CA6ED20', // primary with most transparency
    },
  type: {
      primary: 'Orbitron',
      secondary: 'Montserrat',
    },
  },
}
