import { InMemoryCache, makeVar } from "@apollo/client";
import { getCurrentBrandConfig } from '../../utils/brand-silo'


const initialSession = {
    appData: {
        isLoading: true,
        shouldToggleSystemUserModal: false,
        ...getCurrentBrandConfig()
    }
}


export const mySession = {
    initial: initialSession,
    reactive: makeVar(initialSession),
    dataType: 'OBJECT',
}

export const myProfile = {
    reactive: makeVar(null),
    dataType: 'OBJECT',
}

export const profileList = {
    reactive: makeVar(null),
    dataType: 'ARRAY',
    primaryKey: 'profileId'
}

export const eventList = {
    reactive: makeVar(null),
    dataType: 'ARRAY',
    primaryKey: 'eventId',
}

export const myMessengerContexts = {
    reactive: makeVar(null),
    dataType: 'ARRAY',
    primaryKey: 'contextHash'
}

export const currentMessengerContext = {
    reactive: makeVar(null),
    dataType: 'OBJECT',
}


export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                mySession: {
                    read() {
                        return mySession.reactive()
                    }
                },
            }
        }
    }
})
