import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Label } from 'reactstrap'

const ConnectEventComponent = ({ event, viewerType, goToEvent, goToPartySelector, partyDetails }) => {

    const [audioTracks, setAudioTracks] = useState([])
    const [videoTracks, setVideoTracks] = useState([])

    const [selectedAudioTrack, selectAudioTrack] = useState()
    const [selectedVideoTrack, selectVideoTrack] = useState()

    const { isAudioOnlyParty } = event

    useEffect(() => {

        const resolution = { width: { ideal: 1280 }, height: { ideal: 720 } }
        const constraints = {
            video: isAudioOnlyParty ? false : resolution,
            audio: true,
        }

        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                var userVideoTracks = stream.getVideoTracks()
                var userAudioTracks = stream.getAudioTracks()
                setAudioTracks(userAudioTracks)
                setVideoTracks(userVideoTracks)
            })
            .catch(error => {
                console.log({ error })
            })
    }, [isAudioOnlyParty])

    const config = {
        width: '100%',
        borderRadius: '10px',
        height: '30vh',
        objectFit: 'cover',
    }
    
    const handleVideoTrackChange = (event) => {
        if (event.target.value !== 'none') {
            const video = document.getElementById('temp-local-video')
            const videoStream = new MediaStream()
            const track = videoTracks.find(t => t.id === event.target.value)
            selectVideoTrack(event.target.value)
            videoStream.addTrack(track)
            video.srcObject = videoStream
        } else {
            selectVideoTrack(undefined)
        }
    }

    const handleAudioTrackChange = (event) => {
        if (event.target.value !== 'none') {
            console.log({ audioId: event.target.value })
            const audio = document.getElementById('temp-local-audio')
            const audioStream = new MediaStream()
            const track = audioTracks.find(t => t.id === event.target.value)
            selectAudioTrack(event.target.value)
            console.log({ track })
            audioStream.addTrack(track)
            audio.srcObject = audioStream
        } else {
            selectAudioTrack(undefined)
        }
    }

    const handleSubmit = () => {

        if (!selectedAudioTrack) {
            alert('You need to select at least an audio track')
        } else {
            const stream = new MediaStream()
            if (selectedAudioTrack && selectedAudioTrack !== 'none') {
                const audioTrack = audioTracks.find(t => t.id === selectedAudioTrack)
                stream.addTrack(audioTrack)
            }

            if (selectedVideoTrack && selectedVideoTrack !== 'none') {
                const videoTrack = videoTracks.find(t => t.id === selectedVideoTrack)
                stream.addTrack(videoTrack)
            }

            const localMedia = {
                audioStreamId: selectedAudioTrack,
                videoStreamId: selectedVideoTrack,
                stream
            }
            
            const isHost = viewerType === 'HOST'
            const isGroupEvent = event.type === 'GROUP_EVENT'

            const eventHasExistingParties = ( partyDetails && partyDetails.parties && partyDetails.parties.length > 0 )
            const shouldShowPartySelectorForGroup = ( isGroupEvent && eventHasExistingParties )
            
            if (isHost || shouldShowPartySelectorForGroup) {
                goToPartySelector(viewerType, localMedia)
            } else {
                goToEvent(viewerType, localMedia)
            }

        }
    }   

    return (
        <Row>
            <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }} >
                
                <h3>Connect to event</h3>
                { !isAudioOnlyParty && (
                    <Row>
                        <Col>
                            <Label>Video</Label>
                            <select onChange={handleVideoTrackChange}>

                                <option>none</option>

                                {videoTracks.length && videoTracks.map(vt => (
                                    <option value={vt.id}  >{vt.label}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <Label>Audio</Label>
                        <select onChange={handleAudioTrackChange}>

                            <option>none</option>

                            {audioTracks.length && audioTracks.map(at => (
                                <option value={at.id} >{at.label}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
                { !isAudioOnlyParty && (
                    <Row>
                        <Col xs={12}>
                            <video id="temp-local-video" style={config} autoPlay playsInline muted />
                        </Col>
                        <Col xs={12}>
                            <FontAwesomeIcon className="mr-2" icon={ (selectedVideoTrack === undefined) ? faTimes : faCheckCircle } />
                            Video
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={12} className="d-none" >
                        <audio id="temp-local-audio" style={config} controls autoPlay playsInline muted />
                    </Col>
                    <Col xs={12}>
                        <FontAwesomeIcon className="mr-2" icon={ (selectedAudioTrack === undefined) ? faTimes : faCheckCircle } />
                         Audio
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button block color="primary" onClick={handleSubmit}>Next</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ConnectEventComponent
