import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import { CREATE_POST_MUTATION } from './mutations'
import { GET_SESSION_REQUEST } from '../../../utils/graphql'
import BaseFormComponent from '../../../shared/base-form'

const CreatePostModal = ({
  isOpen, toggle, profileId, authUser
}) => {


const [createPost] = useMutation(CREATE_POST_MUTATION)

const handleCreatePost = (props) => {
    const { text, linkUrl, primaryImageUrl } = props

    const request = {
        entityId: profileId,
        entityType: 'PROFILE',
        text, 
        linkUrl: linkUrl === '' ? 'none' : linkUrl, 
        primaryImageUrl,
    }

    const variables = generateVariables(request)

    createPost(variables)
    toggle()
}


const generateVariables = (request) => ({
    variables: {
        request
    },
    refetchQueries: [{
      ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
      fetchPolicy: 'network-only',
    }],
  })


    const createPostForm = {
        isInline: false,
        showErrors: true,
        fields: {
            text: {
                dataType: 'STRING',
                type: 'text',
                name: 'text',
                label: 'What do you want to say?',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                disabled: '',
                isRequired: true,
                minLength: 1,
                maxLength: 1000,
                xs: 12,
            },
            linkUrl: {
                dataType: 'STRING',
                type: 'text',
                name: 'linkUrl',
                label: 'Link URL',
                showLabel: true,
                defaultValue: '',
                placeholder: '',
                isRequired: false,
                minLength: 1,
                maxLength: 250,
                xs: 12,
            },
            primaryImageUrl: {
                dataType: 'IMAGE',
                type: 'image',
                name: 'primaryImageUrl',
                label: 'Image',
                showLabel: true,
                defaultValue: "none",
                placeholder: '',
                isRequired: true,
                xs: 12,
            },
        },
        submit: {
            isBlock: true,
        },
    }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Create a post</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <BaseFormComponent form={createPostForm} submit={handleCreatePost} />
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default CreatePostModal
