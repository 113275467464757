import { gql } from '@apollo/client'

export const SUBSCRIBE_USER_TO_CHANNEL_MUTATION = gql`
  mutation subscribeUserToChannel($request: SubscriptionRequest) {
    subscribeUserToChannel(request: $request) {
        client_secret
        status
    }
  }
`

export const UNSUBSCRIBE_USER_TO_CHANNEL_MUTATION = gql`
  mutation unsubscribeUserToChannel($channelId: String) {
    unsubscribeUserToChannel(channelId: $channelId)
  }
`
