import { gql } from '@apollo/client'

export const GET_CHANNEL_MEMBERSHIP_QUERY = gql`

query ($request: IsUserSubscribedToChannelRequest){
    getChannelMembership(request: $request) {
        product {
            productId
            priceId
            amount
            name
            description
            symbol
        }
        targetId
        membershipHash
        modifiedAt
        otherAttr {
            parent
        }
        targetType
        createdAt
        email
        membershipId
        isActive
        timestampUnix
        isAccepted
        appId
        phone
        profileId
    }
}
`
