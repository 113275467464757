import React, { useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import LoadingComponent from '../../loading-component'
import { generateUrlPath } from '../../../utils/routing'
import { getAppId } from '../../../utils/brand-silo'
import { mySession } from '../../../shared/cache'

const removeLocalStorageItems = (query) => {
  const storageKeys = Object.keys(localStorage).filter(k => k.includes(query))   
  storageKeys.map(key => localStorage.removeItem(key))
}

const getCognitioAttributes = (cognito) => (cognito && cognito.attributes ? cognito.attributes : { sub: null })


const getAuthDisplayName = (cognito) => {
  const { email } = getCognitioAttributes(cognito)
  const displayName = email.substring(0, email.indexOf('@'))
  return displayName
}

const AuthGuard = ({ render }) => {
  const [loading, setLoading] = useState(true)
  const [authSession, setAuthSession] = useState()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognito) => {
        const { sub: cognitoId } = getCognitioAttributes(cognito)
        const { email } = getCognitioAttributes(cognito)
        const displayName = getAuthDisplayName(cognito)
        setAuthSession({ ...cognito, cognitoId, displayName, createWssConnection: true, email })
        setLoading(false)
      })
      .catch((err) => {
        setAuthSession({ cognitoId: null, createWssConnection: false })
        setLoading(false)
      })

    Hub.listen('auth', (e) => {
      const { event: eventType } = e.payload
      switch (eventType) {
        case 'signOut':
          localStorage.removeItem('@TESSERACT/ID_TOKEN')
          localStorage.removeItem('@TESSERACT/ACCESS_TOKEN')
          localStorage.removeItem('@TESSERACT/PROFILE')
          removeLocalStorageItems('CognitoIdentityServiceProvider')
          setTimeout(() => {
            const appId = getAppId()
            const path = 'LANDING'
            const urlPath = generateUrlPath(appId, path, {}, {}, {[path]:path} )
            const newPath = `${window.location.origin}${urlPath}`
            window.location.href = newPath
          }, 100)
          break
        case 'signIn':
          const { data: cognito } = e.payload
          if (cognito && cognito.attributes) {
            const { sub: cognitoId, email } = getCognitioAttributes(cognito)
            const displayName = getAuthDisplayName(cognito)
            setAuthSession({
              ...cognito, cognitoId, createWssConnection: true, displayName, email
            })
            mySession.reactive(mySession.initial)
          }
          break
        default:
          break
      }
    })
  }, [])

  if (loading) {
    return <LoadingComponent isSite />
  } if (authSession) {
    return render(authSession)
  }
}

export default AuthGuard
