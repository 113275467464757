import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import BaseFormComponent from '../../shared/base-form'

const CreateChannelModal = ({ toggle, isOpen, createChannel }) => {
  const createNewChannel = ({ displayName, description }) => {
    const variables = {
      request: {
        displayName,
        description,
      },
    }
    createChannel({ variables })
    toggle()
  }

  const form = {
    isInline: false,
    showErrors: true,
    fields: {
      displayName: {
        dataType: 'STRING',
        type: 'text',
        name: 'displayName',
        label: 'Channel Name',
        showLabel: true,
        defaultValue: '',
        placeholder: '',
        isRequired: true,
        minLength: 1,
        maxLength: 20,
        xs: 12,
      },
      description: {
        dataType: 'STRING',
        type: 'text',
        name: 'description',
        label: 'Description',
        showLabel: true,
        defaultValue: '',
        placeholder: '',
        isRequired: false,
        minLength: 1,
        maxLength: 200,
        xs: 12,
      },

    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Create Channel</ModalHeader>
      <ModalBody>
        <BaseFormComponent form={form} submit={createNewChannel} />
      </ModalBody>
    </Modal>
  )
}

export default CreateChannelModal
