import { gql } from '@apollo/client'

export const CREATE_GROUP_MUTATION = gql`
  mutation createGroup($request: CreateGroupRequest) {
    createGroup(request: $request) {
        displayName
        groupId        
        appId
        ownerId
        createdAt
        modifiedAt
        members {
            displayName
            isActive
            isAccepted
            targetType
            appId
            profileId
            membershipId
            userId
            status
            userName
            interest
            isOnline
            currentPartyId
        }
    }
  }
`
