import React, { useRef } from 'react'
import ReactPlayer from 'react-player'
import YouTube from 'react-youtube'


const NativeStreamComponent = ({ currentHost, event, shouldSync }) => {

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    const config = {
        file: {
            forceHLS: !isSafari,
            forceVideo: true,
            // attributes: {
            //     poster: feed && feed.actionUrl && feed.actionUrl.image,
            //     disablePictureInPicture: true
            //   
        },
    }
  
    const streamToShow = (event.isLive && currentHost.liveStreamUrl) ? currentHost.liveStreamUrl : currentHost.streamUrl
  
    const playerRef = useRef()


    const handleOnReady = () => {
      if (shouldSync && event.isLive) {

        const startDateTime = new Date(event.startTime)
        const currentDateTime = new Date()

        const timeDiff = startDateTime.getTime() - currentDateTime.getTime() 
        const secondsDiff = timeDiff / 1000
        const absSecondsDiff = Math.abs(secondsDiff)
 
        const playerTime = playerRef.current.getCurrentTime()
        const diff = Math.abs(playerTime - absSecondsDiff)

        if (diff > 1) {
          playerRef.current.seekTo(absSecondsDiff)
        }
      }
    }

    return (
      <ReactPlayer 
        ref={playerRef}
        url={streamToShow}
        playing={true}
        controls={true}
        volume={.5}
        width={'100%'}
        height={'auto'}
        config={config}
        onReady={handleOnReady}
        onPlay={handleOnReady}
      />
    )
}  
  
const YoutubeStreamComponent = ({ currentHost }) => {
    // const isMobile = window.innerWidth < 768
    // const mobileWidth = `${window.innerWidth * 0.9}`
    // https://developers.google.com/youtube/player_parameters
  
    const opts = {
      // width: isMobile ? mobileWidth : '640',
      width: '100%',
      height: 'auto',
      // height: 'auto',
      playerVars: {
        autoplay: 1,
        playsinline: 1,
      },
    }
  
    return (
      <YouTube videoId={currentHost.entityId} opts={opts} />
    )
}
  
const resolveStreamType = (currentHost, event, shouldSync) => {

  switch (currentHost.streamType) {
      case 'NATIVE':
        return (
          <NativeStreamComponent currentHost={currentHost} event={event} shouldSync={shouldSync} />
        )
      case 'YOUTUBE':
        return (
          <YoutubeStreamComponent currentHost={currentHost} />
        )
      default:
        return (
          <>unable to identify stream type</>
        )
    }
}


const EventMediaComponent = ({ event, shouldSync }) => {
    
    const currentHost = event.hosts.find((h) => h.isPrimary)

    return resolveStreamType(currentHost, event, shouldSync)

}


export default EventMediaComponent

