import { gql } from '@apollo/client'

export const PUT_TEMPLATE_MUTATION = gql`
  mutation putTemplate($request: PutTemplateRequest){
    putTemplate(request: $request) {
        title
    }
  }
`


export const PUT_TEMPLATE_ITEM_MUTATION = gql`
  mutation putTemplateItem($request: PutTemplateItemRequest){
    putTemplateItem(request: $request)
  }
`
