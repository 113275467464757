import React, { useState } from 'react'
import BaseHeroComponent from '../base-hero-component'
import { Button  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import CreateEventModal from '../../create-event-modal'
import EditChannelModal from '../../edit-channel-modal'
import SubscribeChannelComponent from '../../subscribe-channel-component'


const ChannelHeroComponent = ({ channel, myProfileId, authUser, profile }) => {
    const [isSubscribed, setIsSubscribed] = useState(channel.isSubscribed)
    const [showSubscribeModal, setShowSubscribeModal] = useState(false)

    const [showCreateEventModal, setCreateEventModal] = useState(false)

    const [showEditChannelModal, setShowEditChannelModel] = useState(false)

    const toggleModal = () => setCreateEventModal(!showCreateEventModal)

    const toggleEditModal = () => setShowEditChannelModel(!showEditChannelModal)

    const isChannelOwner = myProfileId === channel.profileId
    const { channelId, products, displayName } = channel
    const SecondaryRenderComponent = () => (
        <>
            <SubscribeChannelComponent
                channelId={channelId} 
                products={products}
                displayName={displayName}
                isSubscribed={isSubscribed} 
                setIsSubscribed={setIsSubscribed} 
                showSubscribeModal={showSubscribeModal} 
                setShowSubscribeModal={setShowSubscribeModal} 
                profileId={myProfileId}
                authUser={authUser}
            />
            { isChannelOwner && (
                <>
                    <EditChannelModal isOpen={showEditChannelModal} toggle={toggleEditModal} channel={channel} authUser={authUser} />
                    <Button color="primary" className="ml-2" outline onClick={toggleEditModal} >
                        <FontAwesomeIcon 
                            color="primary"
                            className="cursor-pointer"
                            icon={faEdit}
                        />
                    </Button>
                </>
            )}
        </>
    )

    const createEventRender = () => {
        return myProfileId === channel.profileId
            ?   <>
                    <CreateEventModal channelId={channel.channelId} isOpen={showCreateEventModal} toggle={toggleModal} profile={profile} products={channel.products} allowStream={true} />
                    <Button color="secondary" onClick={toggleModal} >Create Event</Button>
                </>
            : <></>
    }

    return (
        <BaseHeroComponent
            name={channel.displayName}
            description={channel.description}
            targetType={"CHANNEL"}
            targetId={channel.channelId}
            isLiked={false}
            showLikeToggle={false}
            primaryRender={createEventRender}
            secondaryRender={SecondaryRenderComponent}
            labelText={"Channel"}
            imageUrl={channel.primaryImageUrl}
            headerImageUrl={channel.headerImageUrl}
        />
    )
}

export default ChannelHeroComponent
