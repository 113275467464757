import styled from 'styled-components'


export const MessagengerContainer = styled.div`

    height: 100%;   

    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    // opacity: 0.8;

`;


export const MessagengerListContainer = styled.div`

    height: 100%;
    padding: 10px;
    overflow: scroll;
    margin-bottom: .3rem;

`
