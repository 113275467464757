import React from 'react'
import { Button } from 'reactstrap'


export const resolveCta = (cta, fn, goTo) => {
    const style = cta.color === 'primary' ? { color: 'white' } : {}

    switch (cta.type) {
        case 'REF':
            return (<Button href={`#${cta.ref}`}style={style} color={cta.color} block={cta.isBlock} >{ cta.text }</Button>)
        case 'LINK':
            return (<Button style={style} onClick={() => window.open(cta.ref, "_blank")} color={cta.color} block={cta.isBlock} >{ cta.text }</Button>)
        case 'SITE-LINK':
            const goToSiteLink = () => goTo(cta.director, cta.payload)
            return (<Button style={style} onClick={goToSiteLink} color={cta.color} block={cta.isBlock} >{ cta.text }</Button>)
        case 'FUNCTION':
            return (<Button block color={cta.color} style={style} onClick={fn}>{ cta.text }</Button>)
        default:
            return (<></>)
    }
}
