import React, { useState } from 'react'
import { Button, Container } from 'reactstrap'
import { useReactiveVar } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEdit } from '@fortawesome/free-solid-svg-icons'
import ProfileHeroComponent from '../../../hero/profile-hero-component'
import LoadingComponent from '../../../loading-component'
import { SEARCH_PROFILES_QUERY } from './queries'
import EditProfileModal from '../../../edit-profile-modal'
import BaseGalleryComponent from '../../../../shared/base-gallery'
import { myProfile, profileList } from '../../../../shared/cache'
import CachedFieldComponent from '../../../../shared/cache/helpers/CachedFieldComponent'

const isDefined = (def) => (def !== null && def !== undefined)


const ProfileDirector = ({
  appConfig,
  profile,
  isMyProfile,
  messenger,
  authUser,
  setMessengerContext,
  goToEvent,
  goToGroupDetails,
  goToChannelDetails,
  goToProfile,
  goToEventDetails,
}) => {
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)

  const toggleEditProfileModal = () => setShowEditProfileModal(!showEditProfileModal)

  return (
    <Container>
      <EditProfileModal isOpen={showEditProfileModal} toggle={toggleEditProfileModal}  profile={profile} appId={appConfig.appId} authUser={authUser} />

      <ProfileHeroComponent 
        isMyProfile={isMyProfile}
        profile={profile}
        authUser={authUser} 
        secondaryRender={() => (
          <>
            { isMyProfile && (
              <Button color="secondary" className="ml-3 btn-lg text-center" onClick={toggleEditProfileModal}>
                <FontAwesomeIcon color="secondary" icon={faEdit} />
              </Button>
            )}
          </>
        )}
      />

      <BaseGalleryComponent 
        appConfig={appConfig}
        targetType={'PROFILE'}
        events={[]} 
        likedEvents={profile.likedEvents}
        postedEvents={profile.postedEvents}
        goToEvent={goToEvent} 
        friends={profile.friends}
        messenger={messenger}
        setMessengerContext={setMessengerContext}
        myProfileId={profile.profileId}
        goToProfile={goToProfile}
        isMyProfile={isMyProfile}
        groups={profile.groups}
        channels={profile.channels}
        goToGroupDetails={goToGroupDetails}
        goToChannelDetails={goToChannelDetails}
        profile={profile}
        authUser={authUser}
        goToEventDetails={goToEventDetails}
      />

    </Container>
  )
}


const ProfileDirectorWithQuery = ({ profileId, ...props}) => {

  const variables = { request: { profileId } }
  return (
    <CachedFieldComponent
      query={SEARCH_PROFILES_QUERY}
      variables={variables}
      cache={profileList}
      find={profileId}
      render={(cacheProps) => (
        <ProfileDirector
          {...props}
          isMyProfile={false}
          profile={cacheProps}
        />
      )}
    />
  )
}

const ProfileDirectorWithData = ({ profile, ...props}) => {

  const myProfileData = useReactiveVar(myProfile.reactive)

  const selectedProfileId = profile && profile.profileId

  if (!selectedProfileId && !myProfileData) {
    return (<LoadingComponent />)
  }
  
  const isMySelectedProfile = ( !isDefined(selectedProfileId) && isDefined(myProfileData) ) 
  
  if (isMySelectedProfile) {
    return (
      <ProfileDirector
          {...props}
          myProfileData={myProfileData}
          isMyProfile={true}
          profile={myProfileData}
        />
    )
  } else {
    return (
      <ProfileDirectorWithQuery
        {...props}
        myProfileData={myProfileData}
        profileId={selectedProfileId}
      />
    )
  }

}

export default ProfileDirectorWithData
