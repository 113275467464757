import React from 'react'
import { getInitials } from '../../shared/shared-functions/get-initials'
import { ThumbnailImageContainer, ThumbnailInitial } from '../comments-component/style'
import S3ImageComponent from '../s3-image-component'

const ThumbnailImageComponent = ({ profile, noMargin }) => {
    return (
        <ThumbnailImageContainer noMargin={noMargin} >
            { (profile.primaryImageUrl && profile.primaryImageUrl !== 'none')
                ? (<S3ImageComponent 
                        imageName={profile.primaryImageUrl} 
                        style={{ width: '2.3rem', height: '2.3rem', borderRadius: '100%'}}
                        imageStyle={{ borderRadius: '100%' }}
                        centerMargin
                    />)
                : (<ThumbnailInitial >
                    { getInitials(profile) }
                </ThumbnailInitial>)
            }
        </ThumbnailImageContainer>
    )
}

export default ThumbnailImageComponent
