import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Container, Col, Row, Collapse } from 'reactstrap'
import { sharedFaqList } from './faq'


const FaqItem = ({ item }) => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)

    return (
        <Row>
            <Col xs={12} onClick={toggle} style={{ cursor: 'pointer' }} className="p-3" >
                <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} className="mr-3" />
                { item.title }
            </Col>
            <Collapse isOpen={isOpen} >
                { item.link && (
                    <Col xs={12}  className="mb-3" >
                        <a href={item.link.url} target="_blank" rel="noopener noreferrer" >{ item.link.title }</a>
                    </Col>
                )}

                { item.description && (
                    <Col xs={12}  className="mb-3" >
                        { item.description }
                    </Col>
                )}
                { item.list && (
                    <Col xs={12} >
                        { item.list.map((listItem, index) => (
                            <Row>
                                <Col xs={12} >
                                    { `${index + 1}) ${listItem}` }
                                </Col>
                            </Row>
                        )) }
                    </Col>
                )}

            </Collapse>
        </Row>
    )
}



const FaqPage = ({ appConfig }) => {

    const { sharedItems, faqItems } = appConfig.faq


    const itemsToShow = [
        ...sharedFaqList.filter(i => sharedItems.includes(i.key)),
        ...faqItems
    ]


    return (
        <Container>
            <h1>Frequently Asked Questions</h1>

            { itemsToShow.map( item => (<FaqItem item={item} />) ) }

        </Container>
    )
}

export default FaqPage

