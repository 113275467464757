import { gql } from '@apollo/client'

export const ALL_MESSENGER_CONTEXTS_QUERY = gql`
  query getAllMessengerContexts($request: AllMessengerContextsRequest) {
    getAllMessengerContexts(request: $request) {
        results {
            contextType
            contextHash
            targetId
            displayName
            messages {
                displayName
                text
                profileId
                contextType
                contextHash
                createdAt
                modifiedAt
                timestampUnix
            }
        }
    }
  }
`
