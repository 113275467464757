import styled from 'styled-components'


export const EventContainer = styled.div`

    margin: 0 1.6rem;

`;


export const EventTitleBarContainer = styled.div`
    padding: 1rem;
    display: flex;
    text-align: left;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    margin-bottom: 1rem;
`;


export const EventSidebarContainer = styled.div`
    padding: 1rem;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };

    margin-right: 1rem;
`;



export const EventPeerCount = styled.div`

    padding: 1rem;
    white-space: nowrap; 
`;



export const EventInteractionContainer = styled.div`
    // margin-left: 1rem;
`;

export const EventInteractionHeaderContainer = styled.div`
    padding: 1rem;
    display: flex;
    text-align: left;
    background-color: ${({theme}) => theme.components.gallery.backgroundColor };
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    margin-bottom: 1rem;
`;

