import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { LIKE_ACTION_MUTATION } from './mutations'
import { getAppId } from '../../utils/brand-silo'
import { myProfile } from '../../shared/cache'

const doesExist = (str) => (str !== null && str !== undefined && str !== '')

const LikeComponent = ({ targetId, targetType }) => {
  const profile = myProfile.reactive()
  let isTargetLiked = false
  if(profile && profile.profileId) {
    const likedTarget = profile.allLikes.find(l => l.targetId === targetId)
    isTargetLiked = likedTarget ? true : false
  }

  const [isLiked, setIsLiked] = useState(isTargetLiked)

  const [likeAction] = useMutation(LIKE_ACTION_MUTATION)

  const triggerLikeAction = (targetId, targetType) => async () => {
    const newValue = !isLiked
    setIsLiked(newValue)

    const appId = getAppId()

    const variables = {
      request: {
        appId,
        targetId,
        targetType,
        value: newValue,
      },
    }

    likeAction({ variables })
  }

  const idToken = localStorage.getItem('@TESSERACT/ID_TOKEN')
  const accessToken = localStorage.getItem('@TESSERACT/ACCESS_TOKEN')

  const hasProfile = doesExist(idToken) && doesExist(accessToken)

  if (hasProfile) {
    return (
      <FontAwesomeIcon color="primary" className="cursor-pointer" icon={isLiked ? faHeart : farHeart} onClick={triggerLikeAction(targetId, targetType)} size="2x" />
    )
  }
  return null
}

export default LikeComponent
