

export const updateArrayItem = (cache, item) => {

    const { primaryKey, reactive } = cache
    const otherRecords = reactive().results.filter(r => r[primaryKey] !== item[primaryKey] )

    const newState = {
        results: [
            ...otherRecords,
            item
        ]
    }

    reactive(newState)
}

const getArray = (cache) => {

    const init = cache()

    if (init === null) {
        return { results: [] }
    } else {
        return init
    }
}

export const updateArrayItems = (cache, items) => {

    const { primaryKey, reactive } = cache
    const itemKeys = items.map(i => i[primaryKey])
    const cacheArray = getArray(reactive)

    const otherRecords = cacheArray.results.filter(r => !itemKeys.includes(r[primaryKey]) )

    const newState = {
        results: [
            ...otherRecords,
            ...items
        ]
    }

    reactive(newState)
}
