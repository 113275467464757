export const NextStepAppConfig = {
    appId: 'NEXTSTEP',
    name: 'NextStep',
    logo: 'NextStep',
    appUrl: 'NextStep.app',
    favicon: 'tesseract-favicon.ico',
    pages: [
      'LANDING',
      // 'EXPLORE',
      'PROFILE',
    //   'MESSENGER',
    ],
    type: 'SINGLE',
    forceSso: true,
    landing: {
      page: 'LANDING',
      header: 'TRACK YOUR SUCCESS',
      subHeader: 'Building you up for the future',
      cta: {
        primary: {
          text: 'GET STARTED',
          target: 'PROFILE',
          type: 'FUNCTION'
  
        },
        secondary: {
          text: 'LEARN MORE',
          target: 'EVENT',
        },
      },
      items: [
        {
          type: 'CONTACT',
          id: 'contact-us',
          isInline: true,
          email: true,
          company: false,
          body: false
        },
        {
          type: 'FOOTER',
          id: 'footer',
        },
      ]
    },
    pageConfigs: [
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [
            'EXPERIENCE',
            'POSTS',
            // 'EVENTS'
            'FRIENDS',
            'GROUPS',
        ],
      },
      {
        key: 'EXPLORE',
        isAuthorized: false,
        tabs: [],
      },
      {
        key: 'EVENT',
        isAuthorized: true,
        tabs: [],
      },
      {
        key: 'GROUP',
        isAuthorized: true,
        hasEvent: true,
        tabs: [],
      },
    ],
    event: {
      type: 'SINGLE_APP',
      eventId: '2021',
      name: 'NextStep',
      description: 'Setting you up for the future',
      showHeader: false,
      maxPeers: 1000000,
      maxPartySize: 9,
      viewerTypes: {
        allowSolo: true,
        allowParty: true,
        allowFrontRow: false,
        allowHost: false
      }
    },
    theme: {
      components: {
        page: {
          backgroundColor: 'secondary',
          altBackgroundColor: 'secondary',
        },
        navBar: {
          backgroundColor: 'primary',
          logoColor: 'secondary',
          thumbnailContainerColor: 'quinary',
          iconColor: 'primary',
          iconBackgroundColor: 'secondary',
          borderColor: 'quaternary',
        },
        sideBar: {
          fontColor: 'secondary',
          backgroundColor: 'quinary',
          itemComponentColorPassive: 'quinary',
          itemComponentColorActive: 'primary',
          itemBackgroundColorPassive: 'quinary',
          itemBackgroundColorActive: 'quinary',
          itemColorPassive: 'secondary',
          itemColorActive: 'secondary',
        },
        hero: {
          titleColor: 'quaternary',
          descriptionColor: 'tertiary',
          bottomBackgroundColor: 'secondary',  
          badgeBackgroundColor: 'secondary',
          badgeFontColor: 'tertiary',
          borderColor: 'senary',
          profileImgBackgroundColor: 'octonary',
          profileImgColor: 'secondary'
        },
        gallery: {
          backgroundColor: 'secondary',
          borderColor: 'senary',
          tileLowerBackgroundColor: 'quinary',
          viewCountColor: 'quaternary',
          menuItemColorPassive: 'quaternary',
          menuItemColorActive: 'tertiary',
          menuItemBadgeBackgroundColorPassive: 'quaternary',
          menuItemBadgeBackgroundColorActive: 'tertiary',
          menuItemBadgeColorPassive: 'tertiary',
          menuItemBadgeColorActive: 'secondary',
          menuItemAccent: 'primary'
        }
      },  
      color: {
        primary: '#667EBE', //primary blue
        secondary: '#f7f7f7', // offwhite
        tertiary: '#69747C', // slate
        quaternary: '#545454', // dark gray
        quinary: '#90aaf0', // light primary
        senary: '#667EBE34', // light blue grey
        septenary: '#cf1b3034', // primary w transparency
        octonary: '#7D838699', // grey w transparency
        nonary: '#8666AD', // purple
      },
      type: {
        primary: 'Oswald',
        secondary: 'Oswald',
      },
    },
  }
  