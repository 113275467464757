import { gql } from '@apollo/client'

export const GET_COMMENTS_QUERY = gql`
  query getComments($request: GetCommentsRequest) {
    getComments(request: $request) {
        results {
            commentId
            profileId
            appId
            entityId
            entityType
            text
            threadId
            isPinned
            createdAt
            displayName
            primaryImageUrl
            likeCount
            dislikeCount
            myLikeAction
        }
        metadata {
            count
            skip
            limit
        }
    }
  }
`
