import React, { useEffect, useState } from 'react' 
import { Row, Col, Button } from 'reactstrap'
import { getAppId } from '../../utils/brand-silo'
import { generateUrlPath } from '../../utils/routing'
import { mySession } from '../../shared/cache'
import { useReactiveVar } from '@apollo/client'

const ConsentFooter = () => {

    const reactive = useReactiveVar(mySession.reactive)

    const { appData: appConfig } = reactive

    const style = {
        display: "fixed",
        position: "absolute",
        bottom: "0",
        width: "100%",
        zIndex: 1000
    }

    const appId = getAppId()
    const CONSENT_IDENTIFIER = `@${appId}/CONSENT`
    const TRUE_IDENTIFIER = 'TRUE'

    const [hasConsent, setConsent] = useState(localStorage.getItem(CONSENT_IDENTIFIER))

    useEffect(() => {
        const consent = localStorage.getItem(CONSENT_IDENTIFIER) === TRUE_IDENTIFIER
        setConsent(consent)
    }, [CONSENT_IDENTIFIER])


    const consentHandler = () => {
        localStorage.setItem(CONSENT_IDENTIFIER, TRUE_IDENTIFIER)
        setConsent(true)
    }

    const ServiceLink = ({ page, verbiage }) => {
        const { appId } = appConfig
        const DIR = {[page]:page}
        const termsUrl = generateUrlPath(appId, page, {}, {}, DIR)
        return (
            <a href={termsUrl} className="color-secondary" target="_blank" rel="noopener noreferrer" ><u>{ verbiage }</u></a>
        )
    }

    const TermsAndConditionsLink = () => 
        <ServiceLink page={'TERMS'} verbiage={'terms of service'} />

    const PrivacyPolicyLink = () => 
        <ServiceLink page={'PRIVACY'} verbiage={'privacy policy'} />


    if (!hasConsent) {
        return (
            <Row className="bg-primary" style={style} >
                <Col xs={12} md={6} className="px-3" >
                    <span>
                        <h5>Your privacy</h5>
                        <p>
                            We use cookies to improve your experience on our site and to show you relevant advertising. To find out more, read our updated <PrivacyPolicyLink /> and <TermsAndConditionsLink />.
                        </p>
                    </span>
                </Col>
                <Col xs={12} md={6} className="d-flex" >
                    <Button block color="secondary" className="m-auto" onClick={consentHandler}>OK</Button> 
                </Col>
            </Row>
        )
    }

    return (<></>)
}

export default ConsentFooter