import styled, { keyframes } from 'styled-components'

const topEnterAnimation = keyframes`
    0% { top: -100px }
    25% { top: 0 }
    80% { top: 0 }
    100% { top: -100px }
 `

export const ToastContainer = styled.div`
    position: fixed;
    width: 100%;
    top: -100px;
    left: 0; 
    animation-name: ${(props) => (props.toastDisplay ? topEnterAnimation : 'none')};
    z-index: ${(props) => (props.toastDisplay ? '1000' : '1')};
    animation-duration: ${(props) => (props.animationDuration ? props.animationDuration : '0s')};
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
`
