import { gql } from '@apollo/client'


export const CREATE_COMMENT_MUTATION = gql`
    mutation postComment($request: PostCommentRequest) {
        postComment(request: $request) {
            text
            commentId
            threadId
        }
    }
`
