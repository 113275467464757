export const getInitials = ( profile ) => {
    let initials = ''
    if (profile && profile.displayName) {
        let displayName = profile.displayName
        let secondInitial
        const nameSplit = displayName.split(' ')
        const firstInitial = nameSplit[0].charAt(0).toUpperCase()
        nameSplit.length > 1 ? secondInitial = nameSplit[1].charAt(0).toUpperCase() : secondInitial = ''
        initials = firstInitial + secondInitial 
    } 

    return initials 
}