import { gql } from '@apollo/client'

export const SEARCH_EVENTS_QUERY = gql`
  query searchEvents($request: SearchEventsRequest) {
    searchEvents(request: $request) {
        results {
            eventId
            profileId
            title
            description
            entityId
            streamType
            startTime
            duration
            streamUrl
            liveStreamUrl
            channelId
            profileId
            isHost
            viewCount
            isMediaUpload
            createIndex
            primaryImageUrl
            headerImageUrl
            templateId
            isAudioOnlyParty
            host {
              ipAddress
              status
              hostDetails {
                isPublic
                createArchive
                destinations {
                  Url
                  Ip
                  Port
                }
              }
            }
            viewerTypes {
              allowSolo
              allowParties 
              allowFrontRow
            }
            tier {
              productId
              priceId
              amount
              name
              description
              symbol
            }
        }
        metadata {
            count
            skip
            limit
        }
    }
  }
`
