

  const getSlugFromParams = (path, params, profile, DIRECTORS) => {
    let slug = ''
    switch (path) {
      case DIRECTORS.EVENT:
        slug = `${params.event.eventId}`
        break
      case DIRECTORS.PROFILE:
        if (params && params.profile && params.profile.profileId) {
          slug = `${params.profile.profileId}`
        } else if (profile && profile.profileId) {
          slug = `${profile.profileId}`
        }
        break
      case DIRECTORS.EXPLORE:
        if (params && params.event && params.event.eventId) {
            slug = `${params.event.eventId}`
        }
        break
      case DIRECTORS.CHANNEL:
        if (params && params.channel && params.channel.slug) {
          slug = `${params.channel.slug}`
        }
        break
      case DIRECTORS.GROUP:
        if (params && params.group && params.group.groupId) {
          slug = `${params.group.groupId}`
        }
        break
      default:
        break
    }
    return slug
  }

exports.generateUrlPath = (appId, path, params, profile, DIRECTORS) => {
    let urlPath = ''
    const loweredPath = path.toLowerCase()
    const loweredAppId = appId.toLowerCase()
    const shouldHideAppIdSlug = this.isTesseractIdOrNotDev(loweredAppId)
    const slug = getSlugFromParams(path, params, profile, DIRECTORS)
    if (shouldHideAppIdSlug) {
      urlPath = `/${loweredPath}/${slug}`
    } else {
      urlPath = `/${loweredAppId}/${loweredPath}/${slug}`
    }
    return urlPath
}

exports.isTesseractIdOrNotDev = (loweredAppId) => {
  const loweredTesseract = 'tesseract'
  const isDev = this.isDevInRuntime()
  return loweredAppId === loweredTesseract || !isDev
}

exports.isDevInRuntime = () => {
  const isDev = ( window.location.hostname === 'localhost' || window.location.href.includes('dev.tesseract.media') )
  return isDev
}