import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faUserFriends,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { ALL_MESSENGER_CONTEXTS_QUERY } from './queries'
import { getAppId } from '../../utils/brand-silo'
import { currentMessengerContext, myMessengerContexts } from '../../shared/cache'
import CachedFieldComponent from '../../shared/cache/helpers/CachedFieldComponent'

const MessengerContextIcon = ({ type, className }) => {
  switch (type) {
    case 'PEER':
      return <FontAwesomeIcon className={className} icon={faUser} />
    case 'GROUP':
      return <FontAwesomeIcon className={className} icon={faUserFriends} />
    default:
      return <FontAwesomeIcon className={className} icon={faUserFriends} />
  }
}


const MessengerListModal = ({ isOpen, toggle, data }) => {

  const openMessengerContext = (cxt) => () => {
    console.log({ cxt })
    currentMessengerContext.reactive(cxt)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Messenger</ModalHeader>
      <ModalBody>
        <ListGroup>
          {data.results.length === 0 ? (
            <div>No messages</div>
          ) : (
            data.results.map((mc) => (
              // <ListGroupItem onClick={getFriendMessengerContext(mc)}>
              <ListGroupItem onClick={openMessengerContext(mc)}>
                <Row>
                  <Col xs={11}>
                    <Row>
                      <Col xs={12}>
                        <MessengerContextIcon
                          className="mr-2"
                          type={mc.contextType}
                        />
                        <b>{mc.displayName}</b>
                      </Col>
                      <Col xs={12}>{mc.preview}</Col>
                    </Row>
                  </Col>
                  <Col className="d-flex" xs={1}>
                    <FontAwesomeIcon
                      className="my-auto"
                      icon={faChevronRight}
                    />
                  </Col>
                </Row>
              </ListGroupItem>
            ))
          )}
        </ListGroup>
      </ModalBody>
    </Modal>
  )
}

const MessengerListModalWithData = (props) => {

  const variables = {
    request: {
      appId: getAppId(),
    },
  }

  return (
    <CachedFieldComponent
      query={ALL_MESSENGER_CONTEXTS_QUERY}
      variables={variables}
      cache={myMessengerContexts}
      showLoading={false}
      render={(cacheProps) => (
        <MessengerListModal
          {...props}
          data={cacheProps}
        />
      )}
    />
  )

}


export default MessengerListModalWithData
