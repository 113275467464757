import { gql } from '@apollo/client'

export const INIT_EVENT_SESSION = gql`
  mutation initEventSession($request: InitEventSessionRequest) {
    initEventSession(request: $request) {
        channel {
            channelArn
        }
        profile {
            displayName
            modifiedAt
            timestampUnix
            userId
            status
            userName
            createdAt
            appId
            interest
            profileId
            friends {
                displayName
            }
            groups {
                displayName
            } 
        }
        user {
            userAgent
            modifiedAt
            ipAddress
            userType
            lastName
            createdAt
            email
            firstName
            hash
            timestampUnix
            userId
            cognitoId
            isNewUser 
        }
        tokens {
            idToken
            accessToken
        }
        eventDetails {
          partyId
          peers {
              profileId
              displayName
              channelArn
          }
          parties {
              partyId
              peers {
                  profileId
                  displayName
              }
          }
        }
    }
  }
`

export const DISCONNECT_EVENT_SESSION = gql`
  mutation disconnectEventSession($appId: String) {
    disconnectEventSession(appId: $appId)
  }
`
