export const TesseractTVAppConfig = {
    appId: 'TV',
    name: 'Tesseract TV',
    logo: 'TESSERACT.tv',
    appUrl: 'tesseract.tv',
    favicon: 'tv-favicon.ico',
    pages: [
      'LANDING',
      'PROFILE',
      'EXPLORE',
      'MESSENGER',
      'FAQ'
    ],
    type: 'PROFILE_APPS',
    forceSso: true,
    allowStreams: true,
    landing: {
      page: 'LANDING',
      header: 'MEDIA TRANSCENDING DIMENSIONS',
      subHeader: 'Creating digital experiences that could never happened naturally',
      cta: {
        primary: {
          text: 'Explore',
          type: 'FUNCTION',
          color: 'primary'
        },
        // secondary: {
        //   text: 'Learn More',
        // },
      },
      items: [
        {
          type: 'COLUMN',
          tag: 'THE FEATURES',
          title: 'What is Tesseract Media?',
          id: 'what-is-it',
          items: [
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/content.png',
                header: 'Content',
                description: 'Whether you make free or premium content, this is the place to keep it. This is an all-in-one platform which provide the capabilities to host free and public content side by side with subscriber-based premium content. Forget stringing together a half dozen apps for hosting, monetization, marketing, and more, Tesseract TV has it all.',
                showBorder: true
              },
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/video_conference.png',
                header: 'Experience',
                description: 'We\'re turning content into events by bringing the audience together and closer to the content creator. This is done through a super chat and dynamic experiences along side the content. Live streams or video-on-demand, give every stream a viewing party. We bring the audience into the presentation to facilitate a virtual experience that feels natural.',
                showBorder: true
              },
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/channel.png',
                header: 'Channels',
                description: 'Anyone can create a channel for free and immediately gain access to the richest feature set for content creators to interact with their audiences. Monetize your content through various subscription tiers and bring your audience into your performances. You can even upgrade your channel to get its own standalone platform with custom domain and theme.',
                showBorder: true
              },
          ]
        }, 
        {
          type: 'COLUMN',
          title: 'Next Generation Virtual Events',
          id: 'virtual-events',
          items: [
              {
                // image: 'none',
                header: '',
                description: `
                Tesseract.tv is a free platform to shwowcase content and interact with audiences. Join a network of content creators and users to experience your content. Create virtual events, socialize with your audience, and monetize your content.

                There are a number of experiences that are unique to tesseract apps which allow for a new level of virtual events. Live streams, VOD, party, front-row, host controls (trivia, polling, etc).

                Virtual events are configurable to all levels of access control to meet any need. Whether a globally public application or tightly restricted access to subscribers, there is a wealth of options to meet your needs.
                `,
                showBorder: false
              },
          ]
        },  
        {
          type: 'COLUMN',
          title: '',
          id: 'solo-features',
          items: [
              {
                  image: '',
                  header: 'Solo Experience',
                  description: 'The solo viewing experience is our most basic content viewing option. With this option you can view live streams or on demand video content. In addition, you have the ability to interact in the live instant messenger. This option is available for every event, including free and premium subscription tier events.',
                  showBorder: true
              },
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/solo.png',
                header: '',
                description: '',
                showBorder: false
              },
          ]
        },  
        {
          type: 'COLUMN',
          title: '',
          id: 'party-features',
          items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/party.png',
              header: '',
              description: '',
              showBorder: false
            },
          {
              image: '',
              header: 'Party Experience',
              description: 'The party viewing experince is a virtual viewing party. Available for live streams, this feature includes everything from the solo experience, plus allows a small group of users to be in a real-time video conference while all watching a live stream. The party experience connects audience memebers to a higher degree, more similar to that of some people sitting on a couch together watching television.',
              showBorder: true
            },
        ]
        },  
        {
          type: 'COLUMN',
          title: '',
          id: 'front-row-events',
          items: [
              {
                  image: '',
                  header: 'Front Row Experience',
                  description: 'The front row viewing experience is a virtual viewing party plus more. In the front row, it is similar to the party experience but with the ability to be ingrained into the live stream. This feature virtualizes the experience of an in-person event where the performer interacts with the members of the audience in the front row. When you join the front row and the host decides to bring your party into the show, you and your party will be part of the livestream for the rest of the audience to view.',
                  showBorder: true
              },
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/front_row.png',
                header: '',
                description: '',
                showBorder: false
            },
          ]
        },
        {
          type: 'COLUMN',
          title: 'How it Works',
          id: 'how-it-works',
          items: [
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/sign_up.png',
                header: 'Make a Channel',
                description: 'Sign up to Tesseract TV, and create a channel for free. Edit your channel: pick a name, description, and imagery to suit your mission.',
                showBorder: true
              },
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/schedule.png',
                header: 'Schedule an Event',
                description: 'Create an event for your channel, pick a name, time, description, etc to best describe the nature of the event. You can upload a prerecorded video, or you can choose to livestream at the time of the event.',
                showBorder: true
              },
              {
                image: 'https://tes-media-dev.s3.amazonaws.com/content/go_live.png',
                header: 'Go Live',
                description: 'It\'s time to be the host, fire up your broadcaster, or kick back and let your uploaded video fly.',
                showBorder: true
              },
          ]
        }, 
        {
          type: 'COLUMN',
          title: '',
          id: 'host-features',
          items: [
            {
              image: 'https://tes-media-dev.s3.amazonaws.com/content/front_row.png',
              header: '',
              description: '',
              showBorder: false
            },
            {
              image: '',
              header: 'Be a Host',
              description: 'The host experience is the presenter of a live broadcasting event. If you\'ve created an event with the live stream option, you have the capability to experience this feature. You can bring a party into the front row to experience a host-audience feedback loop. Also, you can fire off polls to get real time feedback from the entire audience. Live broadcasts can be recorded and saved so that the stream can be watched later by viewers in the future.',
              showBorder: true
            },
        ]
        }, 
        {
          type: 'CONTACT',
          id: 'contact-us',
          isInline: true,
          email: true,
          company: false,
          body: false
        },
        {
          type: 'FOOTER',
          id: 'footer',
        },
      ]
    },
    faq: {
      sharedItems: [ 1, 2, 3, 4, 5, 6 ],
      faqItems: [
        
      ],
    },
    pageConfigs: [
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [
          'POSTS',
          'EVENTS',
          'FRIENDS',
          'GROUPS',
          'CHANNELS'
        ],
      },
      {
        key: 'EXPLORE',
        isAuthorized: false,
        headerText: 'Current and Upcoming Digital Events',
        tabs: [
          'EVENTS',
          'CHANNELS'
        ],
      },
      {
        key: 'EVENT',
        isAuthorized: true,
        tabs: [],
      },
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [],
      },
      {
        key: 'GROUP',
        isAuthorized: true,
        hasEvent: true,
        tabs: [],
      },
    ],
    events: [],
    theme: {
      components: {
        page: {
          backgroundColor: 'quinary',
          altBackgroundColor: 'secondary',
          backgroundTransparency: 'nonary',
        },
        navBar: {
          backgroundColor: 'secondary',
          logoColor: 'tertiary',
          thumbnailContainerColor: 'quinary',
          iconColor: 'primary',
          iconBackgroundColor: 'secondary',
          borderColor: 'senary',
        },
        sideBar: {
          fontColor: 'tertiary',
          backgroundColor: 'secondary',
          inActiveLogoBorderColor: 'senary',
          itemComponentColorPassive: 'secondary',
          itemComponentColorActive: 'nonary',
          itemBackgroundColorPassive: 'secondary',
          itemBackgroundColorActive: 'septenary',
          itemColorPassive: 'primary',
          itemColorActive: 'tertiary',
          logoColor: 'secondary',
        },
        hero: {
          titleColor: 'tertiary',
          descriptionColor: 'quaternary',
          bottomBackgroundColor: 'secondary',  
          badgeBackgroundColor: 'tertiary',
          badgeFontColor: 'octonary',
          borderColor: 'senary',
          profileImgBackgroundColor: 'quinary',
          profileImgColor: 'quaternary'
        },
        gallery: {
          backgroundColor: 'secondary',
          borderColor: 'senary',
          tileLowerBackgroundColor: 'quinary',
          viewCountColor: 'quaternary',
          menuItemColorPassive: 'quaternary',
          menuItemColorActive: 'tertiary',
          menuItemBadgeBackgroundColorPassive: 'quaternary',
          menuItemBadgeBackgroundColorActive: 'tertiary',
          menuItemBadgeColorPassive: 'tertiary',
          menuItemBadgeColorActive: 'secondary',
          menuItemAccent: 'primary'
        }
      },  
      color: {
        primary: '#42B16D', // primary green
        secondary: '#292930', // dark black
        tertiary: '#FFFFFF', // white
        quaternary: '#A7A7A7', // gray
        quinary: '#393A43', // light black
        senary: '#575757', // dark gray
        septenary: '#42B16DBE', // primary with transparency
        octonary: '#292D34', // middle black
        nonary: '#87E3AB8B', // primary with most transparency
        denary: '#42B16D20', // primary with most transparency
      },
      type: {
        primary: 'Orbitron',
        secondary: 'Oswald',
      },
    },
  }
  
  