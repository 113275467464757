import React from 'react'
import { EventSidebarContainer } from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faFlag, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'



const SidebarButton = ({ style, icon, text, action = () => {} }) => {

    return (
        <div style={style} >
            <Button className="w-100" onClick={action} style={{ padding: '2px 2px 2px 0' }} >
                <FontAwesomeIcon icon={icon} /><br />
                <span style={{ fontSize: '10px', position: 'relative', top: '-5px' }}>{ text }</span>
            </Button>
        </div>
    )
}




const ActiveEventSidebarComponent = ({ goBack, event, localMedia, enableAudio, enableVideo, toggleAudio, toggleVideo }) => {

    const showAudio = localMedia.audioStreamId !== undefined
    const showVideo = localMedia.videoStreamId !== undefined

    return (
        <EventSidebarContainer>

            <SidebarButton
                icon={faSignOutAlt}
                text="Leave"
                action={goBack}
            />

            { showVideo && (
                <SidebarButton
                    icon={enableVideo ? faVideo : faVideoSlash}
                    style={{ margin: '1rem 0'}}
                    text="On"
                    action={toggleVideo}
                />
            )}

            { showAudio && (
                <SidebarButton
                    icon={enableAudio ? faMicrophone :faMicrophoneSlash}
                    text="Muted"
                    action={toggleAudio}
                />
            )}

            <SidebarButton
                icon={faFlag}
                style={{ marginTop: '100%' }}
                text="Report"
            />


        </EventSidebarContainer>
    )
}


export default ActiveEventSidebarComponent