import { gql } from '@apollo/client'

export const GET_SUBMISSION_QUERY = gql`
  query getSubmission($request: GetSubmissionRequest) {
    getSubmission(request: $request) {
      results {
        answer {
            DECIMAL
            STRING
            BOOLEAN
            optionKey
        }
        templateId
        templateItemId
      }
      metadata {
        count
      }
    }
  }
`
