export const sharedFaqList = [
    {
        key: 1,
        title: 'How to create an account?',
        description: 'To create an account, click on the \'Log In\' button on the sidebar, then click the \'Create an account\'. Then enter your email address, password, and phone number and click \'CREATE ACCOUNT\'. At this point you will be sent a confirmation code to your email, enter that confirmation code into the text box and submit.',
    },
    {
        key: 2,
        title: 'What is a channel?',
        description: 'A channel is a public presence for media. Channels can host public videos and virtual events. Channels can have subscribers which are premium viewers of media and virtual events. A channel owner can restrict certain events to subscribers only.',
    },
    {
        key: 3,
        title: 'How to create a channel?',
        description: 'To create an channel, you must first create an account. After creating an account, you may make a channel. To do this, got to your profile, click on the \'Channels\' tab. Now at this point you should see a \'Create Channel\' button. After clicking this button, enter a name for the channel and submit.',
    },
    {
        key: 4,
        title: 'What is a virtual event?',
        description: 'A virtual event consists of a video or livestream and the live audience. During the initial live viewing, audience members can join a watch party and enjoy the company of others also watching the stream. After the live event has ended viewers can watch the video on demand and can interact with eachother using the live messenger or comments section.',
    },
    {
        key: 5,
        title: 'How to host a virtual event?',
        description: 'In order to host a virtual event, you must first complete the list of steps to be eligible to host an event. If you have chosen to use the live broadcast functionality, you have to use broadcasting software to originate the stream. We recommend the OBS (Open Broadcaster Software) which is a free broadcasting appliance. It can be downloaded from the following list.',
        list: [
            'Create an account', 
            'Create a channel', 
            'Download broadcasting software',
            'Schedule a virtual event',
            'Configure broadcasting software',
            'Start streaming'
        ],
        link: {
            title: 'Download OBS (Open Broadcaster Software)',
            url: 'https://obsproject.com/download'
        }
    },
    {
        key: 6,
        title: 'How do you configure broadcasting software?',
        description: 'In order to live stream on the platform, you must configure your broadcasting software. The following list shows how to initiate the stream and connect your broadcasting software to the event.',
        list: [
            'Schedule and create a virtual event',
            'Go to the event details page',
            'Click the \'Initiate Broadcast Host\' button (this takes up to two minutes)',
            'Copy the stream url and stream key and input them into the broadcasting software',
            'On the broadcasting software click start streaming'
        ]
    }

]