import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import DndPartyTarget from './dnd-party-target'

const PartyNavModule = ({
  party, maxPartySize, setPeerDragged, myProfileId, appConfig,
}) => {
  const goToThisParty = () => setPeerDragged({ partyId: party.partyId })

  return (
    <Col xs={12} md={6}>

      <Row>
        <Col xs={12}>
          <DndPartyTarget partyId={party.partyId} name={party.name} myProfileId={myProfileId} peers={party.peers} maxPartySize={maxPartySize} setPeerDragged={setPeerDragged} goToThisParty={goToThisParty} appConfig={appConfig} />
        </Col>
      </Row>

    </Col>
  )
}

const EventNavigationComponent = ({
  isOpen, toggle, eventId, currentPartyId, myProfileId, appConfig, parties, goToSpecifiedParty,
}) => {
  // const MAX_PARTIES = 8
  const MAX_PARTY_SIZE = 6

  // const getVirtualParties = (availableParties) => Array.from(Array(MAX_PARTIES).keys()).map((key) => {
  //   const partyId = `0${key}`
  //   const currentParty = availableParties.find((p) => p.partyId === partyId)
  //   return {
  //     key,
  //     partyId,
  //     name: `Party ${key}`,
  //     peers: (currentParty && currentParty.peers) ? currentParty.peers : [],
  //   }
  // })

  const mapParties = (availableParties) => availableParties.map((p, key) => {
    const { partyId } = p
    const currentParty = availableParties.find((p) => p.partyId === partyId)
    return {
      key,
      partyId,
      name: `Party ${key}`,
      peers: (currentParty && currentParty.peers) ? currentParty.peers : [],
    }
  })

  // const virtualParties = getVirtualParties(parties)
  const virtualParties = mapParties(parties)

  console.log({ parties, virtualParties })


  if (!isOpen) {
    return <></>
  }

  const setPeerDragged = (dragResults) => {
    const { partyId: newPartyId } = dragResults
    if (currentPartyId === newPartyId) {
      return
    }
    goToSpecifiedParty(newPartyId)
    toggle()
  }

  const isMobile = window.innerWidth < 768

  return (
    <Row
      style={{
        position: 'fixed', width: '90vw', height: '90vh', left: '5vw', zIndex: 10000, borderRadius: '15px',
      }}
      className="bg-secondary p-5"
    >
      <Col xs={12} className="text-center">

        <p className="d-flex">
          <strong className="color-secondary">
            Group Event Navigator
          </strong>
          <Button color="tertiary" className="ml-auto" onClick={toggle}><FontAwesomeIcon icon={faTimes} /></Button>

        </p>

        <DndProvider backend={HTML5Backend}>
          <Row style={{ overflow: 'scroll', height: isMobile ? '65%' : '85%' }}>
            { virtualParties && virtualParties.map((party) => <PartyNavModule party={party} maxPartySize={MAX_PARTY_SIZE} myProfileId={myProfileId} setPeerDragged={setPeerDragged} appConfig={appConfig} />)}
          </Row>
        </DndProvider>

      </Col>

    </Row>
  )
}

export default EventNavigationComponent
