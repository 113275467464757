import { ApolloClient, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error";
import { v4 as uuidv4 } from 'uuid'
import { getAppId } from '../utils/brand-silo'
import { cache } from '../shared/cache'

const ENV_BFF_URL = process.env.REACT_APP_TES_SLS_BFF_URL
const GRAPHQL_URI = `${ENV_BFF_URL}/graphql`

const getLocalStorageItem = (key) => {
  const value = localStorage.getItem(key)
  if (value !== null && value !== undefined) {
    return value
  }
  return ''
}

const getHeaders = () => ({
  'Content-Type': 'application/json',
  'x-tesseract-id': getLocalStorageItem('@TESSERACT/ID_TOKEN'),
  'x-tesseract-access': getLocalStorageItem('@TESSERACT/ACCESS_TOKEN'),
  'x-correlation-id': uuidv4(),
  'x-session-id': uuidv4(),
  'x-app-id': getAppId(),
  'x-user-agent': navigator.userAgent,
})

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...getHeaders(),
  },
}))

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )
  }

  if (networkError) {
    console.log(`[Network error]: Error: ${networkError}`)  
    if (!window.location.href.includes('localhost')) {
      window.location.reload()
    }
  }

})

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
})

export default client
