import React, { useEffect, useRef } from 'react'
import { ChatFeed, Message } from 'react-chat-ui'
import { Button } from 'reactstrap'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { MESSENGER_CONTEXT_QUERY } from './queries'
import { SEND_MESSAGE_MUTATION } from './mutations'
import { getAppId } from '../../utils/brand-silo'
import BaseFormComponent from '../../shared/base-form'
import { MessagengerContainer, MessagengerListContainer } from './styles'
import { currentMessengerContext, myMessengerContexts, myProfile } from '../../shared/cache'
import CachedFieldComponent from '../../shared/cache/helpers/CachedFieldComponent'
import { updateArrayItem } from '../../shared/cache/util'


const mapMessages = (msgs, profile) => {
  const mapped = msgs.slice()
    .sort((a, b) => a.timestampUnix - b.timestampUnix)
    .map((m, index) => {
      const { profileId } = profile
      const sentByMe = profileId === m.profileId
      const id = sentByMe ? 0 : index + 1
      const newMessage = {
        id,
        message: m.text,
        senderName: id !== 0 ? m.displayName : undefined,
      }
      return new Message(newMessage)
    })
  return mapped
}

const MessengerListComponent = ({ messages, profile, containerRef }) => {

  const strongMessages = messages && messages.length
    ? mapMessages(messages, profile) : []

  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  return (
      <ChatFeed
        maxHeight={500}
        messages={strongMessages}
        hasInputField={false}
        showSenderName
        bubblesCentered={false}
      />
  )
}

const MessengerListComponentWithData = ({ contextHash, variables, profile }) => {
  
  const containerRef = useRef()
  return (
  <MessagengerListContainer ref={containerRef} >
    <CachedFieldComponent
      query={MESSENGER_CONTEXT_QUERY}
      variables={variables}
      find={contextHash}
      required={'messages'}
      isNestedQuery={true}
      cache={myMessengerContexts}
      render={(cacheProps) => (
        <MessengerListComponent
          profile={profile}
          messages={cacheProps.messages}
          containerRef={containerRef}
        />
      )}
    />
  </MessagengerListContainer>
)}


const MessengerComponent = (props) => {

    const {
        profile, 
        context, 
        variables, 
        showHeader = true, 
        style
      } = props

  console.log({ messengerContext: context })

  const [sendMessageAction] = useMutation(SEND_MESSAGE_MUTATION)

  const toggle = () => currentMessengerContext.reactive(null)

  const sendMessage = async ({ text }) => {
    const { targetId, contextType } = context
    const appId = getAppId()
    const variables = {
      request: {
        appId,
        targetId,
        contextType,
        text,
      },
    }

    const { profileId } = profile

    const newMessengerState = {
      ...context,
      messages: [
        ...context.messages,
        { text, profileId },
      ],
    }

    updateArrayItem(myMessengerContexts, newMessengerState)

    return sendMessageAction({ variables })
  }


  const messengerStyle = {
    zIndex: 10000,
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    borderRadius: '5px',
    maxWidth: '350px',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    ...style
  }

  const formDetails = {
    isInline: true,
    showErrors: false,
    fields: {
      text: {
        dataType: 'STRING',
        type: 'text',
        name: 'text',
        label: 'Email',
        isComment: true,
        showLabel: false,
        autoComplete: 'off',
        defaultValue: '',
        isRequired: true,
        minLength: 1,
        maxLength: 250,
        xs: 12,
      },
    },
    submit: {
      isBlock: true,
      canReSubmit: true,
    },
  }

  return (
    <MessagengerContainer style={messengerStyle}  >

      { showHeader && (
        <div className="d-flex">
          <span className="m-2 mr-auto">
            { context.displayName }
          </span>

          <Button onClick={toggle}><FontAwesomeIcon icon={faTimes} /></Button>

        </div>
      )}

      <MessengerListComponentWithData
        profile={profile}
        contextHash={context.contextHash}
        variables={variables}
      />

      <BaseFormComponent form={formDetails} submit={sendMessage} />

    </MessagengerContainer>

  )
}

const InlineMessengerComponentWithData = (props) => {


  const { currentContext } = props


  
  if (currentContext === null) {
    return (<></>)
  }

  const appId = getAppId()
  const { contextHash, targetId, contextType } = currentContext
  const variables = {
    request: {
      appId,
      targetId,
      contextType,
      contextHash,
    },
  }

  return (
    <CachedFieldComponent
      query={MESSENGER_CONTEXT_QUERY}
      variables={variables}
      find={contextHash}
      cache={myMessengerContexts}
      showLoading={false}
      initialState={currentContext}
      render={(cacheProps) => (
        <MessengerComponent
          {...props}
          context={cacheProps}
          profile={myProfile.reactive()}
          variables={variables}
        />
      )}
    />
  )
}

export default InlineMessengerComponentWithData
