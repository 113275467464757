import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube, faScroll } from '@fortawesome/free-solid-svg-icons'
import Tesseract from '../../animations/tesseract'
import DotsAnimation from '../../animations/dots'
import ColumnComponent from '../../shared/landing/column-component'
import ContactComponent from '../../shared/landing/contact-component'
import FooterComponent from '../../components/footer'
import { resolveCta } from '../../shared/landing/utils'
import { HeroBackground, HeroHeaderText } from './styles'


const determineHeroItem = (appId) => {
  const isLarge = window.innerWidth > 991
  const isXLarge = window.innerWidth > 1200

  let mockupStyle = {}

  if (isXLarge) {
    mockupStyle = {
      width: '69%', position: 'absolute', left: '81px', zIndex: '10', top: '137px',
    }
  } else if (isLarge) {
    mockupStyle = {
      width: '69%', position: 'absolute', left: '67px', zIndex: '10', top: '113px',
    }
  } else {
    mockupStyle = {
      width: '67%', position: 'absolute', left: '54px', zIndex: '10', top: '81px',
    }
  }

  switch (appId) {
    case 'HOTBOX':
      return (
        <>
          <img width="100%" alt="mockup" src="./mbp-mockup.png" />
          <img width="100%" alt="hotbox-animation" style={mockupStyle} src="./gif-hotbox-french-cropped.gif" />
        </>
      )
    case 'SPEAKEASY':
    case 'YOTTA':
      return <DotsAnimation />
    case 'TESSERACT':
    case 'TV':
      return <Tesseract addPadding />
    case 'NEXTSTEP':
      return <FontAwesomeIcon className="my-3 hero-item" size="10x" icon={faScroll} />
    default:
      return <FontAwesomeIcon className="my-3 hero-item" size="10x" icon={faCube} />
  }
}

const LandingPage = ({ appConfig, goToCta, authUser, current, setNotification, goTo }) => (
  <>
    <HeroBackground>
      <Container style={{ minHeight: '80vh' }} >
        <Row>
          <Col xs={12} className=" mt-5">
            <Row style={{ minHeight: '50vh' }}>
              <Col xs={12} sm={6} className="text-center d-flex">

                <Row className="m-auto">
                  <Col>
                    { determineHeroItem(appConfig.appId) }
                  </Col>
                </Row>

              </Col>
              <Col xs={12} sm={6} className="d-flex">
                <Row className="my-auto font-secondary pt-4">
                  <Col>

                    <Row className="mb-3">
                      <Col xs={12}>
                          <p >
                              &mdash; <span className="ml-2" style={{ fontWeight: 800, fontSize: '14px' }} >{ appConfig.name }</span>
                          </p>
                          <HeroHeaderText>
                            { appConfig.landing.header }
                          </HeroHeaderText>
                      </Col>
                      <Col xs={12}>
                        <span style={{ fontSize: '22px' }}>{ appConfig.landing.subHeader }</span>
                      </Col>
                    </Row>
                    <Row className="mt-5 mb-3">
                      { appConfig.landing.cta.secondary && (
                        <Col xs={{ size: 6}}>
                          { resolveCta(appConfig.landing.cta.secondary) } 
                        </Col>
                      )}
                      <Col xs={appConfig.landing.cta.secondary ? 6 : { size: 6, offset: 3 }}>
                        { resolveCta(appConfig.landing.cta.primary, goToCta) } 
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </HeroBackground>

    { appConfig.landing.items && 
        appConfig.landing.items.map((item, index) => {
          return (
            <>
              { item.type === 'COLUMN' && <ColumnComponent details={item} index={index} goTo={goTo} /> }
              { item.type === 'CONTACT' && <ContactComponent details={item} index={index} setNotification={setNotification} /> }
              { item.type === 'FOOTER' && (
                  <FooterComponent appConfig={appConfig} authUser={authUser} page={current.path} setNotification={setNotification} index={index} /> 
              )}
            </>
          )
      })
    }


  </>
)

export default LandingPage
