import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation, useQuery } from '@apollo/client'
import { EVENT_EDITS_MUTATION } from './mutations'
import { SEARCH_CHANNELS_QUERY } from '../orchestrator/director/channel-director/queries'
import { GET_SESSION_REQUEST } from '../../utils/graphql'
import BaseFormComponent from '../../shared/base-form'
import { mySession } from '../../shared/cache'

const EditEventModal = ({
  isOpen, toggle, event, authUser
}) => {

const [editEvent] = useMutation(EVENT_EDITS_MUTATION)

const channelId = event.channelId

const variables = {
  request: {
    channelId
  },
}

let retrievedChannel
let products = []
let tierName = 'FREE'

const { data: channelSearchResults , loading: loadingChannels } = useQuery(SEARCH_CHANNELS_QUERY, { variables })

if (loadingChannels) {
  return (<></>)
} else {
  if (channelSearchResults.searchChannels.metadata.count > 0) {
    retrievedChannel = channelSearchResults.searchChannels.results[0]
    products = retrievedChannel.products
    tierName = event.tier ? event.tier.name : 'FREE'
  }
}

const handleSubmitEventEdits = (props) => {
  const { subscriptionTierId, ...propsWithoutSubTierId } = props

  let productId = 'Free'
  if (subscriptionTierId !== 'Free') {
    const chosenTierProduct = products.find(p => p.name === subscriptionTierId)
    productId = chosenTierProduct.productId
  }

  const { startTime } = props
  const attributes = {
      ...propsWithoutSubTierId,
      productId,
      channelId,
      startTime: startTime.toString(),
      eventId: event.eventId
  }
  const variables = generateVariables(attributes)
  editEvent(variables)
  toggle()
}


const generateVariables = (attributes) => ({
    variables: {
        request: {
            ...attributes
        }
    },
    refetchQueries: [{
      ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
      fetchPolicy: 'network-only',
    }],
  })

  const tierNames = [
    'Free',
    ...(products && products.length ? products.map(p => p.name) : [])
  ]

  const { appData } = mySession.reactive()
  const allowStream = !appData.allowProfileEvents

  const editEventForm = {
    isInline: false,
    showErrors: true,
    fields: {
        title: {
            dataType: 'STRING',
            type: 'text',
            name: 'title',
            label: 'Title',
            showLabel: true,
            defaultValue: '',
            placeholder: event.title,
            isRequired: false,
            minLength: 1,
            maxLength: 250,
            xs: 12,
        },
        description: {
            dataType: 'STRING',
            type: 'text',
            name: 'description',
            label: 'Description',
            showLabel: true,
            defaultValue: '',
            placeholder: event.description,
            isRequired: false,
            minLength: 0,
            maxLength: 1000,
            xs: 12,
        },
        startTime: {
            dataType: 'STRING',
            type: 'datetime',
            name: 'startTime',
            label: 'Start Time',
            showLabel: true,
            defaultValue: new Date(event.startTime),
            isRequired: false,
            xs: 12,
        },
        duration: {
            dataType: 'NUMBER',
            type: 'number',
            name: 'duration',
            label: 'Duration (15 to 360 minutes)',
            showLabel: true,
            defaultValue: event.duration,
            isRequired: false,
            min: 1,
            max: 360,
            xs: 12,
            // customValidation: validateEventDate,
            // customValidationErrorMessage: "Events cannot overlap for broadcast host"
        },
        createIndex: {
            dataType: 'boolean',
            type: 'checkbox',
            name: 'createIndex',
            label: 'Index Event',
            showLabel: true,
            defaultValue: event.createIndex,
            isRequired: true,
            hidden: !allowStream,
            xs: 12,
            md: 5
        },
        allowSolo: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'allowSolo',
          label: 'Solo',
          showLabel: true,
          defaultValue: event.viewerTypes.allowSolo,
          hidden: true,
          isRequired: true,
          xs: 12,
          md: 5
        },
        allowParties: {
            dataType: 'boolean',
            type: 'checkbox',
            name: 'allowParties',
            label: 'Parties',
            showLabel: true,
            defaultValue: event.viewerTypes.allowParties,
            isRequired: true,
            hidden: !allowStream,
            xs: 12,
            md: 5
        },
        allowFrontRow: {
            dataType: 'boolean',
            type: 'checkbox',
            name: 'allowFrontRow',
            label: 'Front Row',
            showLabel: true,
            defaultValue: event.viewerTypes.allowFrontRow,
            isRequired: true,
            hidden: !allowStream,
            xs: 12,
            md: 5
        },
        isAudioOnlyParty: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'isAudioOnlyParty',
          label: 'Audio Party',
          showLabel: true,
          defaultValue: event.isAudioOnlyParty,
          isRequired: true,
          hidden: !allowStream,
          xs: 12,
          md: 5
        },
        subscriptionTierId: {
            dataType: 'STRING',
            type: 'select',
            name: 'subscriptionTierId',
            label: 'Subscription Type',
            showLabel: true,
            options: tierNames,
            defaultValue: tierName,
            placeholder: tierName,
            hidden: !allowStream,
            isRequired: true,
            xs: 12,
        },
        primaryImageUrl: {
            dataType: 'IMAGE',
            type: 'image',
            name: 'primaryImageUrl',
            label: 'Profile Picture',
            showLabel: true,
            defaultValue: event.primaryImageUrl,
            placeholder: event.primaryImageUrl,
            isRequired: true,
            minLength: 5,
            maxLength: 250,
            xs: 12,
        },
        headerImageUrl: {
            dataType: 'IMAGE',
            type: 'image',
            name: 'headerImageUrl',
            label: 'Banner Image',
            showLabel: true,
            defaultValue: event.headerImageUrl,
            placeholder: event.headerImageUrl,
            isRequired: true,
            minLength: 5,
            maxLength: 250,
            xs: 12,
        },
    },
    submit: {
      isBlock: true,
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Event</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <BaseFormComponent form={editEventForm} submit={handleSubmitEventEdits} />
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default EditEventModal
