import { gql } from '@apollo/client'

export const MESSENGER_CONTEXT_QUERY = gql`
  query getMessengerContext($request: MessengerContextRequest) {
    getMessengerContext(request: $request) {
      results {
        displayName
        text
        profileId
        contextType
        contextHash
        createdAt
        modifiedAt
        timestampUnix
      }
    }
  }
`
