export const YottaChatAppConfig = {
  appId: 'YOTTA',
  name: 'yotta.chat',
  logo: 'yotta.chat',
  appUrl: 'yotta.chat',
  favicon: 'yotta-favicon.ico',
  pages: [
    'LANDING',
    // 'EXPLORE',
    'PROFILE',
    'MESSENGER',
  ],
  type: 'SINGLE',
  forceSso: true,
  landing: {
    page: 'LANDING',
    header: 'really good video',
    subHeader: 'chat with your friends and stuff yotta yotta yotta',
    cta: {
      primary: {
        text: 'get started',
        target: 'PROFILE',
        type: 'FUNCTION'

      },
      secondary: {
        text: 'LEARN MORE',
        target: 'EVENT',
      },
    },
    items: [
      {
        type: 'CONTACT',
        id: 'contact-us',
        isInline: true,
        email: true,
        company: false,
        body: false
      },
      {
        type: 'FOOTER',
        id: 'footer',
      },
    ]

  },
  pageConfigs: [
    {
      key: 'PROFILE',
      isAuthorized: true,
      tabs: [
        'FRIENDS',
        'GROUPS',
      ],
    },
    {
      key: 'EXPLORE',
      isAuthorized: false,
      isDisabled: true,
      tabs: [],
    },
    {
      key: 'EVENT',
      isAuthorized: false,
      tabs: [],
    },
    {
      key: 'GROUP',
      isAuthorized: true,
      hasEvent: true,
      tabs: [],
    },
  ],
  event: {
    eventId: '',
    name: '',
    description: '',
    showHeader: false,
    maxPeers: 0,
    maxPartySize: 0,
  },
  theme: {
    components: {
      page: {
        backgroundColor: 'quinary',
        altBackgroundColor: 'secondary',
        backgroundTransparency: 'nonary',
      },
      navBar: {
        backgroundColor: 'secondary',
        logoColor: 'primary',
        thumbnailContainerColor: 'quinary',
        iconColor: 'primary',
        iconBackgroundColor: 'secondary',
        borderColor: 'quaternary',
      },
      sideBar: {
        fontColor: 'tertiary',
        backgroundColor: 'secondary',
        itemComponentColorPassive: 'secondary',
        itemComponentColorActive: 'nonary',
        itemBackgroundColorPassive: 'secondary',
        itemBackgroundColorActive: 'septenary',
        itemColorPassive: 'primary',
        itemColorActive: 'tertiary',
        logoColor: 'secondary',
      },
      hero: {
        titleColor: 'tertiary',
        descriptionColor: 'quaternary',
        bottomBackgroundColor: 'secondary',  
        badgeBackgroundColor: 'tertiary',
        badgeFontColor: 'octonary',
        borderColor: 'senary',
        profileImgBackgroundColor: 'quinary',
        profileImgColor: 'quaternary'
      },
      gallery: {
        backgroundColor: 'secondary',
        borderColor: 'senary',
        tileLowerBackgroundColor: 'quinary',
        viewCountColor: 'quaternary',
        menuItemColorPassive: 'quaternary',
        menuItemColorActive: 'tertiary',
        menuItemBadgeBackgroundColorPassive: 'quaternary',
        menuItemBadgeBackgroundColorActive: 'tertiary',
        menuItemBadgeColorPassive: 'tertiary',
        menuItemBadgeColorActive: 'secondary',
        menuItemAccent: 'primary'
      }
    },  
    color: {
      primary: '#964bf2', // primary green
      secondary: '#292930', // dark black
      tertiary: '#FFFFFF', // white
      quaternary: '#A7A7A7', // gray
      quinary: '#393A43', // light black
      senary: '#575757', // dark gray
      septenary: '#964bf2BE', // primary with transparency
      octonary: '#292D34', // middle black
      nonary: '#b57dfa8B', // primary with most transparency

    },
    type: {
      primary: 'Orbitron',
      secondary: 'Oswald',
    },
  },
}
