import React from 'react'
import { useMutation } from '@apollo/client'
import BaseFormComponent from '../../shared/base-form'
import { SUBMIT_EMAIL_MUTATION } from './mutations'
import { getAppId } from '../../utils/brand-silo'

const EmailSubmissionComponent = ({ setNotification }) => {
  const emailForm = {
    isInline: true,
    showErrors: true,
    fields: {
      email: {
        dataType: 'STRING',
        type: 'email',
        name: 'email',
        label: 'Enter email for more info',
        showLabel: true,
        defaultValue: '',
        placeholder: '',
        isRequired: true,
        minLength: 5,
        maxLength: 250,
        xs: 12,
      },
    },
    submit: {
      isBlock: true,
    },
  }

  const [submitEmail] = useMutation(SUBMIT_EMAIL_MUTATION)

  const submitHandler = async ({ email }) => {
    const appId = getAppId()
    const variables = {
      request: {
        appId,
        email,
      },
    }
    return submitEmail({ variables })
  }

  return (
    <BaseFormComponent setNotification={setNotification} form={emailForm} submit={submitHandler} />
  )
}

export default EmailSubmissionComponent
