import React, { useRef, useState } from 'react'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, Input } from 'reactstrap';

const resolveUrl = (type, item) => {

    let shareUrl = window.location.href

    if (item) {
        switch (type) {
            case 'EVENT':
                shareUrl = `https://${window.location.hostname}/${type.toLowerCase()}/${item.eventId}`
                break
            case 'CHANNEL':
                shareUrl = `https://${window.location.hostname}/${type.toLowerCase()}/${item.slug}`
                break
            case 'GROUP':
                shareUrl = `https://${window.location.hostname}/${type.toLowerCase()}/${item.groupId}`
                break
            case 'APP':
                shareUrl = item.remoteUrl
                break
            default:
                break
        }    
    }

    return shareUrl
}

const ShareModal = ({ type, render }) => {

    const inputRef = useRef()
    const [isOpen, setIsOpen] = useState(false)

    const [shareUrl, setShareUrl] = useState()

    const toggle = (item) => {
        setIsOpen(!isOpen)
        if (item) {
            setShareUrl(resolveUrl(type, item))
        } else {
            setShareUrl()
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl)
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} >
                <ModalHeader toggle={toggle}>Share URL</ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <Input ref={inputRef} disabled value={shareUrl} />
                        <InputGroupAddon addonType="append">
                            <Button color="primary" onClick={copyToClipboard}>
                                <FontAwesomeIcon icon={faClipboard} />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </ModalBody>
            </Modal>
            { render(toggle) }
        </>
    )
}


export default ShareModal
