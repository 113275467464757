import React from 'react'
import PollComponent from '../../../../poll-component'

const EventSidebar = (props) => {

    const {
        pollDetails
    } = props

    const showPoll = pollDetails && (Object.keys(pollDetails).length > 0)

    return (
        <>
            { showPoll &&
                <PollComponent pollDetails={pollDetails} />
            }
        </>
    )
}

export default EventSidebar