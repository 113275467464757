import { gql } from '@apollo/client'

export const GET_SESSION = gql`
  query getAppSession($request: AppSessionRequest) {
    getAppSession(request: $request) {
      appConfig {
        key
        type
      }
      profile {
        displayName
        modifiedAt
        timestampUnix
        userId
        status
        userName
        createdAt
        appId
        interest
        location
        profileId
        primaryImageUrl
        headerImageUrl
        attributes {
          attributeId
          entityId
          entityType
          type
          isVerified
          primaryImageUrl
          title
          position
          startDate
          endDate
          description
        }
        allLikes {
          targetId
          targetType
        }
        friends {
          displayName
          appId
          profileId
          status
          interest
          isOnline
          primaryImageUrl
          headerImageUrl
        }
        groups {
          displayName
          groupId        
          appId
          ownerId
          description
          primaryImageUrl
          headerImageUrl
          createdAt
          modifiedAt
          members {
            displayName
            isActive
            isAccepted
            targetType
            appId
            profileId
            membershipId
            userId
            status
            userName
            interest
            isOnline
            currentPartyId
            primaryImageUrl
            headerImageUrl
          }
        } 
        events {
          eventId
          profileId
          title
          description
          entityId
          streamType
          startTime
          duration
          channelId
          headerImageUrl
          primaryImageUrl
          createArchive
        }
        likedEvents {
          eventId
          profileId
          title
          description
          entityId
          streamType
          startTime
          duration
          channelId
          headerImageUrl
          primaryImageUrl
          createArchive
        }
        postedEvents {
          eventId
          profileId
          title
          description
          entityId
          streamType
          startTime
          duration
          channelId
          headerImageUrl
          primaryImageUrl
          createArchive
        }
        channels {
          channelId
          profileId
          appId
          displayName
          affiliateGroupId
          slug
          primaryImageUrl
          headerImageUrl
        }
        hosts {
          channelId
          hostId
          status
          appId
          profileId
          type
        }
      }
      user {
        userAgent
        modifiedAt
        ipAddress
        userType
        lastName
        createdAt
        email
        firstName
        hash
        timestampUnix
        userId
        cognitoId
        isNewUser 
      }
      events {
        eventId
        profileId
        title
        description
        entityId
        streamType
        startTime
        duration
        channelId
      }
      tokens {
        idToken
        accessToken
      }  
    }
  }
`
