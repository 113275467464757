import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from '../../../styles/global'
import { getCurrentBrandConfig } from '../../../utils/brand-silo'

const AppConfigProvider = ({ render }) => render(getCurrentBrandConfig())

const ThemedApp = ({ render }) => (
  <AppConfigProvider render={(appConfig) => (
    <ThemeProvider theme={appConfig.theme}>
      <GlobalStyles />
      { render() }
    </ThemeProvider>
  )}
  />
)

export default ThemedApp
