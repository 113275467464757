import React from 'react'
import BaseHeroComponent from '../base-hero-component'

const GroupHeroComponent = ({ group, primaryRender, secondaryRender }) => (
  <BaseHeroComponent
    name={group.displayName}
    targetType={"GROUP"}
    targetId={group.groupId}
    imageUrl={group.primaryImageUrl}
    headerImageUrl={group.headerImageUrl}
    isLiked={false}
    showLikeToggle={false}
    primaryRender={primaryRender}
    secondaryRender={secondaryRender}
    labelText={"Group"}
  />
)

export default GroupHeroComponent
