import moment from 'moment'
import { addMinutes, doOverlap } from './datetime' 

const doesExist = (item) => (item !== null && item !== undefined)

const stringDoesExist = (str) => (doesExist(str) && str !== '')

const arrayHasItems = (arr) => (doesExist(arr) && arr.length > 0)

export const mapAppEvents = (events, shouldNullCheck) => {

  if (shouldNullCheck && !arrayHasItems(events)) {
    return []
  }

  const mapped = events.map((event, i) => {
    
    const {
      eventId, title, description, streamType, entityId, streamUrl, liveStreamUrl, profileId,
      channelId, host, startTime, duration, viewerTypes, isHost, createArchive,
      primaryImageUrl, headerImageUrl, createIndex, templateId, type, tier,isAudioOnlyParty
    } = event

    const showHeader = stringDoesExist(streamType) && stringDoesExist(entityId)

    const hosts = []

    if (host) {
      hosts.push({
        isPrimary: true,
        streamType,
        streamUrl,
        ...host
      })
    } else {
      switch (streamType) {
        case 'NATIVE':
          hosts.push({
            isPrimary: true,
            streamType,
            streamUrl,
            entityId,
            liveStreamUrl,
            name: 'Native Stream',
          })
          break
        case 'YOUTUBE':
          hosts.push({
            isPrimary: true,
            streamType,
            entityId,
            name: 'Youtube Stream',
          })
          break
        default:
          break
      }
    }
    
    const eventSetting = {
      startTime: new Date(startTime),
      endTime: addMinutes(startTime, duration)
    }

    const currentTime = new Date()
    const current = {
      startTime: currentTime,
      endTime: currentTime,
    }

    const isAfterEndTimeButHasArchive = (currentTime >= eventSetting.startTime && createArchive)
    const isLive = doOverlap(current, eventSetting)
    const allowEntry = (isLive || isAfterEndTimeButHasArchive)
    const fromNow = moment(startTime).fromNow()

    return {
      key: i,
      eventId,
      title,
      description,
      entityId,
      type,
      showHeader,
      maxPeers: 1000000,
      maxPartySize: 5,
      hosts,
      channelId,
      startTime, 
      duration,
      allowEntry,
      isLive,
      fromNow,
      viewerTypes,
      isHost,
      primaryImageUrl,
      headerImageUrl,
      profileId,
      createIndex,
      templateId,
      tier,
      isAudioOnlyParty
    }
  })
  
  return mapped
}
