import React from 'react'
import BaseHeroComponent from '../base-hero-component'

const ProfileHeroComponent = ({ profile, secondaryRender, isMyProfile }) => (
  <BaseHeroComponent
    name={profile.displayName}
    description={profile.interest}
    targetType="PROFILE"
    targetId={profile.profileId}
    imageUrl={profile.primaryImageUrl}
    headerImageUrl={profile.headerImageUrl}
    isLiked={false}
    showLikeToggle={!isMyProfile}
    secondaryRender={secondaryRender}
    labelText={profile.location}
  />
)

export default ProfileHeroComponent
