import React, { useEffect } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { updateArrayItems } from '../util'
import { myProfile, profileList } from '../index'


const isInArray = (primaryKey, array, item) => {
    return !!array.find(i => i[primaryKey] === item[primaryKey])
}

const existsWithLength = (array) => {
    return array && array.length > 0
}

const processProfileData = (profile) => {
    const profiles = []

    const { groups, friends } = profile

    if (existsWithLength(profile.groups)) {
        groups.forEach(g => {
            if (existsWithLength(g.members)) {
                g.members.forEach(m => {
                    if (!isInArray('profileId', profiles, m)) {
                        profiles.push(m)
                    }
                })
            }    
        })
    }

    if (existsWithLength(profile.friends)) {
        friends.forEach(f => {
            if (!isInArray('profileId', profiles, f)) {
                profiles.push(f)
            }
        })
    }

    updateArrayItems(profileList, profiles)
}

const refetch = (cache) => () => {
    const current = cache.reactive()
    if (current !== null) {
        cache.reactive(null)
    }
}

const NetworkQuery = ({ cognitoId, query, variables, cache }) => {
    const {data} = useQuery(query, { variables, fetchPolicy: 'network-only' })
    let shouldToggleSystemUserModal = false

    useEffect(() => {
        if (data) {
            const { getAppSession: appSession } = data
        
            if (appSession.tokens) {
                const { tokens } = appSession
                const { accessToken, idToken } = tokens
                localStorage.setItem('@TESSERACT/ACCESS_TOKEN', accessToken)
                localStorage.setItem('@TESSERACT/ID_TOKEN', idToken)
            }
    
            if (appSession.profile) {
                myProfile.reactive(appSession.profile)
                processProfileData(appSession.profile)
            } else if (cognitoId !== null && cognitoId !== undefined) {
                // eslint-disable-next-line
                shouldToggleSystemUserModal = true
            }
        
            cache.reactive({
                appData: {
                    ...cache.initial.appData,
                    ...appSession.appConfig,   
                    isLoading: false,
                    shouldToggleSystemUserModal 
                }
            })
        }    
    // eslint-disable-next-line
    }, [data])

    return (<></>)
}

const ReactiveNetworkQuery = (props) => {
    const { cache } = props
    const cacheData = useReactiveVar(cache.reactive) 

    if (cacheData === null || cacheData.appData.isLoading) {
        return (
            <NetworkQuery {...props} />
        )
    } else {
        return (<></>)
    }
}

const SessionCacheComponent = ({ cognitoId, query, variables, cache, render}) => {

    const cached = cache.reactive()

    const refetchSession = refetch(cache)

    const downProps = {
        ...cached,
        refetchSession
    }

    return (
        <>
            <ReactiveNetworkQuery
                cognitoId={cognitoId}
                query={query}
                variables={variables}
                render={render}
                cache={cache}
            />
            { render(downProps) }
        </>
    )
}

export default SessionCacheComponent
