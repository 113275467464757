import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import BaseFormComponent from '../../shared/base-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Col, Spinner  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft  } from '@fortawesome/free-solid-svg-icons'
import { SUBSCRIBE_USER_TO_CHANNEL_MUTATION, UNSUBSCRIBE_USER_TO_CHANNEL_MUTATION } from './mutations'
import { GET_SESSION_REQUEST } from '../../utils/graphql'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';


const CardInput = (props) => {
    const CARD_ELEMENT_OPTIONS = {
        style: {
          base: {
            'color': '#fff',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '18px',
            '::placeholder': {
              color: '#fff',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      }
      
    return (
        <CardElement options={CARD_ELEMENT_OPTIONS} />
    )
}

const SubscribeToChannelModal = ({ isOpen, toggle, channelId, products, displayName, profileId, setIsSubscribed, authUser, isSubscribed }) => {
    const [subscribeUserToChannel] = useMutation(SUBSCRIBE_USER_TO_CHANNEL_MUTATION)
    const [unsubscribeUserToChannel] = useMutation(UNSUBSCRIBE_USER_TO_CHANNEL_MUTATION)
    const [showPickText, setShowPickText] = useState(false)
    const [chosenLevel, setChosenLevel] = useState(null)
    const [showInitial, setShowInitial] = useState(true)
    const [showSpinner, setShowSpinner] = useState(false)
    
    const subscribeToChannelMutation = () => {
        if(chosenLevel === null){
            setShowPickText(true)
            return
        }

        setShowInitial(false)
    }

    const unsubscribeUserAndToggleModal = () => {
        const variables = generateCancelSubscriptionVariables(channelId)
        unsubscribeUserToChannel(variables)
        toggle()
        setIsSubscribed(false)
        goBackToTierList()
    } 

    const goBackToTierList = () => {
        setShowInitial(true)
        setChosenLevel(null)
    }

    const tierLevelOverrides = [
        {
            name: 'Base Level',
            amount: 1.99,
            symbol: '$',
            priceId: 'price_1Hv6cBDoDwqR9NKH8AYxehKe',
        },
        {
            name: 'Plus Level',
            amount: 3.99,
            symbol: '$',
            priceId: 'price_1Hv6cBDoDwqR9NKH8AYxehKe',
        }
    ]

    const tierLevels = ( products && products.length > 0)
        ? products.slice().sort((a,b) => a.amount - b.amount )
        : tierLevelOverrides


    const generateCancelSubscriptionVariables = (channelId) => ({
    variables: {
        channelId
    },
    refetchQueries: [{
        ...GET_SESSION_REQUEST({ cognitoId: authUser.cognitoId }),
        fetchPolicy: 'network-only',
    }],
    })

    const generateSubscriptionVariables = (channelId, paymentMethodId, priceId, email, targetId, productId) => ({
    variables: {
        request: {
            channelId, 
            paymentMethodId,
            priceId,
            email,
            targetId,
            productId
        }
     
    },
    })

    const onRadioButtonClicked = (event, level) => {
        setShowPickText(false)
        setChosenLevel(level)
    }

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmitSub = async (fields) => {
        try {
            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }
            setShowSpinner(true)
            const email = fields.email
            const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: email,
            },
            });
            
            const {paymentMethod} = result
    
            const paymentMethodId = paymentMethod.id
            const priceId = chosenLevel.priceId
            const chosenProduct = products.find(p => p.priceId === priceId)
            const targetId = channelId
    
            if (result.error) {
            console.log(result.error.message)
            } else {
                const variables = generateSubscriptionVariables(channelId, paymentMethodId, priceId, email, targetId, chosenProduct.productId)
        
                const res = await subscribeUserToChannel(variables)
                console.log({res})
                const {client_secret, status} = res.data.subscribeUserToChannel
    
                if (status === 'requires_action') {
                stripe.confirmCardPayment(client_secret).then(function(result) {
                    if (result.error) {
                    console.log('There was an issue!')
                    console.log(result.error)
                    // Display error message in your UI.
                    // The card was declined (i.e. insufficient funds, card has expired, etc)
                    } else {
                        console.log('You got the money!')
                        window.location.reload()
                        // Show a success message to your customer
                    }
                    
                });
                } else {
                    console.log('You got the money!')
                    window.location.reload()
                    // No additional information was needed
                    // Show a success message to your customer
                }
            }

            console.log('Before toggle')
            toggle()
            console.log('After toggle')
            setIsSubscribed(true)
            console.log('After setIsSubscribed')
            goBackToTierList()
            console.log('After toggle')
        } catch(err) {
            console.log(err)
        } finally {
            setShowSpinner(false)
        }
      }


    const form = {
        persistOnSubmit: true,
        isInline: false,
        showErrors: true,
        fields: {
          email: {
            dataType: 'STRING',
            type: 'email',
            name: 'email',
            label: 'Email',
            showLabel: true,
            defaultValue: '',
            placeholder: '',
            isRequired: true,
            minLength: 1,
            maxLength: 250,
            xs: 12,
          },
        },
        submit: {
          isBlock: true,
        },
      }

    return (
        <Modal isOpen={isOpen} toggle={toggle} >
            <ModalHeader toggle={toggle}>
                { displayName }
                { showSpinner && <Spinner className='ml-3'/> }
            </ModalHeader>
            
            {
            isSubscribed ? 
            <>
                <ModalBody>
                    Are you sure you want to unsubscribe?
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={unsubscribeUserAndToggleModal}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </>

           : showInitial ?
            (
                <>
                    <ModalBody>
                        Choose a subscription level:
                        <Form>
                            <FormGroup tag="fieldset" row>
                            <Col sm={10}>
                            {tierLevels && tierLevels.map((level, i) => (
                                <FormGroup check>
                                    <Label check>
                                    <Input type="radio" name="radio2" value={i} onChange={(e) => onRadioButtonClicked(e, level)} />{' '}
                                    {level.name} for {level.symbol}{level.amount} per month
                                    </Label>
                                </FormGroup>
                            ))}
                            </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        {showPickText && <span>Please choose a subscription level</span>}
                        <Button color="primary" onClick={subscribeToChannelMutation}>Subscribe</Button>
                    </ModalFooter>
                </>) 
            :(
                <>
                    <ModalBody>
                        <div className='d-flex'>
                            <Button outline className='my-auto' color="primary" onClick={goBackToTierList}>  
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                            <h3 className='my-auto ml-4' >Payment Details</h3>
                        </div>
               
                        <div className='my-3'>
                            <CardInput />
                        </div>

                        <BaseFormComponent form={form} submit={handleSubmitSub} />
                    </ModalBody>
                </>
            )}

        </Modal>
    )
}


export default SubscribeToChannelModal
