import React from 'react'
import { Container } from 'reactstrap'
import { terms } from './templates/terms.jsx'
import { privacy } from './templates/privacy.jsx'

const TermsAndAgreementsPage = ({ appConfig, page }) => {

    const {  name, appUrl } = appConfig

    const resolvePage = () => {
        switch (page) {
            case 'TERMS':
                return terms({name, appUrl})
            case 'PRIVACY':
                return privacy({name, appUrl})
            default:
                return <></>
        }
    }

    return (
        <Container>
            { resolvePage() }
        </Container>
    )
}

export default TermsAndAgreementsPage
