import { gql } from '@apollo/client'

export const CREATE_BROADCAST_CHANNEL_MUTATION = gql`
  mutation startBroadcastChannel($eventId: String!) {
    startBroadcastChannel(eventId: $eventId) 
  }
`


export const REMOVE_BROADCAST_CHANNEL_MUTATION = gql`
  mutation stopBroadcastChannel($eventId: String!) {
    stopBroadcastChannel(eventId: $eventId) 
  }
`
