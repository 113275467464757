import { gql } from '@apollo/client'

export const SUBMIT_EMAIL_MUTATION = gql`
  mutation submitEmail($request: SubmitEmailRequest) {
    submitEmail(request: $request) {
        email
        userId
        appId
    }
  }
`
