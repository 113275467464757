import React, { useEffect, useState } from 'react'
import { getAppId } from '../../../../../utils/brand-silo'
import { useMutation } from '@apollo/client'
import { INIT_EVENT_SESSION } from '../../../../stream-component/master/mutations'
import LoadingComponent from '../../../../loading-component'
import { Button } from 'reactstrap';
import S3ImageComponent from '../../../../s3-image-component'
import { BaseGalleryCompContainer, MenuBarContainer, TileContainer, Tile, TileImage, TileInfoContainer, TileTitle, IconBar, LeftIconBar } from '../../../../../shared/base-gallery/style'


const VIEWER_TYPE = {
    SOLO: 'SOLO',
    PARTY: 'PARTY',
    FRONT_ROW: 'FRONT_ROW',
    HOST: 'HOST'
}

const handleEventSession = (data, ingressData, linkProfileRef, setProfile, setMasterDetail, goToEvent, goToPeerConnect, updateEventDetails) => {

    const { channel, tokens, profile, eventDetails } = data.initEventSession
    const { idToken, accessToken } = tokens
    let partyId = eventDetails && eventDetails.partyId ? eventDetails.partyId : ''
    let peers = eventDetails && eventDetails.peers ? eventDetails.peers : []
    let parties = eventDetails && eventDetails.parties ? eventDetails.parties : []


    const { viewerType } = ingressData

    localStorage.setItem('@TESSERACT/ACCESS_TOKEN', accessToken)
    localStorage.setItem('@TESSERACT/ID_TOKEN', idToken)

    const linkProfileObject = {
        profileId: profile.profileId, 
        channelArn: channel && channel.channelArn ? channel.channelArn : undefined
    }

    linkProfileRef.current(linkProfileObject)
    setProfile(profile)

    updateEventDetails(partyId, peers, parties)

    if (linkProfileObject.channelArn) {
        setMasterDetail({ channelArn: linkProfileObject.channelArn })
    }

    if (viewerType === VIEWER_TYPE.SOLO) {
        goToEvent(viewerType)
    } else {
        const { displayName } = profile
        goToPeerConnect(viewerType, displayName)
    }    

}


const generateInitVariables = ( displayName, eventId, eventType, authUser, viewerType, code, interest, status) => {

    const { sub: cognitoId } = authUser && authUser.attributes ? authUser.attributes : { sub: null }
    const appId = getAppId()

    const getFullDetails = ( viewerType === 'HOST' || eventType === 'GROUP_EVENT' )

    return {
        request: {
        cognitoId,
        appId,
        displayName,
        //   code,
        eventId,
        interest,
        status,
        getFullDetails,
        viewerType
        },
    }
}


const IngressForm = (props) => {
    const { authUser, event, profile, initSession, setIngressData } = props

    const submit = (formValues) => {
        const { viewerType, displayName, code } = formValues
        const { eventId, type: eventType } = event
        const variables = generateInitVariables(displayName, eventId, eventType, authUser, viewerType, code)

        initSession({ variables })
        setIngressData(formValues)
    
    }

    useEffect(() => {
        const isSingleEventApp = event.type === 'SINGLE_APP'
        const isGroupEvent = event.type === 'GROUP_EVENT'

        const { allowSolo, allowParties, allowFrontRow } = event.viewerTypes
        const isPartyOnly = allowParties && !allowSolo && !allowFrontRow

        if (isSingleEventApp || isGroupEvent || isPartyOnly) {
            const displayName = profile && profile.displayName ? profile.displayName : 'Member' 
            const form = { viewerType: 'PARTY', displayName, code: '' }
            submit(form)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const { viewerTypes, isHost } = event


    const showSolo = viewerTypes.allowSolo
    const showParty = viewerTypes.allowParties
    const showFrontRow = viewerTypes.allowParties
    const showHost = isHost && viewerTypes.allowFrontRow

    return (
        <BaseGalleryCompContainer className="">

            <MenuBarContainer>
                <div className="text-left" style={{ margin: '12px', fontSize: '22px' }}>
                    CHOOSE YOUR VIEWING EXPERIENCE
                </div>
            </MenuBarContainer>

            <TileContainer>
            
                { showSolo && (
                    <Tile >
                        <TileImage >
                            <S3ImageComponent 
                                imageName={'https://tes-media-dev.s3.amazonaws.com/content/solo.png'} 
                                style={{ width: '100%', height: '100%' }}
                            />
                        </TileImage>
                        <TileInfoContainer>
                            <TileTitle noHeight={true} >Solo</TileTitle>
                            <IconBar>
                                <LeftIconBar>
                                    Watch by yourself
                                </LeftIconBar>
                            </IconBar>
                            <div className="text-left mt-3">
                                <Button color="primary" onClick={() => submit({ viewerType: VIEWER_TYPE.SOLO })}>Start Watching</Button>
                            </div>
                        </TileInfoContainer>
                        </Tile>
                )}

                { showParty && (
                    <Tile >
                        <TileImage >
                            <S3ImageComponent 
                                imageName={'https://tes-media-dev.s3.amazonaws.com/content/party.png'} 
                                style={{ width: '100%', height: '100%' }}
                            />
                        </TileImage>
                        <TileInfoContainer>
                            <TileTitle noHeight={true} >Party</TileTitle>
                            <IconBar>
                                <LeftIconBar>
                                    Watch with others
                                </LeftIconBar>
                            </IconBar>
                            <div className="text-left mt-3">
                                <Button color="primary" onClick={() => submit({ viewerType: VIEWER_TYPE.PARTY })}>Start Watching</Button>
                            </div>
                        </TileInfoContainer>
                    </Tile>
                )}

                { showFrontRow && (
                    <Tile >
                        <TileImage >
                            <S3ImageComponent 
                                imageName={'https://tes-media-dev.s3.amazonaws.com/content/front_row.png'} 
                                style={{ width: '100%', height: '100%' }}
                            />
                        </TileImage>
                        <TileInfoContainer>
                            <TileTitle noHeight={true} >Front Row</TileTitle>
                            <IconBar>
                                <LeftIconBar>
                                    Joint event as a live part of the audience
                                </LeftIconBar>
                            </IconBar>
                            <div className="text-left mt-3">
                                <Button color="primary" onClick={() => submit({ viewerType: VIEWER_TYPE.FRONT_ROW })}>Start Watching</Button>
                            </div>
                        </TileInfoContainer>
                    </Tile>
                )}

                { showHost && (
                    <Tile >
                        <TileImage >
                            <S3ImageComponent 
                                imageName={'https://tes-media-dev.s3.amazonaws.com/content/front_row.png'} 
                                style={{ width: '100%', height: '100%' }}
                            />
                        </TileImage>
                        <TileInfoContainer>
                            <TileTitle noHeight={true} >Host</TileTitle>
                            <IconBar>
                                <LeftIconBar>
                                Host a live event
                                </LeftIconBar>
                            </IconBar>
                            <div className="text-left mt-3">
                                <Button color="primary" onClick={() => submit({ viewerType: VIEWER_TYPE.HOST })}>Start Hosting</Button>
                            </div>
                        </TileInfoContainer>
                    </Tile>
                )}

            </TileContainer>
        </BaseGalleryCompContainer>
    ) 
}


const EventIngressComponent = (props) => {
    const { setMasterDetail, goToEvent, goToPeerConnect, linkProfileRef, setProfile, updateEventDetails } = props
    const [initSession, { data, loading }] = useMutation(INIT_EVENT_SESSION)
    const [ingressData, setIngressData] = useState()

    if (loading) {
        return <LoadingComponent isInline />
    } else if (data) {
        handleEventSession(data, ingressData, linkProfileRef, setProfile, setMasterDetail, goToEvent, goToPeerConnect, updateEventDetails)
        return <></>
    } else {
        return <IngressForm {...props} initSession={initSession} setIngressData={setIngressData} />
    }
}

export default EventIngressComponent

