import React, { useState } from 'react'
import { Button, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faSearch, faTimes, faShare } from '@fortawesome/free-solid-svg-icons'
// import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import hash from 'object-hash'
import { useMutation } from '@apollo/client'
import { mapAppEvents } from '../../utils/mapper'
import CreateGroupModal from '../../components/create-group-modal'
import CreateChannelModal from '../../components/create-channel-modal'
import { getAppId, getCurrentBrandConfig } from '../../utils/brand-silo'
import { CREATE_GROUP_MUTATION } from '../../components/orchestrator/director/profile-director/mutations'
import { CREATE_CHANNEL_MUTATION } from '../../components/orchestrator/director/channel-director/mutations'
import algoliasearch from 'algoliasearch'
import { InstantSearch, connectHits, connectSearchBox } from 'react-instantsearch-dom'
import ExperienceComponent from '../../components/experience-component'
import PostListComponent from '../../components/post-list-component'
import S3ImageComponent from '../../components/s3-image-component'
import { getInitials } from '../../shared/shared-functions/get-initials'
import { MenuBarContainer, MenuBarScrollContainer, MenuBarItem, MenuBarItemText, Counter, BaseGalleryCompContainer, TileContainer, 
  Tile, TileImage, TileInfoContainer, StreamMoment, TileTitle, IconBar, LeftIconBar, 
  RightIconBar, ViewCount, ListItemContainer, ListItem, ListItemThumbnailImageContainer, ListItemThumbnailImage,
  ListItemInitials, ListItemName, ListItemDescription, ListItemOnlineStatus, ListItemInfoContainer, ListItemMessageContainer, CreateButtonContainer, 
  CreateButton, ListItemContentContainer, TileSectionContainer } from './style'
import ProfileModal from '../../components/profile-modal'
import ShareModal from '../../components/share-modal'
import { eventList, myProfile } from '../../shared/cache'
import { updateArrayItems } from '../../shared/cache/util'
import CreateEventModal from '../../components/create-event-modal'

const algoliaAppId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID
const algoliaSearchKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
const STAGE = process.env.REACT_APP_STAGE.toUpperCase()
const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchKey)

const PAGES = {
    EXPERIENCE: 'EXPERIENCE',
    POSTS: 'POSTS',
    FRIENDS: 'FRIENDS',
    EVENTS: 'EVENTS',
    GROUPS: 'GROUPS',
    CHANNELS: 'CHANNELS',
    APPS: 'APPS'
}

const countItemsLength = (items) => {
  if (items && items.length) {
    return items.length
  } else {
    return 0
  }
}


const EventListItem = ({ index, event, goToEventDetails, toggleShareModal }) => (
  <Tile key={index}>
    <TileImage onClick={goToEventDetails(event)}>
      <S3ImageComponent 
        imageName={event.primaryImageUrl} 
        style={{ width: '100%', height: '100%' }}
        imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <StreamMoment>
      { moment(event.startTime).fromNow() }
      </StreamMoment>
    </TileImage>
    <TileInfoContainer>
      <TileTitle>{event.title}</TileTitle>
      <IconBar>
        <LeftIconBar>
          <FontAwesomeIcon icon={faShare} onClick={() => toggleShareModal(event)} style={{ cursor: 'pointer' }} />
        </LeftIconBar>
        <RightIconBar>
          {/* <FontAwesomeIcon icon={faEye} /> */}
          {/* <ViewCount>
            {event.numUsers ? event.numUsers : 0}
          </ViewCount> */}
        </RightIconBar>
      </IconBar>
    </TileInfoContainer>
  </Tile>
)


const EventsList = ({ events = [], likedEvents = [], postedEvents = [], joinEvent, goToEventDetails, isMyProfile, authUser }) => {

    const hasNoEvents = (events === null || events === undefined || events.length === 0)
    const hasNoLikedEvents = (likedEvents && likedEvents.length === 0)
    const hasNoPostedEvents = (postedEvents && postedEvents.length === 0)

    let mappedEvents = []
    let mappedLikedEvents = []
    let mappedPostedEvents = []

    if (!hasNoEvents) {
      updateArrayItems(eventList, events)
      mappedEvents = mapAppEvents(events, true)
    }

    if (!hasNoLikedEvents) {
      updateArrayItems(eventList, mappedLikedEvents)
      mappedLikedEvents = mapAppEvents(likedEvents, true)
    }

    if (!hasNoPostedEvents) {
      updateArrayItems(eventList, mappedPostedEvents)
      mappedPostedEvents = mapAppEvents(postedEvents, true)
    }

    const [isModalOpen, setIsModalOpen] = useState(false)
    const toggleEventModal = () => setIsModalOpen(!isModalOpen)

    const { allowProfileEvents } = getCurrentBrandConfig()

    let isEventCreator = false

    if (authUser && authUser.signInUserSession) {
      const key = "event_creators"
      const groups = authUser.signInUserSession.accessToken.payload['cognito:groups']
      isEventCreator = groups && groups.length && groups.includes(key)
    }

    const allowCreateEvent = (isMyProfile && allowProfileEvents && isEventCreator)

    return (
      <ShareModal type={'EVENT'} render={(toggleShareModal) => (
        <TileContainer>
          { allowCreateEvent && (
            <>
              <CreateEventModal 
                channelId={'none'} 
                isOpen={isModalOpen}
                toggle={toggleEventModal}
                profile={myProfile.reactive()}
                products={[]}
                allowStream={false}
              />
              <CreateButtonContainer >
                <CreateButton
                  onClick={toggleEventModal}
                >
                  Create Event
                </CreateButton>
              </CreateButtonContainer>
            </>
          )}
        
        { (hasNoEvents && hasNoLikedEvents && hasNoPostedEvents) && (<i className="my-3" >No events yet</i>) }

        { mappedEvents.length > 0 && (
            <TileSectionContainer>
              { mappedEvents.map((event, index) => (
                  <EventListItem
                    index={index}
                    event={event}
                    goToEventDetails={goToEventDetails}
                    toggleShareModal={toggleShareModal}
                  />
                )
              )}
            </TileSectionContainer>
          )}

          { mappedPostedEvents.length > 0 && (
            <>
              <h3>My Posted Events</h3>
              <TileSectionContainer>
                { mappedPostedEvents.map((event, index) => (
                    <EventListItem
                      index={index}
                      event={event}
                      goToEventDetails={goToEventDetails}
                      toggleShareModal={toggleShareModal}
                    />
                  ))
                }
              </TileSectionContainer>
              </>
          )}

          { mappedLikedEvents.length > 0 && (
              <>
                <h3>My Liked Events</h3>
                <TileSectionContainer>
                  { mappedLikedEvents.map((event, index) => (
                      <EventListItem
                        index={index}
                        event={event}
                        goToEventDetails={goToEventDetails}
                        toggleShareModal={toggleShareModal}
                      />
                    ))
                  }
                </TileSectionContainer>
              </>
          )}

        </TileContainer>
      )} />
    )
  }
  
  const FriendsList = ({ friends, setMessengerContext, myProfileId, groupId, authUser, goToProfile, isMyProfile, ownerId }) => {
  
    const [showProfileModal, setShowProfileModal] = useState(false)
    const [selectedMember, setSelectedMember] = useState({ displayName: 'default' })
  
    const toggleProfileModal = (event, member) => {
      if (member !== undefined) {
        setSelectedMember(member)
      }
  
      setShowProfileModal(!showProfileModal)
    }
  
    if (!isMyProfile) {
      return <></>
    }
    
    if (friends === null || friends === undefined || friends.length === 0) {
      return <i className="my-5">No friends yet</i>
    }
  
    return (
      <>
        <ProfileModal 
          isOpen={showProfileModal} 
          toggle={toggleProfileModal} 
          selectedMember={selectedMember} 
          groupOwnerId={ownerId} 
          userProfileId={myProfileId} 
          groupId={groupId} 
          authUser={authUser} 
          goToProfile={goToProfile}
        />

        <ListItemContainer>
          {friends.length
            && friends.map((friend, index) => (
              <ListItem key={index} onClick={(event) => toggleProfileModal(event, friend)}>
                <ListItemThumbnailImageContainer>
                  <ListItemThumbnailImage>
                    <ListItemInitials>
                      {getInitials(friend)}
                    </ListItemInitials>
                  </ListItemThumbnailImage>
                </ListItemThumbnailImageContainer>
                <ListItemContentContainer>
                  <ListItemInfoContainer>
                    <ListItemName>
                      {friend.displayName}
                    </ListItemName>
                    <ListItemDescription>
                      {friend.interest}
                    </ListItemDescription>
                  </ListItemInfoContainer>
                  <ListItemOnlineStatus isOnline={friend.isOnline}>
                    {friend.isOnline ? 'Online' : 'Offline'}
                  </ListItemOnlineStatus>
                </ListItemContentContainer>
              </ListItem>
            ))}
        </ListItemContainer>
      </>
    )
  }

  const GroupsList = ({ groups, setMessengerContext, goToGroupDetails, isMyProfile }) => {
    const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false)
  
    const [createGroup] = useMutation(CREATE_GROUP_MUTATION)
  
    const toggleCreateGroupModal = () => setCreateGroupModalOpen(!isCreateGroupModalOpen)
  
    const openMessageContext = (event, displayName, groupId) => {
      const contextType = 'GROUP'
      const appId = getAppId()
      const contextHash = hash(`${appId}-${groupId}`)
      setMessengerContext(contextType, groupId, true, displayName, contextHash)
      event.stopPropagation()
    }

    return (
      <>
        <CreateGroupModal
          createGroup={createGroup}
          isOpen={isCreateGroupModalOpen}
          toggle={toggleCreateGroupModal}
        />
  
        <ListItemContainer>
          {groups.length === 0 ? (
            <i className="mt-3">No groups yet</i>
          ) : (
            groups.map((group, index) => (

            <ListItem key={index} onClick={goToGroupDetails(group)}>
              <ListItemThumbnailImageContainer>
                <ListItemThumbnailImage>
                  <ListItemInitials>
                    {getInitials(group)}
                  </ListItemInitials>
                </ListItemThumbnailImage>
              </ListItemThumbnailImageContainer>
              <ListItemContentContainer>
                <ListItemInfoContainer>
                  <ListItemName>
                    {group.displayName}
                  </ListItemName>
                  <ListItemDescription>
                    {group.members.length + ' ' + (group.members.length > 1 ? 'members' : 'member')}
                  </ListItemDescription>
                </ListItemInfoContainer>
                <ListItemMessageContainer>
                  <FontAwesomeIcon icon={faEnvelope} onClick={(e) => openMessageContext(e, group.displayName, group.groupId)} />
                </ListItemMessageContainer>
              </ListItemContentContainer>
            </ListItem>
            ))
          )}
          { isMyProfile && (
            <CreateButtonContainer >
              <CreateButton
                onClick={toggleCreateGroupModal}
              >
                Create Group
              </CreateButton>
            </CreateButtonContainer>
          )}
        </ListItemContainer>
      </>
    )
  }
  
  const ChannelsList = ({ channels, goToChannelDetails, isMyProfile, profile }) => {
    const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false)
  
    const [createChannel] = useMutation(CREATE_CHANNEL_MUTATION)
  
    const toggleCreateChannelModal = () => setCreateChannelModalOpen(!isCreateChannelModalOpen)
  
    return (
      <>
      { isMyProfile && (
        <CreateChannelModal
          createChannel={createChannel}
          isOpen={isCreateChannelModalOpen}
          toggle={toggleCreateChannelModal}
        />
      )}
      <ShareModal type={'CHANNEL'} render={(toggleShareModal) => (
        <TileContainer>
          <TileSectionContainer>
            {!channels || channels.length === 0 ? (
              <i className="mt-3">No channels yet</i>
            ) : (
              channels.map((channel, index) => (
                <Tile key={index}>
                  <TileImage onClick={goToChannelDetails({ channelId: channel.channelId, slug: channel.slug })}>
                    <S3ImageComponent 
                      imageName={channel.primaryImageUrl} 
                      style={{ width: '100%', height: '100%' }}
                    />
                  </TileImage>
                  <TileInfoContainer>
                    <TileTitle>{channel.displayName}</TileTitle>
                    <IconBar>
                      <LeftIconBar>
                        <FontAwesomeIcon icon={faShare} onClick={() => toggleShareModal(channel)} style={{ cursor: 'pointer' }} />
                        {/* <FontAwesomeIcon icon={farHeart} /> */}
                      </LeftIconBar>
                      <RightIconBar>
                        <ViewCount>
                          { channel.subscriberCount ? channel.subscriberCount : '0' }
                        </ViewCount>
                        Subscribers
                      </RightIconBar>
                    </IconBar>
                  </TileInfoContainer>
                </Tile>
              ))
            )}
            { isMyProfile && (
              <CreateButtonContainer style={{marginTop: '0'}}>
                <CreateButton
                  onClick={toggleCreateChannelModal}
                >
                  Create Channel
                </CreateButton>
              </CreateButtonContainer>
            )}
          </TileSectionContainer>
        </TileContainer>
      )} />

    </>
    )
  }

  const AppsList = ({ apps, isMyProfile, profile, goToEventDetails }) => {
    const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false)
  
    const [createChannel] = useMutation(CREATE_CHANNEL_MUTATION)
  
    const toggleCreateChannelModal = () => setCreateChannelModalOpen(!isCreateChannelModalOpen)
  
    updateArrayItems(eventList, apps)

    return (
      <>
      { isMyProfile && (
        <CreateChannelModal
          createChannel={createChannel}
          isOpen={isCreateChannelModalOpen}
          toggle={toggleCreateChannelModal}
        />
      )}
      <ShareModal type={'APP'} render={(toggleShareModal) => (
        <TileContainer>
          <TileSectionContainer>
            {!apps || apps.length === 0 ? (
              <i className="mt-3">No apps yet</i>
            ) : (
              apps.map((app, index) => (
                <Tile key={index}>
                  <TileImage onClick={goToEventDetails(app)}>
                    <S3ImageComponent 
                      imageName={app.primaryImageUrl} 
                      style={{ width: '100%', height: '100%' }}
                    />
                  </TileImage>
                  <TileInfoContainer>
                    <TileTitle>{app.displayName}</TileTitle>
                    <IconBar>
                      <LeftIconBar>
                        <FontAwesomeIcon icon={faShare} onClick={() => toggleShareModal(app)} style={{ cursor: 'pointer' }} />
                        {/* <FontAwesomeIcon icon={farHeart} /> */}
                      </LeftIconBar>
                      {/* <RightIconBar>
                        <ViewCount>
                          { app.subscriberCount ? app.subscriberCount : '0' }
                        </ViewCount>
                        Subscribers
                      </RightIconBar> */}
                    </IconBar>
                  </TileInfoContainer>
                </Tile>
              ))
            )}
            {/* { isMyProfile && (
              <CreateButtonContainer style={{marginTop: '0'}}>
                <CreateButton
                  onClick={toggleCreateChannelModal}
                >
                  Create Channel
                </CreateButton>
              </CreateButtonContainer>
            )} */}
          </TileSectionContainer>
        </TileContainer>
      )} />
    </>
    )
  }

const MenuBar = ({ current, setCurrent, show, posts, events, friends, groups, channels, showCounter, appEvents }) => {
    const setCurrentView = (page) => () => {
      setCurrent({ page })
    }

    return (
      <MenuBarContainer>
        <MenuBarScrollContainer>

          { show.experience && 
            (
              <MenuBarItem
                active={current.page === PAGES.EXPERIENCE}
                onClick={setCurrentView(PAGES.EXPERIENCE)}
              >
                <MenuBarItemText active={current.page === PAGES.EXPERIENCE}>
                  Experience
                </MenuBarItemText>
              </MenuBarItem>
            )
          }      
          { show.posts && 
            (
              <MenuBarItem
                active={current.page === PAGES.POSTS}
                onClick={setCurrentView(PAGES.POSTS)}
              >
                <MenuBarItemText active={current.page === PAGES.POSTS}>
                  Posts
                </MenuBarItemText>
                { showCounter && (
                  <Counter active={current.page === PAGES.POSTS} >
                    { countItemsLength(posts) }
                  </Counter>
                )}

              </MenuBarItem>
            )
          }

          { show.events && 
            (
              <MenuBarItem
                active={current.page === PAGES.EVENTS}
                onClick={setCurrentView(PAGES.EVENTS)}
              >
                <MenuBarItemText active={current.page === PAGES.EVENTS}>
                  Events
                </MenuBarItemText>
                { showCounter && (
                  <Counter active={current.page === PAGES.EVENTS} >
                    { countItemsLength(events) }
                  </Counter>
                )}
              </MenuBarItem>
            )
          }
          { show.friends && 
            (
              <MenuBarItem
                active={current.page === PAGES.FRIENDS}
                onClick={setCurrentView(PAGES.FRIENDS)}
              >
                <MenuBarItemText active={current.page === PAGES.FRIENDS} >
                  Friends
                </MenuBarItemText>
                { showCounter && (
                  <Counter active={current.page === PAGES.FRIENDS} >
                    { countItemsLength(friends) }
                  </Counter>
                )}
              </MenuBarItem>
            )
          }
          { show.groups && 
            (
              <MenuBarItem
                active={current.page === PAGES.GROUPS}
                onClick={setCurrentView(PAGES.GROUPS)}
              >
                <MenuBarItemText active={current.page === PAGES.GROUPS}>
                  Groups
                </MenuBarItemText>
                { showCounter && (
                  <Counter active={current.page === PAGES.GROUPS} >
                    { countItemsLength(groups) }
                  </Counter>
                )}
              </MenuBarItem>
            )
          }
          { show.channels && 
            (
              <MenuBarItem
                active={current.page === PAGES.CHANNELS}
                onClick={setCurrentView(PAGES.CHANNELS)}
              >
                <MenuBarItemText active={current.page === PAGES.CHANNELS}>
                  Channels
                </MenuBarItemText>
                { showCounter && (
                  <Counter active={current.page === PAGES.CHANNELS} >
                    { countItemsLength(channels) }
                  </Counter>
                )}
              </MenuBarItem>
            )
          }
          { show.apps && 
            (
              <MenuBarItem
                active={current.page === PAGES.APPS}
                onClick={setCurrentView(PAGES.APPS)}
              >
                <MenuBarItemText active={current.page === PAGES.APPS}>
                  Apps
                </MenuBarItemText>
                { showCounter && (
                  <Counter active={current.page === PAGES.APPS} >
                    { countItemsLength(appEvents) }
                  </Counter>
                )}
              </MenuBarItem>
            )
          }
        </MenuBarScrollContainer>

      </MenuBarContainer>
    )
}
  


const BaseGalleryComponent = ({ 
    isMyProfile, 
    myProfileId,
    friends, 
    events, 
    likedEvents,
    postedEvents,
    groups, 
    channels, 
    targetType, 
    goToEvent, 
    messenger, 
    setMessengerContext, 
    goToProfile, 
    goToGroupDetails, 
    goToChannelDetails, 
    appConfig,
    profile,
    authUser,
    ownerId,
    entityId,
    current,
    setCurrent,
    show,
    appEvents,
    goToEventDetails
}) => {

    const resolveView = () => {
        switch (current.page) {
            case PAGES.EXPERIENCE:
              return <ExperienceComponent profile={profile} appConfig={appConfig} authUser={authUser} isMyProfile={isMyProfile} />
            case PAGES.POSTS:
              return <PostListComponent profile={profile} authUser={authUser} isMyProfile={isMyProfile} />
            case PAGES.EVENTS:
                return <EventsList 
                  events={events} 
                  likedEvents={likedEvents}
                  postedEvents={postedEvents}
                  goToEventDetails={goToEventDetails} 
                  joinEvent={goToEvent} 
                  isMyProfile={isMyProfile} 
                  authUser={authUser} 
                />
            case PAGES.FRIENDS:
                return (
                    <FriendsList
                        targetType={targetType}
                        ownerId={ownerId}
                        friends={friends}
                        messenger={messenger}
                        setMessengerContext={setMessengerContext}
                        myProfileId={myProfileId}
                        goToProfile={goToProfile}
                        isMyProfile={isMyProfile}
                        groupId={entityId}
                        authUser={authUser}
                    />
                )
            case PAGES.GROUPS:
                return (
                    <GroupsList
                        groups={groups}
                        setMessengerContext={setMessengerContext}
                        goToGroupDetails={goToGroupDetails}
                        isMyProfile={isMyProfile}
                        profile={profile}
                    />
                )
            case PAGES.CHANNELS:
                return (
                    <ChannelsList
                        channels={channels}
                        goToChannelDetails={goToChannelDetails}
                        isMyProfile={isMyProfile}
                    />
                )
            case PAGES.APPS:
              return (
                <AppsList apps={appEvents} goToEventDetails={goToEventDetails} />
              )
            default:
                return <> Hmm, not sure how you got here </>
        }
    }
    
    const showCounter = targetType !== 'EXPLORE'

    return (
        <BaseGalleryCompContainer>
          <MenuBar 
            current={current} 
            setCurrent={setCurrent} 
            show={show} 
            isMyProfile={isMyProfile} 
            events={events} 
            friends={friends} 
            groups={groups} 
            channels={channels} 
            showCounter={showCounter}
            appEvents={appEvents}
          />
          {resolveView()}
        </BaseGalleryCompContainer>
    )
}


const resolveInitialView = (appConfig, targetType, isMyProfile, isTesseract) => {
  const pageConfig = appConfig.pageConfigs.find((c) => c.key === targetType)

  const show = {
    experience: false,
    posts: false,
    events: false,
    friends: false,
    groups: false,
    channels: false,
    apps: false,
  }

  if (isTesseract) {
    show.apps = true
  } else {
    switch (targetType) {
      case 'PROFILE':
        show.experience = pageConfig.tabs.includes(PAGES.EXPERIENCE) 
        show.posts = pageConfig.tabs.includes(PAGES.POSTS) 
        show.events = pageConfig.tabs.includes(PAGES.EVENTS) 
        show.friends = pageConfig.tabs.includes(PAGES.FRIENDS) && isMyProfile
        show.groups = pageConfig.tabs.includes(PAGES.GROUPS) && isMyProfile
        show.channels = pageConfig.tabs.includes(PAGES.CHANNELS) && isMyProfile
        break
      case 'CHANNEL':
        show.events = true
        show.friends = false
        show.groups = true
        show.channels = false
        break
      case 'EXPLORE':
        const exploreConfig = appConfig.pageConfigs.find((c) => c.key === 'EXPLORE')
        show.events = exploreConfig.tabs.includes(PAGES.EVENTS)
        show.channels = exploreConfig.tabs.includes(PAGES.CHANNELS)
        show.apps = exploreConfig.tabs.includes(PAGES.APPS)
        show.friends = false
        show.groups = false
        break
      default:
        show.events = false
        show.friends = false
        show.groups = false
        show.channels = false
        break
    }
  }

  const initialPage = 
    show.apps
    ? PAGES.APPS
    : show.experience
      ? PAGES.EXPERIENCE
      : show.events
        ? PAGES.EVENTS
        : show.friends
          ? PAGES.FRIENDS
          : show.groups
            ? PAGES.GROUPS
            : PAGES.FRIENDS

  const initialView = {
      page: initialPage,
  }

  return { initialView, show }
}

const BaseGalleryComponentWithSearch = (props) => {

  const { appConfig, showSearch, targetType, isMyProfile, appEvents, isTesseract, goToEventDetails } = props
  const { initialView, show } = resolveInitialView(appConfig, targetType, isMyProfile, isTesseract)

  const [current, setCurrent] = useState(initialView)

  const CustomSearchBox = connectSearchBox(({ currentRefinement, refine, isSearchStalled }) => (
    <div >
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
        </InputGroupAddon>
        <Input
          type="search"
          value={currentRefinement}
          onChange={event => refine && refine(event.currentTarget.value)}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={() => refine && refine('')}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {isSearchStalled ? 'Hold on' : ''}
    </div>
  ))
  
  const HitsComponent = connectHits(({ hits }) => {
    const showResults = hits !== undefined && hits !== null
    if (showResults) {
      const eventHits = hits.filter(h => !!h.eventId )
      const channelHits = hits.filter(h => !!h.channelId && !h.eventId)
      return <BaseGalleryComponent
        {...props}
        events={eventHits}
        channels={channelHits}
        current={current} 
        setCurrent={setCurrent}
        show={show}
        goToEventDetails={goToEventDetails}
      />
    } else {
      return (<></>)
    }
  })
  
  const resolveIndex = (page) => {
    let indexName = ''
    switch (page) {
      case PAGES.CHANNELS:
        indexName = `${STAGE}_${appConfig.appId}_CHANNEL`
        break
      case PAGES.EVENTS:
      default:
        indexName = `${STAGE}_${appConfig.appId}_EVENT`
        break
    }

    return indexName
  }

  const SearchComponent = ({ page }) => {
    const indexName = resolveIndex(page)
    return (
      <div>
        <InstantSearch
          indexName={indexName}
          searchClient={algoliaClient}
          stalledSearchDelay={1000}
        >
          <CustomSearchBox />
          <HitsComponent />
        </InstantSearch>
      </div>
    )
  }

  return showSearch
    ? <SearchComponent {...props} page={current.page} />
    : <BaseGalleryComponent {...props} current={current} setCurrent={setCurrent} show={show} appEvents={appEvents} />
}

export default BaseGalleryComponentWithSearch
