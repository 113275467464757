import { gql } from '@apollo/client'

export const CREATE_EVENT_MUTATION = gql`
  mutation createAppEvent($request: CreateEventRequest) {
    createAppEvent(request: $request) {
      eventId
      profileId
      title
      description
      entityId
      streamType
      startTime
      duration
      channelId
      headerImageUrl
      primaryImageUrl
    }
  }
`
