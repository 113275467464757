import styled from 'styled-components'
import { mediaQueries, boldDefaultSmallFont, semiBoldDefaultSmallFont } from '../../styles/helpers'

export const CommentsComponentContainer = styled.div`
    box-shadow: 0px 3px 6px #70707052;
    background-color: ${({ theme }) => theme.components.hero.bottomBackgroundColor };
    margin-top: 1rem;
`;

export const CommentsTitleBar = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid ${({theme}) => theme.components.gallery.borderColor };
    padding: 1rem;

    ${mediaQueries('tablet')`
        padding: 2rem;
    `}
`;

export const CommentsTitleBarRight = styled.div`
    svg {
        width: 1.2rem !important;
        height: 1.2rem;
    }
`;

export const CommentsTitleText = styled.div`
    text-transform: uppercase;
    ${semiBoldDefaultSmallFont};

    ${mediaQueries('tablet')`
        font-size: 1.1rem;
    `};
`;

export const NumComments = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    ${semiBoldDefaultSmallFont}

    ${mediaQueries('tablet')`
        font-size: 1.1rem;
    `};
`;

export const CommentCounter = styled.span`
    margin-right: 0.5rem;
`;

export const CommentsBodyContainer = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
`;

export const ThumbnailInitial = styled.span`
    display: inline-block;
    width: 2.3rem;
    height: 2.3rem;
    background-color: ${({theme}) => {
        return theme.color.primary;
    }};
    border-radius: 100%;
    padding: 0.5rem;
    color: #fff;
    text-align: center;
`;

export const CommentWriterContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
`;

export const CommentsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 1rem;
`;

export const ThumbnailImageContainer = styled.div`
    margin-right: ${(props) => props.noMargin ? '0' : '1.5rem' };
`;

export const CommentContainer = styled.div`
    display: flex;
    margin-top: ${(props) => props.isThreadComment ? '0.8rem' : '0'};

    &:nth-child(n + 2) {
        margin-top: 1.5rem;
    }
`;

export const CommentBox = styled.div`
    margin-top: 0.5rem;
`;

export const UsernameTimeContainer = styled.div`
    display: flex;
    justfiy-content: flex-start;
    flex-direction: column;

    ${mediaQueries('tablet')`
        flex-direction: row;
    `}
`;

export const UserName = styled.span`
    ${boldDefaultSmallFont}
    color: ${(props) => props.appId === 'SPEAKEASY' ? '#000' : '#fff'};

`;

export const CommentTime = styled.span`
    ${semiBoldDefaultSmallFont}
    color: #7D8386;

    ${mediaQueries('tablet')`
        margin-left: 1rem;
    `}
`;

export const CommentText = styled.div`
    ${semiBoldDefaultSmallFont}
    margin-top: 0.5rem;
`;

export const InteractionContainer = styled.div`
    margin-top: 0.8rem;

    svg:nth-of-type(2) {
        margin-left: 0.5rem;
    }
`;

export const LikeDislikeContainer = styled.div`
    display: inline-block;
    min-width: 4rem;

    svg:hover {
        cursor: pointer;
    }
`;

export const LikeDislikeCount = styled.span`
    ${semiBoldDefaultSmallFont}
    color: #7D8386;
    margin: 0 0.3rem;
`;


export const ReplyText = styled.button`
    ${semiBoldDefaultSmallFont}
    color: ${({theme}) => {
        return theme.color.primary;
    }};
    border: none;
    background: none;

    &:focus {
        outline: 0
    }
`;

export const ReplyCommentContainer = styled.div`
    display: ${(props) => props.displayReplyComment ? 'block' : 'none'};
`;

export const ViewThreadComments = styled.button`
    ${semiBoldDefaultSmallFont}
    display: ${(props) => props.displayViewThreadComments ? 'block' : 'none'};
    color: ${({theme}) => {
        return theme.color.primary;
    }};
    border: none;
    background: none;
    margin-top: 0.8rem;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: 0
    }
`;

export const ThreadCommentsContainer = styled.div`
    display: ${(props) => props.displayThreadComments ? 'block' : 'none'};
`;