import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import { PAGES } from '../../utils/pages'
import AppLogo from '../logo'
import { TopNavContainer, ProfileThumbnailContainer, ProfileThumbnailText, ProfileThumbnailImageContainer, SideNavToggler } from './style';
import S3ImageComponent from '../s3-image-component'
import { Button } from 'reactstrap'
import { useReactiveVar } from '@apollo/client'
import { myProfile } from '../../shared/cache'

const NavComponent = ({
  cognitoUser, appConfig, goTo, toggleSidebar, isShowingSidebar, setSidebar
}) => {

  const profile = useReactiveVar(myProfile.reactive)

  const hasProfile = !!profile

  const userData = hasProfile ? profile : undefined
  const hasImage = profile && profile.primaryImageUrl && profile.primaryImageUrl !== 'none'
  const initialImgUrl = (profile && profile.primaryImageUrl) || ''

  const goToLanding = () => {
    setSidebar(true)
    goTo(PAGES.LANDING)()
  }

  return (
    <TopNavContainer>
      <AppLogo appId={appConfig.appId} logo={appConfig.logo} goToLanding={goToLanding} />
      { 
        userData && (userData.profileId || userData.cognitoId)
          ? (
            <ProfileThumbnailContainer>
              <ProfileThumbnailText>
                { userData.displayName }
              </ProfileThumbnailText>
              <ProfileThumbnailImageContainer hasImage={hasImage} >
                { (hasImage)
                    ? <S3ImageComponent 
                        imageName={initialImgUrl} 
                        style={{ width: '2.5rem', height: '2.5rem', borderRadius: '2rem'}}
                        imageStyle={{ borderRadius: '100%' }}
                      />
                    : <FontAwesomeIcon icon={faUser} />
                }
              </ProfileThumbnailImageContainer>
            </ProfileThumbnailContainer>
          ) :
          <></>
      }
      <SideNavToggler>
        <Button onClick={toggleSidebar} >
          <FontAwesomeIcon icon={!isShowingSidebar ? faBars : faTimes} />
        </Button>
      </SideNavToggler>
    </TopNavContainer>
  )
}

export default NavComponent
